import { range } from "../../Components/Game/ArenaHelperFunctions";
import { allCards } from "../Card/Cards";

export const opponentLevelStates = {
    0:{

    },
    1:{
        level:1,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [],
        sunken: [],
        arenaPositions:[[],[],[{...allCards()["Crab"](), level:2, weight:0}],[],[],[{...allCards()["Crab"](), level:2, weight:0}],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    2:{
        level:2,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[{...allCards()["Snail"](), level:2, weight:0}],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    3:{
        level:3,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[{...allCards()["Fish"](), level:2, weight:0}],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    4:{
        level:4,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[{...allCards()["Frog"](), level:2, weight:0}],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    5:{
        level:5,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
}
export const playOpponentCards = {
    1:(state, oppState, setOpponentState)=>{
        setOpponentState(()=>{
            if (oppState.destroyedPositions.length===8) return oppState
            let newDestroyed = oppState.destroyedPositions??[]
            let newPos = [...oppState.arenaPositions]
            range(Math.floor(state.turn/8)+1).forEach(()=>{
                if (newDestroyed?.length>0) {
                    newDestroyed.pop()
                } else if (newPos.flat(1).length<8) {
                    const posMap = {
                        0:3,1:4,2:5,3:2,4:1,5:6,6:7,7:0
                    }
                    for (let i=0; i<newPos.length;i++) {
                        if (newPos[posMap[i]].length===0) {
                            newPos[posMap[i]] = [{...allCards()["Crab"](),level:2,weight:0}]
                            break
                        }
                    }
                }
            })
            return{...oppState, destroyedPositions:newDestroyed, arenaPositions:newPos}
        })
    },
    2:(state, oppState, setOpponentState)=>{
        setOpponentState(()=>{
            if (oppState.destroyedPositions.length===8) return oppState
            let newDestroyed = oppState.destroyedPositions??[]
            let newPos = [...oppState.arenaPositions]
            range(Math.floor(state.turn/8)+1).forEach(()=>{
                if (newDestroyed?.length>0) {
                    newDestroyed.pop()
                } else if (newPos.flat(1).length<8) {
                    const posMap = {
                        0:3,1:4,2:5,3:2,4:1,5:6,6:7,7:0
                    }
                    for (let i=0; i<newPos.length;i++) {
                        if (newPos[posMap[i]].length===0) {
                            newPos[posMap[i]] = [{...allCards()["Snail"](),level:2,weight:0}]
                            break
                        }
                    }
                }
            })
            return{...oppState, destroyedPositions:newDestroyed, arenaPositions:newPos}
        })
    },
    3:(state, oppState, setOpponentState)=>{
        setOpponentState(()=>{
            if (oppState.destroyedPositions.length===8) return oppState
            let newDestroyed = oppState.destroyedPositions??[]
            let newPos = [...oppState.arenaPositions]
            range(Math.floor(state.turn/8)+1).forEach(()=>{
                if (newDestroyed?.length>0) {
                    newDestroyed.pop()
                } else if (newPos.flat(1).length<8) {
                    const posMap = {
                        0:3,1:4,2:5,3:2,4:1,5:6,6:7,7:0
                    }
                    for (let i=0; i<newPos.length;i++) {
                        if (newPos[posMap[i]].length===0) {
                            newPos[posMap[i]] = [{...allCards()["Fish"](),level:2,weight:0}]
                            break
                        }
                    }
                }
            })
            return{...oppState, destroyedPositions:newDestroyed, arenaPositions:newPos}
        })
    },
    4:(state, oppState, setOpponentState)=>{
        setOpponentState(()=>{
            if (oppState.destroyedPositions.length===8) return oppState
            let newDestroyed = oppState.destroyedPositions??[]
            let newPos = [...oppState.arenaPositions]
            range(Math.floor(state.turn/8)+1).forEach(()=>{
                if (newDestroyed?.length>0) {
                    newDestroyed.pop()
                } else if (newPos.flat(1).length<8) {
                    const posMap = {
                        0:3,1:4,2:5,3:2,4:1,5:6,6:7,7:0
                    }
                    for (let i=0; i<newPos.length;i++) {
                        if (newPos[posMap[i]].length===0) {
                            newPos[posMap[i]] = [{...allCards()["Frog"](),level:2,weight:0}]
                            break
                        }
                    }
                }
            })
            return{...oppState, destroyedPositions:newDestroyed, arenaPositions:newPos}
        })
    },
    5:(state, oppState, setOpponentState)=>{
        setOpponentState(()=>{
            if (oppState.destroyedPositions.length===8) return oppState
            let newDestroyed = oppState.destroyedPositions??[]
            let newPos = [...oppState.arenaPositions]
            range(Math.floor(state.turn/8)+1).forEach(()=>{
                if (newDestroyed?.length>0) {
                    newDestroyed.pop()
                } else if (newPos.flat(1).length<8) {
                    const posMap = {
                        0:3,1:4,2:5,3:2,4:1,5:6,6:7,7:0
                    }
                    for (let i=0; i<newPos.length;i++) {
                        if (newPos[posMap[i]].length===0) {
                            newPos[posMap[i]] = [{...allCards()["Big Fish"](),level:2,weight:0}]
                            break
                        }
                    }
                }
            })
            return{...oppState, destroyedPositions:newDestroyed, arenaPositions:newPos}
        })
    },
}
export const levelStates = {
    0:{

    },
    1:{
        level:1,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [allCards()["Ladybug"](),allCards()["Pile of Floaties"]()],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    2:{
        level:2,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [allCards()["Ladybug"](),allCards()["Pile of Floaties"]()],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    3:{
        level:3,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [allCards()["Ladybug"](),allCards()["Pile of Floaties"]()],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    4:{
        level:4,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [allCards()["Ladybug"](),allCards()["Pile of Floaties"]()],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
    5:{
        level:5,
        turn: 0,
        shells:0,
        lastShuffle:-1,
        awaitingNextTurn: false,
        sink:false,
        deck: [],
        topCard:{},
        hand: [allCards()["Ladybug"](),allCards()["Pile of Floaties"]()],
        sunken: [],
        arenaPositions:[[],[],[],[],[],[],[],[]],
        destroyedPositions:[],
        destroyingPositions:[],
    },
}