import { useSearchParams } from "react-router-dom";
import { starterDeck } from "../App.js";
import { drawCards, shuffleDeck } from "../Components/Game/ArenaHelperFunctions.js";
import { useCallback, useEffect, useRef, useState } from "react";
import Arena from "../Components/Game/Arena.js";
import { GameFunctions } from "../Functions/GameFunctions/GameFunctions.js"
import TutorialCard from "../Components/Tutorial/TutorialCard.js";
import { allCards, getProduction, getWeight } from "../Data/Card/Cards.js";
import { processDownloadState, processUploadState } from "../TableFunctions/GameTableFunctions.js";
import CardInfo from "../Components/Game/CardInfo.js";

export default function Tutorial() {
    const [hasInspectedCard, setHasInspectedCard] = useState([])
    const arena = useRef()
    const [frog,setFrog] = useState(false)
    const {hand, deck} = drawCards(shuffleDeck(starterDeck))
    const [updates, setUpdates] = useState(0)
    const [state, setState] = useState({tutorialPhase:0})
    const [opponentState, setOpponentState] = useState({})
    const [searchParams] = useSearchParams();
    const [simulating, setSimulating] = useState(false)
    const [currentCard, setCurrentCard] = useState([])
    const id = searchParams.get("id")
    const [opponentUsername, ] = useState("Tutorial")
    const [canLeave, ] = useState(false)
    const [failed, setFailed] = useState(false)
    const updatePlayer = (state) => {
        //save turn in local storage?
        setState(state)
    }
    const [gameFunctions,] = useState(new GameFunctions({setState, setOpponentState, setSimulating, updatePlayer, removeGame:()=>{},}))
    const recoverDeck = (callback) => {
        //get local storage turn
        let playerState = {}
        let firstTurn=true
        try {
            playerState = localStorage.getItem("tutorial")
            if (playerState) {
                firstTurn = JSON.parse(playerState).tutorialPhase<2
            }
        } catch (e) {

        }
        if (playerState && !firstTurn) {
            const newState = processDownloadState(playerState)
            setState({...newState,
                ...tutorialStates()[(newState.topPhase-(newState.topPhase%2))/2],
                tutorialPhase:newState.topPhase-(newState.topPhase%2),
                inShop: false,})
        } else {
            setState({
                simulating: false,
                topPhase:0,
                tutorialPhase:0,
                inShop: false,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            })
        }
    }
    const handleExit = () => {
            
    }

    const nextTutorialPhase = useCallback((state) => {
        if ((state.awaitingNextTurn) && (!state.simulating) && (!simulating )&& (state.tutorialPhase>=5)) {
            if (opponentState?.arenaPositions?.length>0) {
                gameFunctions.simulateCards(state, opponentState)
            }
            return state.tutorialPhase
        }
        if (simulating || state.simulating || state.awaitingNextTurn || (state.paused&&state.tutorialPhase>3)){
            return state.tutorialPhase
        }
        if (state.tutorialPhase===1) {
            return ((hasInspectedCard.filter(item=>item?.[0]?.title==="Pile of Floaties").length>0)&&(hasInspectedCard.filter(item=>item?.[0]?.title==="Ladybug").length>0))?2:1
        }  else if (state.tutorialPhase===3) {
            return (state.arenaPositions.filter(item=>item?.[0]?.title==="Ladybug" && getWeight(item)===0).length===1) && state.arenaPositions.filter(item=>item.length>0).length===2?4:3
        } else if (state.tutorialPhase===5) {
            
            return (state.turn > 0)?6:5
        } else if (state.tutorialPhase===7) {
            return (state.deck.filter(item=>item?.title==="Bee").length>0)&&(state.turn>0)?8:7
        } else if (state.tutorialPhase===9) {
            return ((state.deck.filter(item=>item?.title==="Bee"&&item?.level>1).length>0)||(state.arenaPositions.filter(item=>item?.[0]?.title==="Bee"&&item?.[0]?.level>1).length>0))?10:9
        } else if (state.tutorialPhase===11) {
            return opponentState?.destroyedPositions?.length>0?12:11
        } else if (state.tutorialPhase===13) {
            return (state.destroyedPositions.length===0&&state.turn>0)?14:13
        } else if (state.tutorialPhase===15) {
            return (state.destroyedPositions.length===0&&state.turn>1)?16:15
        } else {
            return state.tutorialPhase
        }
    },[gameFunctions, opponentState, simulating, hasInspectedCard])

    const handleTurnStart = (state) => {
        if (Math.floor(state.tutorialPhase/2) === 0) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:1,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            if ([...state.hand,...state.deck,...state.arenaPositions.flat(1)].length!==2) {
                setState({...tutorialStates()[0], topPhase:state.topPhase})
            }
        }
        if (Math.floor(state.tutorialPhase/2) === 1) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:3,
                inShop: false,
                paused:false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            if ([...state.hand,...state.deck,...state.arenaPositions.flat(1)].length!==2) {
                setState({...tutorialStates()[1], topPhase:state.topPhase})
            }
            else {
                setState(prevState=>{return{...prevState, paused:false, topPhase: prevState.topPhase}})
            }
        }
        if (Math.floor(state.tutorialPhase/2) === 2) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:5,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: state.awaitingNextTurn,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            if (state?.resetting) setState({...tutorialStates()[2], topPhase:state.topPhase, arenaPositions:(!(state.arenaPositions.flat(1).filter(item=>["Ladybug","Pile of Floaties"].includes(item.title)).length===2))?tutorialStates()[2].arenaPositions:state.arenaPositions})
        }
        if (Math.floor(state.tutorialPhase/2) === 3) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:7,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: state.awaitingNextTurn,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            //TODO reset hand, deck, arena, ladybug weight0, floatie, nothing else
            setState({...tutorialStates()[3], topPhase:state.topPhase})
        }
        if (Math.floor(state.tutorialPhase/2) === 4) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:9,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: state.awaitingNextTurn,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            if (state?.resetting || (state.topPhase>9)) setState({...tutorialStates()[4], topPhase:state.topPhase})
        }
        if (Math.floor(state.tutorialPhase/2) === 5) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:11,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: state.awaitingNextTurn,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            if (state?.resetting || (state.topPhase>11)) setState({...tutorialStates()[5], topPhase:state.topPhase})
        }
        if (Math.floor(state.tutorialPhase/2) === 6) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:13,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: state.awaitingNextTurn,
                sink:false,
                deck: [],
                hand: [],
                sunken: [],
                arenaPositions:[[],[],[allCards()["Frog"]()],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            setState({...tutorialStates()[6], topPhase:state.topPhase})
        }
        if (Math.floor(state.tutorialPhase/2) === 7) {
            const nextOpp = {
                simulating: false,
                tutorialPhase:15,
                inShop: false,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: state.awaitingNextTurn,
                sink:false,
                deck: [],
                hand: [],
                sunken: [],
                arenaPositions:[[allCards()["Big Fish"]()],[allCards()["Balloons"]()],[allCards()["Big Fish"]()],[allCards()["Balloons"]()],[allCards()["Big Fish"]()],[allCards()["Balloons"]()],[allCards()["Big Fish"]()],[allCards()["Balloons"]()]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
            setOpponentState(prevState=>{return {...prevState, ...nextOpp}})
            setState({...tutorialStates()[7], topPhase:state.topPhase})
        }
    }
    const tutorialStates = useCallback(() => {
        const ladybug = allCards()["Ladybug"]()
        const floaties = allCards()["Pile of Floaties"]()
        let turn2ArenaPositions = [[],[],[],[ladybug],[floaties],[],[],[]]
        turn2ArenaPositions = ladybug.enhance(turn2ArenaPositions,3,getProduction(turn2ArenaPositions[3]),ladybug.title+ladybug.position)
        turn2ArenaPositions = floaties.enhance(turn2ArenaPositions,4,getProduction(turn2ArenaPositions[4]),floaties.title+floaties.position)
        return {
            0:{
                simulating: false,
                tutorialPhase:1,
                inShop: false,
                paused:true,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            },
            1:{
                simulating: false,
                tutorialPhase:3,
                inShop: false,
                paused:false,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [...hand],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            },
            2:{
                simulating: false,
                tutorialPhase:5,
                inShop: false,
                paused:false,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [...deck],
                topCard:[...deck][0],
                hand: [],
                sunken: [],
                arenaPositions:turn2ArenaPositions,
                destroyedPositions:[],
                destroyingPositions:[],
            },
            3:{
                simulating: false,
                tutorialPhase:7,
                inShop: false,
                paused:false,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [],
                topCard:{},
                hand: [allCards()["Ladybug"]()],
                sunken: [],
                arenaPositions:[[],[],[],[],[allCards()["Bee"]()],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            },
            4:{
                simulating: false,
                tutorialPhase:9,
                inShop: false,
                paused:false,
                turn: 0,
                shells:1,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [],
                topCard:[],
                hand: [allCards()["Bee"](),allCards()["Bee"](),allCards()["Ladybug"]()],
                sunken: [],
                arenaPositions:[[],[],[],[allCards()["Beehive"]()],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            },
            5:{
                simulating: false,
                tutorialPhase:11,
                inShop: false,
                paused:false,
                turn: 0,
                shells:1,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [],
                topCard:{},
                hand: [allCards()["Ladybug"]()],
                sunken: [],
                arenaPositions:[[],[],[],[allCards()["Beehive"]()],[{...allCards()["Bee"](),level:2}],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            },
            6:{
                simulating: false,
                tutorialPhase:13,
                inShop: false,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [allCards()["Bee"](),allCards()["Bee"](),allCards()["Bee"](),allCards()["Bee"](),allCards()["Bee"]()],
                topCard:allCards()["Bee"](),
                hand: [],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            },
            7:{
                simulating: false,
                tutorialPhase:15,
                inShop: false,
                turn: 0,
                shells:0,
                lastShuffle:-1,
                viewing: false,
                awaitingNextTurn: false,
                sink:false,
                deck: [allCards()["Frog"](),allCards()["Frog"](),allCards()["Frog"](),allCards()["Frog"]()],
                topCard:allCards()["Frog"](),
                hand: [allCards()["Balloons"](),allCards()["Balloons"]()],
                sunken: [],
                arenaPositions:[[],[],[],[],[],[],[],[]],
                destroyedPositions:[],
                destroyingPositions:[],
            }
        }
    },[deck,hand])
    //update tutorial phase
    useEffect(()=>{
        setTimeout(()=>{
            //TOXDO:
            //FIX WHY IS BEEHIVE GOING AWAWY WHAT
            setState(prevState=>{const phase = nextTutorialPhase(prevState);const topPhase = phase===false?prevState.tutorialPhase:phase; return {...prevState, lastShuffle:-1, topPhase:topPhase>prevState.topPhase?topPhase:prevState.topPhase}})
            setUpdates(updates+1)
        },1000)
    }, [updates,nextTutorialPhase])

    useEffect(()=>{
        recoverDeck()
        // eslint-disable-next-line
    },[setState])

    useEffect(()=>{
        if (state?.tutorialPhase>0) {
            localStorage.setItem("tutorial",processUploadState(state))
        }
    },[state.hand, state.arenaPositions,state,state.tutorialPhase, state.topPhase])

    useEffect(()=>{
        setOpponentState(prevState=>{return{...prevState, awaitingNextTurn: state.awaitingNextTurn, turn: state.turn}})
        // eslint-disable-next-line
    },[state.awaitingNextTurn])

    useEffect(()=>{
        let removedState = {}
        if (opponentState.draw) {
            removedState = {
                ...state,
                paused:true,
                lost:false,
                won:false,
                draw:true,
            }
        } 
        else if (opponentState.lost) {
            removedState = {
                ...state,
                paused:true,
                lost:false,
                won:true,
            }
        } 
        else if (opponentState.won) {
            removedState = {
                ...state,
                paused:true,
                lost:true,
                won:false,
                draw:false,
            }
        } 
        if (removedState?.arenaPositions) {
            setState(removedState)
        }
        // eslint-disable-next-line
    },[opponentState, setState])

    useEffect(()=>{
        if (state?.destroyedPositions?.length>0 && !state.simulating && !simulating && state.tutorialPhase > 12) {
            setFailed(true)
        }
    },[state.destroyedPositions, state.tutorialPhase, state.simulating,simulating])
    
    return (
        <div className='App' style={{height:'100vh'}}>
            <TutorialCard 
                setFrog={setFrog}
                state={state}
                reset={()=>{
                    setFrog(false)
                    localStorage.removeItem("tutorial")
                    setHasInspectedCard([])
                    arena.current.setState({...arena.current.state,highlightedCards:[],selectedPlankCard:{}})
                    setState({
                        topPhase:0,
                        simulating: false,
                        tutorialPhase:0,
                        inShop: false,
                        paused:true,
                        turn: 0,
                        shells:0,
                        lastShuffle:-1,
                        viewing: false,
                        awaitingNextTurn: false,
                        sink:false,
                        deck: [...deck],
                        topCard:[...deck][0],
                        hand: [...hand],
                        sunken: [],
        
                        arenaPositions:[[],[],[],[],[],[],[],[]],
                        destroyedPositions:[],
                        destroyingPositions:[],
                    })
                    localStorage.removeItem("tutorial")
                }}
                phase={state.tutorialPhase}
                hasInspectedCard={hasInspectedCard}
                nextPhase={()=>{
                    setFrog(false)
                    if ((state.tutorialPhase%2===0)||(state.topPhase>state.tutorialPhase)) {
                        const nextPhase = state.tutorialPhase+1
                        arena.current.setState({...arena.current.state,highlightedCards:[],selectedPlankCard:{}})
                        const newState = {...state, tutorialPhase: nextPhase, topPhase: state.topPhase>nextPhase?state.topPhase:nextPhase, inshop:false, paused:nextPhase<2, simulating:false, awaitingNextTurn:false, turn:0, shells:0}
                        setState(newState)
                        if ((nextPhase-1)%2===0) {
                            handleTurnStart(newState)
                        }
                    } else {
                        setState(prevState=>{return {...prevState, turn:0, shells:0}})
                    }
                }} 
                restartPhase={()=>{
                    setFrog(false)
                    arena.current.setState({...arena.current.state,highlightedCards:[],selectedPlankCard:{}})
                    setState(prevState=>{return {...prevState,...tutorialStates()[Math.floor(prevState.tutorialPhase-1)/2], tutorialPhase: prevState.tutorialPhase-1,resetting:true, inshop:false, simulating:false, awaitingNextTurn:false}})
                }}
                lastPhase={()=>{
                    setFrog(false)
                    arena.current.setState({...arena.current.state,highlightedCards:[],selectedPlankCard:{}})
                    setHasInspectedCard([])
                    setState(prevState=>{return {...prevState,...tutorialStates()[Math.floor((prevState.tutorialPhase-2)/2)], tutorialPhase: prevState.tutorialPhase-2,resetting:true, inshop:false, simulating:false, awaitingNextTurn:false}})
                }}
                open={state.tutorialPhase%2===0}
                simulating={state.inShop||state.exiting||state.simulating||simulating||state.awaitingNextTurn||(state.paused&&state.tutorialPhase>3)}    
            />
            {
            state?.hand?
            <Arena 
                ref={arena}
                tutorial={true}
                onCloseCardInfo={(card)=>{setHasInspectedCard(prevState=>{return [...prevState,card]})}}
                handleExit={handleExit} 
                gameFunctions={gameFunctions} 
                simulateCards={(state, opponentState)=>gameFunctions.simulateCards(state, opponentState)} 
                canLeave={canLeave} 
                opponentUsername={opponentUsername} 
                currentCard={currentCard} 
                setCurrentCard={setCurrentCard} 
                id={id} simulating={simulating} 
                setSimulating={(s)=>{setSimulating(s)}} 
                updatePlayer={updatePlayer} 
                opponentState={opponentState} 
                setOpponentState={setOpponentState} 
                state={state}
                setState={setState}
            />
            :
            <></>
            }
            <CardInfo onCloseCardInfo={()=>{setFrog(false)}} setCurrentCard={()=>{}} currentCard={[frog?allCards()["Frog"]():{}]}/>
            <div className="CardShopBackgroundOuter" style={{display: !failed?"none":"", position:"absolute", zIndex:900}}></div>
                <div className="CardShopBackgroundInner" style={{display: !failed?"none":"", zIndex:900, borderRadius:"5vh",position:"absolute", top:"35vh", left:0, right:0, margin:"auto", width: "36vh", height:"24vh", backgroundColor:"white"}}></div>
                <div className="CardShopBackgroundCards" style={{display: !failed?"none":"", zIndex:900, position:"absolute", top:"35vh", left:0, right:0, margin:"auto"}}>
                    <div style={{display: !failed?"none":"", zIndex:900, width:"28vh",position:"relative", top:"4vh", left:0, right:0, margin:"auto", fontWeight:"800", fontSize:'1.75vh'}}>You Failed To Defend Against The {state.tutorialPhase>13?"Big Fish":"Frog"}.</div>
                    <button className="RedButtonSmall" style={{margin:"auto", top:'10vh',width:"12vh", fontSize:'2vh', lineHeight:"4vh", fontWeight:"800"}} onClick={()=>{
                        arena.current.setState({...arena.current.state,highlightedCards:[],selectedPlankCard:{}})
                        setFrog(false)
                        setHasInspectedCard([])
                        setState(prevState=>{return {...prevState,...tutorialStates()[Math.floor(prevState.tutorialPhase/2)], tutorialPhase: state.tutorialPhase>13?14:12,resetting:true, inshop:false, simulating:false, awaitingNextTurn:false}})
                        setFailed(false)
                    }}>Redo</button>
                </div>
        </div>
    )
}
