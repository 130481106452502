import { allCards } from "../Data/Card/Cards";
import { drawTrophies, getTrophies, lossTrophies, winTrophies } from "./UserDataTableFunctions";
import { client, myDecipherJWT, myDecipherGameID, myCipherGameID, getUser } from "./UserFunctions";

export const cardMapToRep =(cardCard)=>{
    const isArenaPositions = cardCard instanceof Array
    const card = isArenaPositions?cardCard[0]:cardCard
    if (!(card?.title) && isArenaPositions) return []
    const rep = {position:card.position, health:card.health, title:card.title, turnsLeft: card.turnsLeft, beetle:card.beetle, floatie: card.floatie, ladybug: card.ladybug, cardSinked:card.cardSinked, level:card.level}
    return isArenaPositions? 
    [rep]
    :
    rep
    
}

export const repMapToCards =(cardRep, keepStats=true)=>{
    const isArenaPositions = cardRep instanceof Array
    const rep = isArenaPositions?cardRep[0]:cardRep
    if (!(rep?.title) && isArenaPositions) return []
    const newCard = allCards()[rep.title]()
    const card = !keepStats?{...newCard, position: rep.position, level:rep.level}:{...newCard, ...rep}
    return isArenaPositions? 
    [card]
    :
    card
}

export const processDownloadState = (state) => {
    let newState = JSON.parse(state)
    newState = {...newState, 
        deck:newState?.deck?newState.deck.map((card)=>repMapToCards(card,true)):[],
        hand: newState?.hand?newState.hand.map((card)=>repMapToCards(card,true)):[],
        arenaPositions: newState?.arenaPositions?newState.arenaPositions.map((card)=>{return card.cardSinked?[]:repMapToCards(card,true)}):[[],[],[],[],[],[],[],[]],
        paused: newState.simulating||newState?.awaitingNextTurn
    }
    return newState
}

export const processUploadState = (newState) => {
    let state = {...newState}
    //turn cards into representations
    const newDeck = state.deck?.map(cardMapToRep)
    const newHand = state.hand?.map(cardMapToRep)
    const newArenaPositions = state.arenaPositions?.map(cardMapToRep)
    state.deck = newDeck?newDeck:[]
    state.hand = newHand?newHand:[]
    state.arenaPositions = newArenaPositions?newArenaPositions:[[],[],[],[],[],[],[],[]]
    return JSON.stringify(state)
}
export const checkGameStillExists = (id, callback) => {
    const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
    let users = []
    try {
        users = JSON.parse(myDecipherGameID(id))?.users
    }
    catch (e) {
        callback(false)
    }
    if (users?.length===2 && (users?.filter(user=>user!==username).length===1)) {
        const params = {
            TableName : 'DecksterousMatchMaking',
            Key: {
                Username: users?.filter(user=>user!==username)[0],
            }
        };
    
        client.get(params, async function(err, data) {
            if (err) {
            return console.log(err)
            }
            else {
                const oppPair = data?.Item?.Pair
                const oppPaired = data?.Item?.Paired
                if  ((oppPair===username)&&(oppPaired)) {
                    callback(true)
                }
                else {
                    callback(false)
                }
            }
        }
        );
    }
}

export const getOpponentState = (id, turn, callback) => {
    const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
    let users = []
    try {
        users = JSON.parse(myDecipherGameID(id))?.users
    }
    catch (e) {
        return
    }
    
    if (users?.length===2 && (users?.filter(user=>user!==username).length===1)) {//if exists user that isnt me in gameid
        const params = {
            TableName : 'DecksterousGame',
            AttributesToGet: ['GameID', 'Username', 'Ended', 'EndedTime', 'Turn', 'TimeStamp'],
          };
        
        client.scan(params, async function(err, data) {
            if (err) {  
                return console.log(err)
            } else {
                const states = data?.Items.filter(item=>(item.GameID===id))
                const opp = states.filter(item=>item.Username!==username)[0]
                    if (opp) {
                        getTurn(opp.Username, id, turn, (turnData)=>{
                            if (turnData) {
                                callback(opp,turnData)
                            }
                        })
                    } else {
                        callback(false)
                    }
                
            }
        });
    }
};

export const getPlayerState = (id, callback) => {
    const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
    let users = []
    try {
        users = JSON.parse(myDecipherGameID(id))?.users
    }
    catch (e) {
        return
    }
    
    if (users?.length===2 && (users?.filter(user=>user!==username).length===1)) {//if exists user that isnt me in gameid
        const params = {
            TableName : 'DecksterousGame',
            AttributesToGet: ['GameID', 'Username', 'Ended', 'EndedTime', 'Turn', 'TimeStamp'],
            Key: {
                GameID:id,
                Username:username,
            }
          };
        
        client.get(params, async function(err, data) {
            if (err) {  
                callback('error','error')
                return console.log(err)
            } else {
                const playerGame = data?.Item
                    if (playerGame) {
                        getTurn(playerGame.Username, id, playerGame.Turn, (turnData)=>{
                            callback(playerGame,turnData)
                        })
                    } else {
                        callback(false)
                    }
                
            }
        });
    }
};

const getTurn = (username, id, turn, callback) => {
    if (turn!==undefined && id) {
        const turnID = `${id}:${turn}`
        const params = {
            TableName : 'DecksterousGameTurn',
            AttributesToGet: ['TurnID', 'Username', 'State'],
            Key: {
                TurnID: turnID,
                Username: username
            }
        };
        client.get(params, async function(err, data) {
            if (err) {  
                callback('error')
                return console.log(err)
            } else {
                const turn = data?.Item
                if (turn) {
                    callback(turn)
                } else {
                    callback(0)
                }
            }
        })
    }
}

const putTurn = (id, username, state, callback) => {
    const params = {
        TableName : 'DecksterousGameTurn',
        Item: {
            TurnID: `${id}:${state.turn}`,
            Username: username,
            State: processUploadState(state),
            TimeStamp:new Date().valueOf()
        }
    };
    client.put(params, function(err, turn) {
    if (err) {
        console.log(err)
    }
    else {
        callback(turn)
    }
    });
}

export const putPlayerState = (id, state, callback=()=>{}) => {
    
    
    getTrophies(trophies=>{
        const jsonWT = localStorage.getItem("JWT")
        const {username} = JSON.parse(myDecipherJWT(jsonWT))
        getGame(username, id, (game)=>{
            const params = {
                TableName : 'DecksterousGame',
                Item: {
                    GameID: id,
                    Turn: state.turn,
                    Username: username,
                    TimeStamp: game.TimeStamp,
                    Ended: game.Ended??false,
                    EndedTime: game.EndedTime??null,
                    Trophies:trophies??0
                }
            };
            client.put(params, function(err, game) {
            if (err) {
                console.log(err)
            }
            else {
                putTurn(id,username,state,(turn)=>{
                    callback(game,turn)
                })
            }
            });
        })
    })
    
}

export const removeGame = (id, state, callback) => {
    const jsonWT = localStorage.getItem("JWT")
    const {username} = JSON.parse(myDecipherJWT(jsonWT))
    getGame(username, id, (game)=>{
        const params = {
            TableName : 'DecksterousGame',
            Item: {
                GameID: id,
                Turn: state.turn,
                Username: username,
                TimeStamp: game.TimeStamp,
                Ended: true,
                EndedTime: new Date().valueOf(),
                Trophies:game.Trophies
            }
        };
        client.put(params, function(err, data) {
        if (err) {
            callback(false)
            return console.log(err)
        }
        else {
            putTurn(id,username,state,()=>{
                const userRequest = {
                    Username: username,
                    Pair: "uninit",
                }
                const params = {
                    TableName : 'DecksterousMatchMaking',
                    Item: userRequest
                };
                client.put(params, async function(err) {
                    if (err) {
                        callback(false)
                        return console.log(err)
                    } else {
                        callback(true)
                    }
                })
            })
            
    
        }
        });
    })
}

export const checkGameNotOver = (id, callback) => {
    const params = {
        TableName : 'DecksterousGame',
        AttributesToGet: ['GameID', 'Username','Ended'],
    };
    
    client.scan(params, async function(err, data) {
        if (err) {  
            return console.log(err)
        } else {
            const states = data?.Items.filter(item=>(item.GameID===id))
            const endedStates = states.filter(item=>item.Ended)
            if (states.length>0 && endedStates.length===0) {
                callback(true)
            }
        }
    })
}

export const getCurrentGames = (username, callback) => {
    const params = {
        TableName : 'DecksterousGame',
        AttributesToGet: ['GameID', 'Username', 'Ended'],
      };
    
    client.scan(params, async function(err, data) {
        if (err) {  
            callback(0)
            return console.log(err)
        } else {
            const myGames = data?.Items?.filter(item=>!item.Ended && item.Username===username)
            if (myGames?.length>0) {
                callback(myGames[0].GameID)
            } else {
                callback(0)
            }
        }
    })
}

export const endGame = (player, gameID, callback) => {
    const params = {
        TableName : 'DecksterousGame',
        AttributesToGet: ['GameID', 'Username', 'Ended', 'EndedTime', 'Turn', 'TimeStamp','Trophies'],
      };
    
    client.scan(params, async function(err, data) {
        if (err) {  
            callback(false)
            return console.log(err)
        } else {
            const myGame = data?.Items?.filter(item=>!item.Ended && item.Username===player && item.GameID === gameID)
            if (myGame?.length===1) {
                const params = {
                    TableName : 'DecksterousGame',
                    Item: {
                        GameID: gameID,
                        Turn: myGame.Turn,
                        Username: player,
                        TimeStamp: myGame.TimeStamp,
                        Ended: true,
                        EndedTime: new Date().valueOf(),
                        Trophies: myGame.Trophies
                    }
                };
                client.put(params, function(err, data) {
                if (err) console.log(err);
                else callback(data);
                });
            } else {
                callback(false)
            }
        }
    })
}

export const getGame = (username, id, callback) => {
    const params = {
        TableName : 'DecksterousGame',
        AttributesToGet: ['GameID', 'Username', 'Ended', 'EndedTime', 'Turn', 'TimeStamp','Trophies'],
      };
    
    client.scan(params, async function(err, data) {
        if (err) {  
            callback(false)
            return console.log(err)
        } else {
            const myGames = data?.Items?.filter(item=>item.Username===username && item.GameID===id)
            if (myGames?.length>0) {
                callback(myGames[0])
            } else {
                callback(false)
            }
        }
    })
}
const getTurns = (username,games,callback) =>{
    const params = {
        TableName : 'DecksterousGameTurn',
        AttributesToGet: ['TurnID', 'Username', 'State'],
      };
    
    client.scan(params, async function(err, data) {
        if (err) {  
            return console.log(err)
        } else {
            const gottenTurns = data?.Items?.filter(turn=>turn.Username!==username)
            let myTurns = []
            for (let gameIdx in games) {
                let game = games[gameIdx]
                const nextTurn = gottenTurns.filter(turn=>{
                    const turnID = `${game.GameID}:${game.Turn}`
                    return turn.TurnID===turnID
                })?.[0]
                myTurns.push(nextTurn)
            }
            if (myTurns) callback(myTurns)
        }
    })
}

export const getGameStatus = (id, callback) => {
    const usernames = JSON.parse(myDecipherGameID(id)??"{}")?.users
    getUser(username=>{
        const oppUser = usernames.filter(u=>u!==username)?.[0]??""        
        getGame(username,id,currentGame=>{
            getGame(oppUser, id, game=>{
                if (!game) {  
                    callback(false)
                } else {
                    let trophies = [0,0]
                    let status = "draw"
                    getTurn(username,id,currentGame?.Turn??0,turn=>{
                        getTurn(oppUser,id,game?.Turn??0,oppTurn=>{
                            if (!turn?.State) {
                                callback(false)
                            }
                            if (!oppTurn?.State) {
                                callback(false)
                            }
                            const state = JSON.parse(turn?.State)
                            console.log(turn)
                            if (!state) {
                                callback(false)
                            }
                            trophies = [currentGame?.Trophies??0,game?.Trophies??0]
                            let trophiesFromGame = 0
                            if (state.lost) {
                                status = "loss"
                                trophiesFromGame += lossTrophies(trophies)
                            } else if (state.won) {
                                status = "win"
                                trophiesFromGame += winTrophies(trophies)
                            } else if (state.draw) {
                                status = "draw"
                                trophiesFromGame += drawTrophies(trophies)
                            }
                            callback({currentTrophies:currentGame?.Trophies,trophiesFromGame:trophiesFromGame,status:status})
                        })
                    })
                }
            })
        })
    })
}

export const getGameStatuses = (ids, callback) => {
    getUser(username=>{
        const params = {
            TableName : 'DecksterousGame',
            AttributesToGet: ['GameID', 'Username', 'Ended', 'EndedTime', 'Turn', 'TimeStamp','Trophies'],
          };
        
        client.scan(params, async function(err, data) {
            if (err) {  
                return console.log(err)
            } else {
                const myGames = data?.Items?.filter(item=>(ids.includes(item.GameID))&&(item.Username===username))
                const oppGames = data?.Items?.filter(item=>(ids.includes(item.GameID))&&(item.Username!==username))
                if (oppGames?.length>0) {
                    let currentTrophies = 0
                    let trophies = [0,0]
                    getTurns(username,oppGames,turns=>{
                        for (let gameIdx = 0; gameIdx < oppGames.length;gameIdx++){
                            const game = oppGames[gameIdx]
                            const turn = turns[gameIdx]
                            const currentGame = myGames.filter(item=>item.GameID===game.GameID)[0]
                            if (!turn?.State) {
                                continue
                            }
                            const state = JSON.parse(turn?.State)
                            if (!state) {
                                continue
                            }
                            trophies = [currentGame?.Trophies??0,game?.Trophies??0]
                            let trophiesFromGame = 0
                            if (state.lost) {
                                trophiesFromGame += winTrophies(trophies)
                            } else if (state.won) {
                                trophiesFromGame += lossTrophies(trophies)
                            } else if (state.draw) {
                                trophiesFromGame += drawTrophies(trophies)
                            }
                            currentTrophies += trophiesFromGame
                            if (gameIdx===((oppGames.length)-1)) {
                                callback(currentTrophies)
                            }
                        }
                    })
                }
            }
        })
    })
}

export const opponentGameEnded = (gameID, username, callback) => {
    const params = {
        TableName : 'DecksterousGame',
        AttributesToGet: ['GameID', 'Username', 'Ended'],
      };
    
    client.scan(params, async function(err, data) {
        if (err) {  
            callback(false,false)
            return console.log(err)
        } else {
            if (!data?.Items?.filter(item=>item.GameID===gameID)) callback(false,false)
            const myEndedGames = data?.Items?.filter(item=>item.GameID===gameID && item.Username===username && item.Ended)
            const opponentEndedGames = data?.Items?.filter(item=>item.GameID===gameID && item.Username!==username && item.Ended)
            callback(opponentEndedGames.length>0, myEndedGames.length>0)
        }
    })
}

export const createGame = (username, timeStamp, opponent, opponentTimeStamp, callback) => {
    getTrophies(trophies=>{
        const gameID = myCipherGameID(JSON.stringify((opponentTimeStamp<timeStamp)?({users:[opponent, username],TimeStamp:opponentTimeStamp}):({users:[username,opponent], TimeStamp: timeStamp})))
        const params = {
        TableName : 'DecksterousGame',
        Item: {
            GameID: gameID,
            Turn: 0,
            Username: username,
            TimeStamp:new Date().valueOf(),
            Ended: false,
            EndedTime: null,
            Trophies:trophies??0
        }
        };
        client.put(params, function(err, _) {
            if (err) console.log(err);
            else callback(gameID);
        });
    })
}
