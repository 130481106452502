
//import crab from '../Images/crab.png';
export const images = {
    "Worm":()=><img 
        draggable="false"
        style={{width:'10vh', height:"auto"}} 
        src="https://png.pngtree.com/png-clipart/20230304/ourmid/pngtree-cuteworm-png-image_6631661.png" 
        alt="Worm"
    />,
    "Fly":()=><img 
        draggable="false"
        style={{width:'5vh', height:'5vh', paddingTop:'1.5vh'}} 
        src="https://images.vexels.com/media/users/3/242241/isolated/preview/409d95bf597e130c6c1b1d2ac3f5dbf5-side-fly-geometric-color-stroke.png" 
        alt="Fly"
    />,
    "Big Fish":()=><img 
        draggable="false"
        style={{width:'11.5vh', height:"auto", paddingTop:'1.5vh'}} 
        src="https://freepngtransparent.com/wp-content/uploads/2023/03/Fish-Png-163.png" 
        alt="Big Fish"
    />,
    "Fish":()=><img 
        draggable="false"
        style={{width:'9vh', height:"auto", paddingTop:'.75vh'}} 
        src="https://www.pngmart.com/files/23/Cartoon-Fish-PNG-Transparent.png" 
        alt="Fish"
    />,
    "Frog":()=><img 
        draggable="false"
        style={{width:'9vh', height:"auto", paddingTop:'.15vh'}} 
        src="https://cdn0.iconfinder.com/data/icons/amphibians-colored/48/Animals_Amphibians_Artboard_2-512.png" 
        alt="Frog"
    />,
    "Crab":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto", paddingTop:'1.25vh'}} 
        src="https://pngimg.com/d/crab_PNG25.png" 
        alt="Crab"
    />,
    "Fishing Hut":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto"}} 
        src="https://png.pngtree.com/png-clipart/20230913/original/pngtree-fishing-pole-vector-png-image_11065176.png" 
        alt="Fishing Hut"
    />,
    "Crab Trap":()=><img 
        draggable="false"
        style={{width:'10vh', height:"auto", paddingTop:'1vh'}} 
        src="https://i.pinimg.com/originals/1d/49/ba/1d49ba03fe41cfd71539075b27773332.png" 
        alt="Crab Trap"
    />,
    "Balloons":()=><img 
        draggable="false"
        style={{width:'5vh', height:"auto"}} 
        src="https://pngimg.com/d/balloon_PNG4958.png" 
        alt="Balloons"
    />,
    "Net":()=><img 
        draggable="false"
        style={{width:'5vh', height:"auto"}} 
        src="https://clipart-library.com/img/2002316.png" 
        alt="Net"
    />,
    "Pile of Floaties":()=><img 
        draggable="false"
        style={{width:'7vh', height:"auto", paddingTop:'.25vh'}} 
        src="https://static.vecteezy.com/system/resources/thumbnails/014/179/689/small_2x/flamingo-pool-float-png.png" 
        alt="Pile of Floaties"
    />,
    "Pile of Floaties Icon":()=><img 
        draggable="false"
        style={{width:'1vh', height:"auto"}} 
        src="https://static.vecteezy.com/system/resources/thumbnails/014/179/689/small_2x/flamingo-pool-float-png.png" 
        alt="Pile of Floaties"
    />,
    "Ladybug":()=><img 
        draggable="false"
        style={{width:'5vh', height:"auto", paddingTop:'1.5vh'}} 
        src="https://png.pngtree.com/png-vector/20220713/ourmid/pngtree-insect-ladybug-cartoon-bugs-character-png-image_5932864.png" 
        alt="Ladybug"
    />,
    "Ladybug Icon":()=><img 
        draggable="false"
        style={{width:'1vh', height:"auto"}} 
        src="https://png.pngtree.com/png-vector/20220713/ourmid/pngtree-insect-ladybug-cartoon-bugs-character-png-image_5932864.png" 
        alt="Ladybug"
    />,
    "Beetle":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto", paddingTop:'0vh'}} 
        src="https://static.lioden.com/images/beetles/bolbafferprinceps/female/left/brown.png" 
        alt="Beetle"
    />,
    "Beetle Icon":()=><img 
        draggable="false"
        style={{width:'1vh', height:"auto", paddingTop:'2vh'}} 
        src="https://static.lioden.com/images/beetles/bolbafferprinceps/female/left/brown.png" 
        alt="Beetle"
    />,
    "Perfect Stick":()=><img 
        draggable="false"
        style={{width:'10vh', height:"auto", paddingTop:'2.75vh'}} 
        src="https://images.freeimages.com/vhq/images/previews/3ac/colored-wooden-stick-105676.png" 
        alt="Perfect Stick"
    />,
    "Life Preserver":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto", paddingTop:'0vh'}} 
        src="https://cdn4.iconfinder.com/data/icons/zira/128/life-preserver-512.png" 
        alt="Life Preserver"
    />,
    "Repair Kit":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto", paddingTop:'0vh'}} 
        src="https://png.pngtree.com/png-clipart/20220911/original/pngtree-vector-red-toolbox-with-tool-kit-png-image_8544472.png" 
        alt="Life Preserver"
    />,
    "Bandages":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto", paddingTop:'0vh'}} 
        src="https://static.vecteezy.com/system/resources/previews/019/199/297/original/hand-drawn-gauze-bandage-illustration-png.png" 
        alt="Life Preserver"
    />,
    "Beehive":()=><img 
        draggable="false"
        style={{width:'6vh', height:"auto", paddingTop:'0vh'}} 
        src="https://static.vecteezy.com/system/resources/previews/009/384/385/original/bee-tree-hive-clipart-design-illustration-free-png.png" 
        alt="Beehive"
    />,
    "Bee":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto"}} 
        src="https://png.pngtree.com/png-clipart/20210912/original/pngtree-realistic-bee-insect-png-image_6745951.jpg" 
        alt="Bee"
    />,
    "Slug":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto"}} 
        src="https://attic.sh/8qhrnscwar9egk2i7gq2by9alq7y" 
        alt="Slug"
    />,
    "Snail":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto",position:"relative",top:"-3vh"}} 
        src="https://i.pinimg.com/originals/1f/d0/98/1fd0986cdde850ee7f16f90d82030253.png" 
        alt="Snail"
    />,
    "Clam":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto"}} 
        src="https://png.pngtree.com/png-vector/20230808/ourmid/pngtree-clam-shell-vector-png-image_6874353.png" 
        alt="Slug"
    />,
    "Pearl":()=><img 
        draggable="false"
        style={{width:'8vh', height:"auto"}} 
        src="https://cdn2.iconfinder.com/data/icons/jeweler/500/vab904_29_white_pearl_isometric_cartoon_wedding_computer_fashion-512.png" 
        alt="Snail"
    />,
}