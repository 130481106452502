import UserButton from "../Components/Main/UserButton"
import Waves from "../Components/Main/Waves"

import { myDecipherJWT } from "../TableFunctions/UserFunctions"

export default function UserPage ({shortScreen}) {
    const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
    return (
        <div className="App" style={{height:'80vh'}}>
            <UserButton inUser={true} handleUser={()=>{window.location.href="/"}}/>
            <div>
                <Waves viewing={false}/>
                <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}}>
                    <div style={{height:'25vh', fontSize: '5vh'}}>
                        <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
                    </div>
                    <div style={{position:"relative", width:"80vw", height:'60vh', top:'0vh',left:"10vw", borderRadius:'5vh', backgroundColor:"#ffffffaa"}}>
                        <div style={{position:'absolute',left:0,right:0,margin:'auto', top:'5vh', fontSize: '4vh', fontWeight:"bold"}}>{username}</div>
                        <button className="CreateAccountButtonSmall" onClick={()=>{localStorage.removeItem("JWT");window.location.href = '/'}}>Log Out</button>
                    </div>
                </div>
                
            </div>

        </div>
    )
}