import { useEffect, useState } from "react"
import Tabs from "../Components/Main/Tabs"
import UserButton from "../Components/Main/UserButton"
import Waves from "../Components/Main/Waves"
import '../Styles/Home.css'
import { checkUserExists, getUser } from "../TableFunctions/UserFunctions"
import { addUserData, getFriends } from "../TableFunctions/UserDataTableFunctions"

export default function Home ({isLoggedIn, loaded,userData}) {
    const [searchingUser, setSearchingUser] = useState("")
    const [friends,setFriends] = useState([])
    useEffect(()=>{
        getFriends(setFriends)
    },[])
    const addFriend = () => {
        if (!searchingUser) return
        getUser(username=>{
            if (username!==searchingUser) {
                checkUserExists(searchingUser,exists=>{
                    if (exists===1) {
                        addUserData({friends:[searchingUser]},setFriends)
                        setSearchingUser("")
                    } else {
                    }
                }) 
            }
        })
    }
    const acceptFriend = (friend) => {
        if (!friend) return
        getUser(username=>{
            if (username!==friend) {
                checkUserExists(friend,exists=>{
                    if (exists===1) {
                        addUserData({friends:[friend]},setFriends)
                    } else {

                    }
                }) 
            }
        })
    }
    const handleFriendSearch = (e) => {
        const code = (e.keyCode ? e.keyCode : e.which);
        if (code ===13) {
            addFriend()
        }
    }

    const removeFriend = (e,friend) => {
        if (!friend) return
        getUser(username=>{
            if (username!==friend) {
                checkUserExists(friend,exists=>{
                    if (exists===1) {
                        addUserData({removingFriends:[friend]},setFriends)
                    }
                }) 
            }
        })
    }
    const declineRequest = (e,friend) => {
        if (!friend) return
        getUser(username=>{
            if (username!==friend) {
                checkUserExists(friend,exists=>{
                    if (exists===1) {
                        addUserData({declinedFriends:[friend]},setFriends)
                    } else {

                    }
                }) 
            }
        })
    }
    const challengeUser = (e, friend) => {
        window.location.href = `/Challenge?user=${friend}`
    }
    const tabs = [
        {
            id:0,
            title:"Deck",
            icon:()=>{
                return (<i className="fa fa-file"></i>)
            },
            content: ()=>{
                return (
                <>
                </>
                )
            }
        },
        {
            id:1,
            title:"Home",
            icon:()=>{
                return (<i className="fa fa-home"></i>)
            },
            content: ()=>{
                return (
                <div style={{display:"flex", flexDirection:"column",gap:"8vh", paddingTop:"20vh", left:0, right:0, margin:"auto"}}>
                    <div style={{display:"flex", left:0, right:0, margin:"auto", gap:"4vw"}}>
                        <button className="SignInButton" style={{left:"unset",top:"unset", width:"16rem",height:"12rem", fontSize:"2rem", maxWidth:"25vw", maxHeight:"15vh"}} onClick={()=>{window.location.href = '/findgame'}}>Play Online</button>
                        <button className="CreateAccountButton" style={{left:"unset",top:"unset", width:"16rem",height:"12rem", fontSize:"2rem", maxWidth:"25vw", maxHeight:"15vh"}}  onClick={()=>{window.location.href = '/play'}}>Campaign</button>
                    </div>
                    <button className="BlueButtonSmall" style={{top:"unset",  width:"15vh", left:0, right:0, margin:"auto"}} onClick={()=>{localStorage.removeItem("tutorial");window.location.href = '/tutorial'}}>Tutorial</button>
                </div>
                )
            }
        },
        {
            id:2,
            title:"Social",
            icon:()=>{
                return (<i className="fa fa-comments"></i>)
            },
            content: ()=>{
                return (
                    <div style={{display:"flex", flexDirection:"column",gap:"4vh", left:0, right:0, margin:"auto",fontSize:"1.5rem",fontWeight:500}}>
                        <div style={{display:"flex", left:0, right:0, margin:"auto", gap:"4vw",lineHeight:"3rem",height:"3rem"}}>
                            <input type="text" onChange={e=>setSearchingUser(e.target.value)} onKeyDown={handleFriendSearch} value={searchingUser} placeholder="Search User" style={{width:"25rem",outline:".5rem solid #1f5171",borderRadius:"1rem",border:"none",fontSize:"1.5rem",fontWeight:500,maxWidth:"25vw",padding:"1rem"}}/>
                            <button className="SignInButtonSmall" style={{top:"unset",  width:"7rem", left:0, right:0, margin:"auto",height:"2rem",fontSize:"1rem"}} onClick={addFriend}>Add Friend</button>
                        </div>
                        <div>Friends</div>
                        <div style={{height:"40vh",overflowY:"auto", overflowX:"hidden", minWidth:"38rem", width:"70vw",left:0,right:0,margin:"auto"}}>
                        <div style={{display:"flex",flexDirection:"column",gap:"1vh", minWidth:"36rem", width:"60vw", left:0,right:0,margin:"auto", justifyContent:"flex-start"}}>
                            {friends?.requesting?.map((friend,idx)=>
                                <div key={idx} style={{left:0,right:0,margin:"auto", minWidth:"30rem",width:"80%",backgroundColor:"#ffffffaa",borderRadius:"1rem",lineHeight:"3rem",height:"3rem",display:"grid",gridTemplateColumns:"6rem 12rem auto 6rem",paddingLeft:"3rem",paddingRight:"3rem", justifyItems:"left"}}>
                                    <div><i className="fa fa-trophy"></i>:{friend?.trophies??0}</div>
                                    <div>{friend?.name?.length>13?friend.slice(0,13)+"...":friend?.name}</div>
                                    <div style={{display:"flex",gap:"3rem"}}>
                                        <div style={{fontSize:"1.25rem",color:"#55dd55",display:"flex"}}>
                                            {"Requesting..."}
                                        </div>
                                        
                                    </div>
                                    <div style={{display:"flex", gap:"3rem"}}>
                                        <button className="SignInButtonSmall" style={{width:"1.5rem",height:"1.5rem",top:".75rem",fontSize:"1rem",lineHeight:"1.5rem"}} onClick={()=>{acceptFriend(friend.name)}}>&#x2713;</button>
                                        <button  className="RedButtonSmall" style={{width:"1.5rem",lineHeight:"1.5rem",height:"1.5rem",top:".75rem",fontSize:"1rem"}} onClick={e=>declineRequest(e,friend.name)}>X</button>
                                    </div>
                                </div>
                            )}
                            {friends?.friends?.map((friend,idx)=>
                                <div key={idx} style={{left:0,right:0,margin:"auto", minWidth:"30rem",width:"80%",backgroundColor:"#ffffffaa",borderRadius:"1rem",lineHeight:"3rem",height:"3rem",display:"grid",gridTemplateColumns:"6rem 12rem auto 6rem",paddingLeft:"3rem",paddingRight:"3rem", justifyItems:"left"}}>
                                    <div><i className="fa fa-trophy"></i>:{friend?.trophies??0}</div>
                                    <div>{friend?.name?.length>13?friend.slice(0,13)+"...":friend?.name}</div>
                                    <div style={{display:"flex",gap:"3rem"}}>
                                        <div style={{fontSize:"1.25rem",color:"#55dd55",display:"flex"}}>
                                            {"Friends"}
                                        </div>
                                        
                                    </div>
                                    <div style={{display:"flex", gap:"3rem"}}>
                                        <button className="BlueButtonSmall" style={{width:"1.5rem",height:"1.5rem",top:".75rem",fontSize:"1rem",lineHeight:"1.5rem"}} onClick={(e)=>challengeUser(e,friend.name)}>&#9654;</button>
                                        <button  className="RedButtonSmall" style={{width:"1.5rem",lineHeight:"1.5rem",height:"1.5rem",top:".75rem",fontSize:"1rem"}} onClick={e=>removeFriend(e,friend.name)}>X</button>
                                    </div>
                                </div>
                            )}
                            {friends?.pending?.map((friend,idx)=>
                                <div key={idx} style={{left:0,right:0,margin:"auto", minWidth:"30rem",width:"80%",backgroundColor:"#ffffffaa",borderRadius:"1rem",lineHeight:"3rem",height:"3rem",display:"grid",gridTemplateColumns:"6rem 12rem auto 1.5rem",paddingLeft:"3rem",paddingRight:"3rem", justifyItems:"left"}}>
                                    <div><i className="fa fa-trophy"></i>:{friend?.trophies??0}</div>
                                    <div>{friend?.name?.length>13?friend.slice(0,13)+"...":friend?.name}</div>
                                    <div style={{display:"flex",gap:"3rem"}}>
                                        <div style={{fontSize:"1.25rem",color:"#eebb77",display:"flex"}}>
                                            {"Pending..."}
                                        </div>
                                    
                                    </div>
                                    <div>
                                        <button  className="RedButtonSmall" style={{width:"1.5rem",lineHeight:"1.5rem",height:"1.5rem",top:"-.25rem",fontSize:"1rem"}} onClick={e=>removeFriend(e,friend.name)}>X</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        </div>
                    </div>
                    )
            },
            onTabSelected : () => {
                getFriends(setFriends)
            }
        },
    ]
    return (
        <div className="App" style={{height:'80vh'}}>
            {
                loaded? 
                <>
                {
                    isLoggedIn? 
                    <div>
                        <UserButton inUser={false} handleUser={()=>{window.location.href="/user"}}/>
                        <Waves viewing={false}/>
                        <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}}>
                            <div style={{height:'25vh', fontSize: '5vh'}}>
                                <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
                            </div>
                            <div style={{position:"relative", width:"80vw", height:'60vh',left:0,right:0,margin:"auto"}}>
                                <Tabs tabs={tabs} userData={userData}/>
                            </div>
                        </div>
                        
                    </div>
                    :
                    <div>
                        <Waves viewing={false}/>
                        <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}} className="Home">
                            <div style={{height:'25vh', fontSize: '5vh'}}>
                                <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
                            </div>
                            <div style={{position:"relative", width:"80vw", height:'60vh', top:'0vh',left:"10vw", borderRadius:'5vh', backgroundColor:"#ffffffaa"}}>
                                <button className="SignInButton" onClick={()=>{window.location.href = '/signin'}}>Sign In</button>
                                <button className="CreateAccountButton" onClick={()=>{window.location.href = '/createaccount'}}>Create Account</button>
                                <button className="BlueButtonSmall" style={{position:"absolute", left:0, right:0,margin: "auto",top:"45vh"}} onClick={()=>{localStorage.removeItem("tutorial");window.location.href = '/tutorial'}}>Tutorial</button>
                            </div>
                        </div>
                    </div>
                }
                </>
                :
                <>
                    <div>
                        <Waves viewing={false}/>
                        <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}} className="Home">
                            <div style={{height:'25vh', fontSize: '5vh'}}>
                                <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
                            </div>
                            <div style={{position:"relative", width:"80vw", height:'60vh', top:'0vh',left:"10vw", borderRadius:'5vh', backgroundColor:"#ffffffaa"}}>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}