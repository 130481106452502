import { useEffect, useState } from 'react';
import './App.css';
import SignInPage from './Pages/SignInPage';
import * as Cards from './Data/Card/Cards';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import CreateAccountPage from './Pages/CreateAccountPage';
import FindGame from './Pages/FindGame';
import Game from './Pages/Game';
import UserPage from './Pages/UserPage';
import AnimCursor from './Components/Main/AnimCursor';
import UrlDetect from './Components/Main/UrlDetect';
import Tutorial from './Pages/Tutorial';
import Play from './Pages/Play';
import Campaign from './Pages/Campaign';
import { addUserData, getUserData } from './TableFunctions/UserDataTableFunctions';
import Challenge from './Pages/Challenge';
import Reward from './Pages/Reward';



export const starterDeck = [
  Cards.ladyBug(),
  Cards.floatie()
];
export const starterPlank = [
  [],[],[],[],[],[],[],[]
]
export const shopCards = [
  {id:0, card: () => Cards.beehive(), cost: 1, type:"building"},
  {id:1, card: () => Cards.crabTrap(), cost: 10, type:"building"},
  {id:2, card: () => Cards.fishingHut(), cost: 20, type:"building"},
  {id:3, card: () => Cards.balloons(), cost: 15, type:"building"},
  {id:4, card: () => Cards.floatie(), cost: 15, type:"building"},
  {id:5, card: () => Cards.repairKit(), cost: 5, type:"spell"},
  {id:6, card: () => Cards.stick(), cost: 10, type:"spell"},
  {id:7, card: () => Cards.lifePreserver(), cost: 20, type:"spell"},
  {id:8, card: () => Cards.fly(), cost: 20, type:"troop"},
  {id:9, card: () => Cards.worm(), cost: 25, type:"troop"},
  {id:10, card: () => Cards.ladyBug(), cost: 35, type:"troop"},
  {id:11, card: () => Cards.snail(), cost: 2, type:"troop"},
  {id:12, card: () => Cards.net(), cost: 10, type:"building"},
  {id:13, card: () => Cards.bandages(), cost: 5, type:"spell"},
  {id:14, card: () => Cards.clam(), cost: 5, type:"troop"},
  {id:15, card: () => Cards.beetle(), cost: 5, type:"troop"},
]



function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(0);
  const [loaded, setLoaded] = useState(false)
  const [shortScreen, ] = useState(false)
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")??"{}"))
  useEffect(()=>{
    addUserData({},()=>{
      getUserData(userData=>{
        setUserData(userData)
        localStorage.setItem("userData",JSON.stringify(userData))
      })
    },false)
  },[])
  return (
    <div onContextMenu={e=>e.preventDefault()}>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <AnimCursor/>
      <BrowserRouter>
      <UrlDetect setIsLoggedIn={setIsLoggedIn} setLoaded={setLoaded}/>
        <Routes>
          <Route path="/" element={<Home shortScreen={shortScreen} loaded={loaded} isLoggedIn={isLoggedIn} userData={userData}/>} />
          <Route path="/signin" element={<SignInPage shortScreen={shortScreen} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} />
          <Route path="/createaccount" element={<CreateAccountPage/>} />
          <Route path="/tutorial" element={<Tutorial/>} />
          <Route path="/play/:level" element={<>{isLoggedIn? <Play/>:<></>}</>} />
          <Route path="/play" element={<>{isLoggedIn? <Campaign/>:<></>}</>} />
          <Route path="/game" element={<>{isLoggedIn? <Game/>:<></>}</>} />
          <Route path="/reward" element={<>{isLoggedIn? <Reward/>:<></>}</>} />
          <Route path="/challenge" element={<>{isLoggedIn? <Challenge/>:<></>}</>} />
          <Route path="/findGame" element={<FindGame shortScreen={shortScreen}/>} />
          <Route path="/user" element={<UserPage shortScreen={shortScreen}/>} />
          <Route path="*" element={<Home shortScreen={shortScreen} loaded={loaded} isLoggedIn={isLoggedIn}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
