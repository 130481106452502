import { Draggable, Droppable } from "@hello-pangea/dnd";
import { getItemStyle, range } from "./ArenaHelperFunctions";

import Card from "./Card";

export default function PlayableArea ({destroyedPositions}) {
    return (
            <div className="Game" style={{position:"absolute", width:"140vh",left:0, right:0, bottom:'37.5vh',height:'20vh', margin:"0 auto", display:"grid", gridTemplateColumns:`repeat(8,${window.innerWidth*.7<window.innerHeight*1.2?"8.75vw":"12vh"})`,justifyContent: "space-evenly",
                justifyItems: "center",
                alignContent: "space-evenly",
                alignItems: "center"}}>
                {range(8).map(index=>
                    <div key={index} style={{height: '20vh', width:'17.5vh', position: "relative"}}>
                        <Droppable droppableId={"game"+index} direction="horizontal">
                            {(provided, snapshot) => {
                                return (
                                    <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{border:(snapshot.isDraggingOver)?"2vh solid #ffffff33":"", zIndex:snapshot.isDraggingOver?5:"unset",borderRadius:"5%", position: "relative", width:"14vh", height:"100%"}}
                                    >
                                    <div style={{width:"1px", height:"100%"}}>{provided.placeholder}</div>
                                    </div>
                                )
                            }}
                        </Droppable>
                </div>
            )}
            </div>
            )
}

export const showCards = (cards, isArena, setCurrentCard) => {
    return isArena?
    (<>
{/* invisible cards immovable */}
        <div style={{opacity:0, visibility:'hidden', width:0, transform: "none !important"}}>{cards?.map((card, index)=>
            <Draggable
            style={{transform: "none !important"}}
            key={card.position}
            draggableId={card.title+card.position}
            index={index}
            >
                {(provided,snapshot) => (
                    snapshot.isDragging?
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, {...provided.draggableProps.style, transform: "none !important"})} >
                        {provided.placeholder}
                    </div>
                    :
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, {...provided.draggableProps.style, transform: "none !important"})} >
                        <Card {...card} setCurrentCard={setCurrentCard} />
                    </div>
                )}
            </Draggable>
        )}</div>
    </>)
    :
    (<>
{/* visible cards */}
        {cards?.map((card, index)=>
            <Draggable
            key={card.position}
            draggableId={card.title+card.position}
            index={index}
            >
                {(provided,snapshot) => (
                    <div className="HoverIndex" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...getItemStyle(snapshot.isDragging, provided.draggableProps.style), zIndex:512, cursor:"none"}} >
                        <Card {...card} plural={card.plural} setCurrentCard={setCurrentCard} />
                    </div>
                )}
            </Draggable>
        )}</>
    )
}