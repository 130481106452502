import { Draggable, Droppable } from "@hello-pangea/dnd"
import { getItemStyle, range } from "./ArenaHelperFunctions"
import { getListStyle } from "./Hand"
import BackOfCard from "./BackOfCard"
import Card from "./Card"

export default function Deck({dragging, state, setState, viewing, setCurrentCard}) {
    const card = state.deck[0]
    const cardDisp = (dragging) => {
        return dragging?{transform:"0 !important"}:{}
    }
    const handleClick = () => {
        setState(prevState=>{
            const deck = [...prevState.deck]
            const topCard = deck.shift()
            return prevState.hand.length>=5?prevState:{
                ...prevState, 
                deck:deck,
                hand:[...prevState.hand, topCard]
            }
        })
    }
    
    return (
        <div style={{display: viewing?"none":""}}>
            <div className="Deck" style={{zIndex:500, transition: "opacity .5s", position:"absolute",right:"-3.5vh"}}>
                {
                    card?
                    <Droppable style={{overflow: "hidden !important"}} droppableId="TopCard" direction="horizontal">
                {(provided, snapshot) => {
                    return(
                    <div
                    ref={provided.innerRef}
                    style={{...getListStyle([state.deck[0]]), overflow: "visible"}}
                    {...provided.droppableProps}
                    >
                    <Draggable
                        key={card.position}
                        draggableId={card.title+card.position}
                        index={0}
                    >
                        {(provided,snapshot) => (
                            <div className="HoverIndex" onClick={handleClick} onContextMenu={handleClick} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{...getItemStyle(snapshot.isDragging, provided.draggableProps.style),...cardDisp(dragging), zIndex:512, cursor:"url(/add-to-deck.png),default", bottom:"3vh"}} >
                                <Card {...card} setCurrentCard={setCurrentCard}/>
                            </div>
                        )}
                    </Draggable>
                    </div>
                )}}
                </Droppable>
                :
                <></>
                }
                
            </div>
            <div style={{zIndex:120, position:"absolute"}}>
                {
                    range(Math.min(state.deck.length,8)).map(index=>{
                        if (index===0) return <div key={index}></div>
                        return (
                        <div key={index} style={{position:"absolute", width:0,height:0, zIndex:120-(index*5)}}>
                            <div style={{paddingTop:`${index*.7}vh`, paddingLeft:`${index*.25}vh`}}>
                                <BackOfCard />
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}