export const generateRandomID = () =>{
    const length = 12
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    range(length).forEach(()=>{
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    })
    return result;
}
//https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
export function random(seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x)
}
export const range = n => Array.from(Array(n).keys())
export const remove = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [result, removed];
};
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};
export const getItemStyle = (isDragging, draggableStyle) => ({
    
    ...draggableStyle,
    userSelect: "none",
    width:"18vh",
});
export const shuffleDeck = (deck) => {
    let currentIndex = deck.length;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random(new Date().getTime()) * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [deck[currentIndex], deck[randomIndex]] = [
        deck[randomIndex], deck[currentIndex]];
  }
    return deck
}
export const drawCards = (deck) => {
    const hand = deck.slice(0,5)
    const newDeck = deck.slice(5)
    return {hand:hand, deck:newDeck}
}