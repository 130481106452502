import { useEffect, useState } from "react";
import ShopCards from "./ShopCards";

import { shopCards } from "../../App";

export default function Shop ({turn, setCurrentCard, destroyedPositions, shells, saveCards, addCard,inShop=false,handleShop=()=>{}}) {
    const [page, setPage] = useState(0)
    const [numPurchasable, setNumPurchasable] = useState(0)
    const [sortBy, setSortBy]  = useState(0)
    const [changingPage,setChangingPage] = useState('')
    const cardsPerPage = 8
    useEffect(()=>{
        let numPurchasable = 0
        shopCards.forEach(shopCard=>{
            const cost = shopCard.cost
            const purchasable = shells >= cost
            if (purchasable) numPurchasable++
        })
        setNumPurchasable(numPurchasable)
    },[shells,turn, setNumPurchasable, destroyedPositions?.length])
    function escFunction(event){
        if (event.key === "Escape" && inShop) {
            handleShop()
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', escFunction);
    
        return () => {
          window.removeEventListener('keydown', escFunction);
        };
      });
    return (
        <div>
            <div style={{position: "absolute", left:"16vh", top:'6vh', width:'6vh', height:'6vh'}}>
                <div style={{zIndex:inShop?600:"",position: "relative",left:"0", fontSize:'2vh',fontWeight:"800", width:"20vw",lineHeight: '6vh', height:'6vh', textAlign:"left"}}>Shop</div>
                <div style={{zIndex:inShop?600:"",position: "relative", top:"-12vh",left:"-11vh", fontSize:'2vh',fontWeight:"800", width:"20vw",lineHeight: '6vh', height:'6vh', textAlign:"left"}}>Shells: {shells}</div>
            </div>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css' crossOrigin='anonymous'></script>
            <div 
                className="shop" 
                style={{animationName: inShop?"col":"",
                        background: inShop?"#54de54":"#888888",
                        zIndex: 521, 
                        width:'6vh', 
                        height:'6vh',
                        top:'6vh',
                        left:'6vh',
                        
                    }} 
                onClick={()=>handleShop()}>
            {
            inShop?
            <div className="bell-exit" style={{border: `1px solid #54de54 !important`, boxShadow: `2px 2px 5px #54de54, -2px -2px 5px #54de54`, borderRadius:"50%",width:'6vh',  height:'6vh'}}>
                
            </div>
                :
            <></>
            }
            <i className='fas fa-shopping-cart' style={{fontSize: '2vh', paddingTop:'2vh',position:"absolute",left:'1.75vh', color:"white"}}></i>
            <i className="fas fa-shop btn-shop" style={{animationName: inShop?"shop-ring":""}}></i>
            {
                numPurchasable>0?
                <div className="NumPurchasable"><div>{numPurchasable}</div></div>
                :
                <></>
            }
            </div>
            <div style={{position:"absolute", top:0}}>
                    <div className="CardShopBackgroundOuter" style={{animation: inShop?"shopPop .5s linear calc(1)":"", display: !inShop?"none":""}}></div>
                    <div className="CardShopBackgroundInner" style={{animation: inShop?"shopPop .5s linear calc(1)":"", display: !inShop?"none":""}}></div>
                    <div className="CardShopBackgroundCards" style={{zIndex:inShop?601:"",animation: inShop?"shopPop .5s linear calc(1)":"", display: !inShop?"none":""}}>
                        <div style={{height:0, position:"absolute", top:"2.5vh", left:'5vw', display:"flex", gap:"2vw"}}>
                            <div className="SignInHeader" style={{fontSize:"5vh"}}>Shop</div>
                            <div style={{position:"relative",top:"1vh", left:"2vw", fontWeight:"600", fontSize:"2vh"}}>Shells: {shells}</div>
                            
                        </div>
                        <div style={{zIndex:inShop?602:"",height:0, position:"absolute", top:"2.5vh", right:'5vw', display:"flex", gap:"2vw"}}>
                            <div style={{position:"relative",top:"1vh", paddingLeft:"5vw", fontWeight:"600", fontSize:"2vh"}}>Sort By:</div>
                            <div className="ZoomShopHeader">
                                <button className={sortBy===0?"BlueButtonSmall":"ButtonSmallDisabled"} style={{top:"-12vh"}} onClick={()=>setSortBy(0)}>Cost</button>
                            </div>
                            <div className="ZoomShopHeader">
                                <button className={sortBy===1?"BlueButtonSmall":"ButtonSmallDisabled"} style={{top:"-12vh"}} onClick={()=>setSortBy(1)}>Type</button>
                            </div>
                            
                        </div>
                        <div className="ZoomShopCards" style={{zIndex:inShop?601:"",width:'80%', left:'16.25vw',transition:"opacity .2s", opacity:changingPage?0:1}}>
                            <ShopCards sortBy={sortBy} setNumPurchasable={setNumPurchasable} turn={turn} destroyedPositions={destroyedPositions} cardsPerPage={cardsPerPage} setCurrentCard={setCurrentCard} page={page} saveCards={saveCards} shells={shells} addCard={addCard}/>
                        </div>
                        <button className="SignInButtonSmall" style={{position:"absolute", top:'67vh', left: '40vh', fontSize:'3vh', fontWeight:"800"}} onClick={()=>{if(changingPage)return;setChangingPage(true);setTimeout(()=>{setPage(page===0?(Math.ceil(shopCards.length/cardsPerPage)-1):page-1);setChangingPage('')},200)}}>{"<"}</button>
                        <button className="SignInButtonSmall" style={{position:"absolute", top:'67vh', right: '40vh', fontSize:'3vh', fontWeight:"800"}} onClick={()=>{if(changingPage)return;setChangingPage(true);setTimeout(()=>{setPage((page+1)%(Math.ceil(shopCards.length/cardsPerPage)));setChangingPage('')},200)}}>{">"}</button>
                        <div style={{position:"absolute", top:'67vh', left:0, right:0, margin:"auto", width:"5vw"}} ><button className="RedButtonSmall" style={{top:0}} onClick={()=>handleShop()}>Close</button></div>
                        <div style={{position:"absolute", top:'63vh', left:0, right:0, margin:"auto", fontSize:'1.5vh', fontWeight:"800"}} >{"page: "+(page+1)+"/"+Math.ceil(shopCards.length/cardsPerPage)}</div>
                    </div>
            </div>
        </div>
)}