import { useSearchParams } from "react-router-dom";
import Waves from "../Components/Main/Waves";
import { getGameStatus } from "../TableFunctions/GameTableFunctions";
import { useEffect, useState } from "react";
import { gameCoinsToday, getEvent, putEvent } from "../TableFunctions/EventTableFunctions";

export default function Reward(){
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")
    const [rewardData, setRewardData] = useState(null)
    useEffect(()=>{
        getEvent({type:"GameCoins", id:id},event=>{
            if (event?.Type==="GameCoins") {
                window.location.href = '/';
                return
            }
            getGameStatus(id,(data)=>{
                gameCoinsToday(rewardsToday=>setRewardData({...data, rewardsToday:rewardsToday>=10?0:20}))
            })
        })
        getGameStatus(id,(data)=>{
            gameCoinsToday(rewardsToday=>setRewardData({...data, rewardsToday:rewardsToday>=10?0:20}))
        })
    },[id])
    const handleGetCoins = () => {
        if (!id || !rewardData?.status) return
        putEvent({type:"GameCoins", id:id, value:rewardData.rewardsToday},()=>{
            window.location.href='/'
        })
    }
    return (<div className="App" style={{height:'100vh'}}>
        <Waves viewing={false}/>
        <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}} className="Home">
            <div style={{height:'25vh', fontSize: '5vh'}}>
                <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
            </div>
            <div style={{position:"relative", width:"50vw", height:'50vh', top:'0vh', borderRadius:'5vh', backgroundColor:"#ffffffaa", left:0,right:0,margin:"auto"}}>
                
                {
                    rewardData?
                    <div style={{fontSize:"2rem", lineHeight:"2rem", display:"flex", flexDirection:"column", gap:"5vh"}}>
                        <div style={{fontSize:"3rem", lineHeight:"10vh", fontWeight:800}}>{rewardData.status.toUpperCase()}</div>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <i className="fa fa-trophy"></i>
                            <div>{rewardData.currentTrophies}</div>
                            <div>+</div>
                            <div>{rewardData.trophiesFromGame}</div>
                        </div>
                        <div><i className="fa fa-diamond"></i>{rewardData.rewardsToday}</div>
                        <button style={{width:"10vw", position:"relative", top:"unset", left:0,right:0,margin:"auto"}} className="BlueButtonSmall" onClick={handleGetCoins}>Continue</button>
                    </div>
                    :
                    <></>
                }
                
            </div>
            
        </div>
    </div>)
}