import BigCard from "./BigCard"


export default function CardInfo ({onCloseCardInfo, currentCard, setCurrentCard}) {
    return (
        <div>
            {
                currentCard[0]?.title?
                <div style={{position:"absolute", top:0}}>
                    <div className="CardShopBackgroundOuter" style={{zIndex:600}}></div>
                    <div className="CardShopBackgroundInner" style={{zIndex:601}}></div>
                    <div className="CardShopBackgroundCards" style={{zIndex:602}}>
                        <div style={{position:"absolute", top:'4vh',left:0, right:0}}>
                                <BigCard setCurrentCard={setCurrentCard} card={currentCard[currentCard.length-1]}/>
                        </div>
                        <button className='SignInButtonSmall' style={{top:'62.5vh',cursor:"none"}} onClick={()=>{setCurrentCard(prev=>{const s = [...prev]; s.pop(); return s}); if (typeof onCloseCardInfo === 'function') {onCloseCardInfo(currentCard)}}}>Done</button>
                    </div>
            </div>
            :
            <></>
            }
        </div>
    )
}