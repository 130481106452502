import { useEffect, useState } from "react";
import Arena from "../Components/Game/Arena.js";
import { GameFunctions } from "../Functions/GameFunctions/GameFunctions.js"
import Waves from "../Components/Main/Waves.js";
import { levelStates, opponentLevelStates, playOpponentCards } from "../Data/Play/LevelData.js";
import { useParams } from "react-router-dom";
import { getCampaignLevel, getTopLevel, putCampaignLevel, removeLevel } from "../TableFunctions/CampaignTableFunctions.js";

export default function Play() {
    let { level } = useParams();
    const [, setHasInspectedCard] = useState([])
    const [state, setState] = useState({})
    const [opponentState, setOpponentState] = useState({})
    const [simulating, setSimulating] = useState(false)
    const [currentCard, setCurrentCard] = useState([])
    const [opponentUsername, ] = useState("Tutorial")
    const [canLeave, setCanLeave] = useState(false)
    useEffect(()=>{
        if (level) {
            getTopLevel((topLev)=>{
                if (topLev < level) window.location.href = '/play'
            }) 
        }
    },[level])
    const updatePlayer = (state) => {
        //save turn in local storage?
        setState(state)
    }
    const removeGameFunc = (removedState) => {
        setState({...removedState, paused:true})
            setTimeout(()=>{
                removeLevel(level,()=>{setCanLeave(true)})
            },1000)
    }
    const opponentSimulate = (oppState, state)=>{
        playOpponentCards[level](state, oppState, setOpponentState)
    }
    const [gameFunctions,] = useState(new GameFunctions({setState, setOpponentState, setSimulating, updatePlayer, removeGame:removeGameFunc,opponentSimulate:opponentSimulate}))
    const recoverDeck = (callback) => {
        //get local storage turn
        if (level) {
            getCampaignLevel(level,(playerState, opponentState)=>{
                if (playerState==="error") {
                    setTimeout(()=>{
                        recoverDeck()
                    },5000)
                    return
                }
                if (playerState.level) {
                    const newState = playerState
                    setState({...newState,
                        inShop: false,
                        exiting:false,
                    })
                    setOpponentState({...opponentState})
                } else {
                    setState({...levelStates[level]})
                    setOpponentState({...opponentLevelStates[level]})
                }
            })
        }
        
        
    }
    const handleExit = (callback) => {
            removeLevel(level,()=>{window.location.href='/play'})
    }

    useEffect(()=>{
        recoverDeck()
        // eslint-disable-next-line
    },[setState])


    

    useEffect(()=>{
        if (state.awaitingNextTurn && opponentState.awaitingNextTurn && !state?.simuating && !simulating) {
            gameFunctions.simulateCards(state,opponentState)
        }
        else if (state.awaitingNextTurn) {
            setOpponentState(prevState=>{return{...prevState,awaitingNextTurn:true}})
        }
        // eslint-disable-next-line
    },[state.awaitingNextTurn, state.turn, opponentState.awaitingNextTurn])
    
    useEffect(()=>{
        if (level && state.level) {
            putCampaignLevel(level,state,opponentState)
        }
    },[state, opponentState, level])

    return (
        <div className='App' style={{height:'100vh'}}>
            {
            //state?.hand?
            state.level?
            <Arena 
                handleHome={handleExit} 
                onCloseCardInfo={(card)=>{setHasInspectedCard(prevState=>{return [...prevState,card]})}}
                handleExit={handleExit} 
                gameFunctions={gameFunctions} 
                simulateCards={(state, opponentState)=>gameFunctions.simulateCards(state, opponentState)} 
                canLeave={canLeave} 
                opponentUsername={opponentUsername} 
                currentCard={currentCard} 
                setCurrentCard={setCurrentCard} 
                simulating={simulating} 
                setSimulating={(s)=>{setSimulating(s)}} 
                updatePlayer={updatePlayer} 
                opponentState={opponentState} 
                setOpponentState={setOpponentState} 
                state={state}
                setState={setState}
            />
            :
            <>
                <Waves/>
            </>
            }
        </div>
    )
}