import AnimatedCursor from "react-animated-cursor"

export default function AnimCursor () {
    function iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }
    return (
        <>
            {
                !iOS()?
                <div>
                <AnimatedCursor
                    innerSize={24}
                    outerSize={48}
                    color='31, 81, 113'
                    outerAlpha={0.2}
                    innerScale={1.5}
                    outerScale={1.5}
                    trailingSpeed={1}
                    innerStyle={{zIndex:999,borderRadius:"0", transform: "translate(-50%, -50%) rotate(0deg) scaleX(0.2)"}}
                    outerStyle={{zIndex:998, transform: "translate(-50%, -50%)"}}
                    clickables={[
                        'a',
                        'input[type="text"]',
                        'input[type="email"]',
                        'input[type="number"]',
                        'input[type="submit"]',
                        'input',
                        'label[for]',
                        'select',
                        'textarea',
                        //'button',
                        //'.InfoButton',
                        '.SignInButton',
                        '.RedButton',
                        '.RedButtonSmall',
                        '.BlueButton',
                        '.BlueButtonSmall',
                        '.SignInButtonSmall',
                        '.ButtonSmallDisabled',
                        '.CreateAccountButton',
                        '.UserButton',
                        '.TabButton',
                        //'.Card',
                        '.CardBig',
                        '.CreateAccountButtonSmall',
                        '.user',
                        '.shop',
                        '.exit',
                        '.bell',
                        '.Username',
                        '.Password'
                ]}
                />
                
                <AnimatedCursor
                    innerSize={24}
                    outerSize={0}
                    color='31, 81, 113'
                    outerAlpha={0}
                    innerScale={1.5}
                    trailingSpeed={1}
                    innerStyle={{zIndex:999,borderRadius:"0", transform: "translate(-50%, -50%) rotate(90deg) scaleX(0.2)"}}
                    clickables={[
                        'a',
                        'input[type="text"]',
                        'input[type="email"]',
                        'input[type="number"]',
                        'input[type="submit"]',
                        'input',
                        'label[for]',
                        'select',
                        'textarea',
                        //'button',
                        '.SignInButton',
                        '.RedButton',
                        '.RedButtonSmall',
                        '.BlueButton',
                        '.BlueButtonSmall',
                        '.SignInButtonSmall',
                        '.ButtonSmallDisabled',
                        '.CreateAccountButton',
                        '.UserButton',
                        '.TabButton',
                        //'.Card',
                        '.CardBig',
                        '.CreateAccountButtonSmall',
                        '.user',
                        '.shop',
                        '.exit',
                        '.bell'
                ]}
                />
                
                <AnimatedCursor
                    innerSize={20}
                    outerSize={16}
                    color='207, 238, 255'
                    outerAlpha={0}
                    innerScale={1.5}
                    trailingSpeed={1}
                    innerStyle={{zIndex:999,borderRadius:"0", transform: "translate(-50%, -50%) rotate(-90deg) scaleX(0.1)"}}
                    clickables={[
                        'a',
                        'input[type="text"]',
                        'input[type="email"]',
                        'input[type="number"]',
                        'input[type="submit"]',
                        'input',
                        'label[for]',
                        'select',
                        'textarea',
                        //'button',
                        '.SignInButton',
                        '.RedButton',
                        '.RedButtonSmall',
                        '.BlueButton',
                        '.BlueButtonSmall',
                        '.SignInButtonSmall',
                        '.ButtonSmallDisabled',
                        '.CreateAccountButton',
                        '.UserButton',
                        '.TabButton',
                        //'.Card',
                        '.CardBig',
                        '.CreateAccountButtonSmall',
                        '.user',
                        '.shop',
                        '.exit',
                        '.bell',
                        '.Username',
                        '.Password'
                ]}
                />
                <AnimatedCursor
                    innerSize={20}
                    outerSize={16}
                    color='207, 238, 255'
                    outerAlpha={0}
                    innerScale={1.5}
                    trailingSpeed={1}
                    innerStyle={{zIndex:999,borderRadius:"0", transform: "translate(-50%, -50%) rotate(0deg) scaleX(0.1)"}}
                    clickables={[
                        'a',
                        'input[type="text"]',
                        'input[type="email"]',
                        'input[type="number"]',
                        'input[type="submit"]',
                        'input',
                        'label[for]',
                        'select',
                        'textarea',
                        //'button',
                        '.SignInButton',
                        '.RedButton',
                        '.RedButtonSmall',
                        '.BlueButton',
                        '.BlueButtonSmall',
                        '.SignInButtonSmall',
                        '.ButtonSmallDisabled',
                        '.CreateAccountButton',
                        '.UserButton',
                        '.TabButton',
                        //'.Card',
                        '.CardBig',
                        '.CreateAccountButtonSmall',
                        '.user',
                        '.shop',
                        '.exit',
                        '.bell',
                        '.Username',
                        '.Password'
                ]}
                />
        </div>
        :
        <></>
            }
        </>
    )
}