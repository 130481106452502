import { useEffect, useState } from "react"
import Waves from "../Components/Main/Waves"
import { getTopLevel } from "../TableFunctions/CampaignTableFunctions"
import { range } from "../Components/Game/ArenaHelperFunctions"

export default function Campaign () {
    const [topLevel,setTopLevel] = useState(0)
    useEffect(()=>{
       getTopLevel((level)=>{setTopLevel(level)}) 
    },[])
    return (
        <div className="App">
            <Waves/>
            <div style={{position:"absolute", top:"0vh", left:0, right:0, margin:"auto", height:"100vh"}}>
                <div className="SignInHeader" style={{textAlign:"center",height:"15vh"}} >Level Selection</div>
                <div className="LevelSelection" style={{width:"100vw",height:"80vh", paddingTop:"5vh", overflowY:"scroll"}}>
                    <div style={{display:"flex", flexDirection:"column",gap:"5vh", left:0,right:0,margin:"auto",width:"5vw",msOverflowStyle:"none",scrollbarWidth:"none"}}>
                        <button className="CreateAccountButtonSmall" style={{cursor:"none",position:"relative",top:0}} onClick={()=>{window.location.href='/'}}>Home</button>
                        {
                            topLevel>0?
                            range(topLevel).reverse().map(number=><button key={number} className="BlueButtonSmall" style={{cursor:"none",position:"relative",top:0,height:"5vh"}} onClick={()=>{window.location.href=`/play/${number+1}`}}>Level {number+1}</button>)
                            :
                            ""
                        }
                    </div>
                    <div style={{height:"5vh"}}></div>
                </div>
                
            </div>
        </div>
    )
}