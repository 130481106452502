import { shopCards } from "../../App";
import { range } from "./ArenaHelperFunctions";
import Card from "./Card";


export default function ShopCards ({ sortBy, cardsPerPage, setCurrentCard, saveCards, destroyedPositions, addCard, shells, page}) {
    const getShopCards = (page,sortBy) => {
        return range(cardsPerPage).map(number=>{
            return shopCards.sort(compare(sortBy))[number+(page*cardsPerPage)]
        })
    }
    
    function compareCost( a, b ) {
        if ( a.cost < b.cost ){
          return -1;
        }
        if ( a.cost > b.cost ){
          return 1;
        }
        const cardA = {...a.card()}
        const cardB = {...b.card()}
        return cardA.title.localeCompare(cardB.title);
    }
    function compareType( a, b ) {
        if (!a?.card || !b?.card) {
            return 0
        }
        const cardA = {...a.card()}
        const cardB = {...b.card()}
        const result = cardA.cardType.localeCompare(cardB.cardType)
        return result===0?compareCost(a,b):result
    }
    function compare (sortBy) {
        return sortBy===0?compareCost:compareType
    }
    return (
        <div style={{display: "grid", gridTemplateColumns: "repeat(4,25%)",paddingTop: '1vh',paddingLeft:"1vh", rowGap:'0vh', position:"relative", left:0,right:0, margin:"auto"}}>
            {getShopCards(page,sortBy).map((shopCard, idx)=> {
                if (!shopCard?.card) {
                    return <div key={idx}></div>
                }
                
                const card = {...shopCard.card()}
                const cost = shopCard.cost
                const purchasable = shells >= cost
                return (
                    <div key={idx} style={{display: "grid", justifySelf: "center"}}>
                        <div style={{height: '2.5vh', fontSize:'2.5vh', fontWeight:"800", margin:"0 auto", paddingTop:'2vh'}}>{card.title}</div>
                        <div style={{paddingTop: '1vh', paddingBottom:"1vh", fontSize: '2vh', filter: "grayscale(0) !important", color:purchasable?"#24ae24":"#de5454"}}><div style={{fontWeight: 800}}>{cost} shell{cost>1?'s':''}</div> </div>
                        <div className={purchasable?"hoverScale":""} style={{filter: `grayscale(${purchasable?0:.8})`}}>
                            <Card {...card}
                                smaller={true}
                                setCurrentCard={setCurrentCard}
                                onRemoved={()=>{
                                    if (purchasable) {
                                        if (shopCard.card().title==="Life Preserver") {
                                            saveCards(shells-cost)
                                        } else {
                                            addCard(shopCard.card(),shells-cost)
                                        }
                                    }
                                }}/>
                        </div>
                    </div>
                )
            }
                
            )}
        </div>
    )
}