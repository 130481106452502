import * as AWS from "aws-sdk"
import * as CryptoJS from 'crypto-js'

//const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : '12345'
export const cipherUser = salt => {
   return (string) => {
       return CryptoJS.SHA256(string).toString(CryptoJS.enc.Base64)
   }
}

//export const decipher = salt => {
//    return (string)=>{
//        const bytes = CryptoJS.AES.decrypt(string, salt)
//        const plainText = bytes.toString(CryptoJS.enc.Utf8)
//        return plainText
//    }
//    
//}


// update the config
const cipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const byteHex = n => ("0" + Number(n).toString(16)).slice(-2);
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

  return text => text.split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('');
}

const decipher = salt => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0));
  const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
  return encoded => {
    if (!encoded) return
    return encoded.match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('');
  }
}

const myCipher = cipherUser(process.env.REACT_APP_SALT)
export const myDecipher = decipher(process.env.REACT_APP_SALT)

export const myCipherGameID = cipher(process.env.REACT_APP_SALT_JWT)
export const myDecipherGameID = decipher(process.env.REACT_APP_SALT_JWT)

export const myCipherJWT = cipher(process.env.REACT_APP_SALT_JWT)
export const myDecipherJWT = decipher(process.env.REACT_APP_SALT_JWT)

export const myCipherEventID = cipher(process.env.REACT_APP_SALT_EVENT)
export const myDecipherEventID = decipher(process.env.REACT_APP_SALT_EVENT)

export const client = new AWS.DynamoDB.DocumentClient({
  region: process.env.REACT_APP_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  },
});


const addJWT = (JWT, callback) => {
  const params = {
    TableName : 'DecksterousUserSession',
    Item: {
      JWT: JWT,
    }
  };
  
  client.put(params, function(err, data) {
  if (err) console.log(err);
  else callback(data);
  });
}

export const checkJWT = (callback) => {//and that used exists
  const jsonWT = localStorage.getItem("JWT")
  if (!jsonWT) {
    callback(-1)
    return
  }
  const params = {
    TableName : 'DecksterousUserSession',
    Key: {JWT: jsonWT}
  };
  
  client.get(params, async function(err, data) {
  if (err) {
    callback(-1)
    return console.log(err)
  }
  else {
    const JWT = data?.Item?.JWT
    if (JWT) {//if JWT exists in database
      const {username} = JSON.parse(myDecipherJWT(JWT))
      checkUserExists(username, callback)
    } else {
      callback(-1)
    }
  }
  });
};

export const checkUserExists = (username, callback) => {
  const params = {
    TableName : 'DecksterousUser',
    Key: {Username: username}
  };
  
  client.get(params, function(err, data) {
  if (err) {
    callback(-1)
    return err
  }
  else {
    const serverHash = data?.Item?.Hash
    if (serverHash) {
      callback(1)
    } else {
      callback(-1)
    }
    return
  }
  });
};

export const getUser = (callback) => {
  const JWT = localStorage.getItem("JWT")
  if (!JWT) {
    callback('')
    return
  }
  const {username} = JSON.parse(myDecipherJWT(JWT))
  checkUserExists(username, e=>{
    if (e===1) {
      callback(username)
    } else {
      callback('')
    }
  })
}

export const createAccount = (username,password, callback) => {
  checkUserExists(username,(e)=>{
    if (!(e===1)) {
      const params = {
        TableName : 'DecksterousUser',
        Item: {
          Username: username,
          Hash: myCipher(password)
        }
      };
      client.put(params, function(err, _) {
      if (err) console.log(err);
      else callback(true);
      });
    } else {
      callback(false)
    }
  })
};

export const signedIn = (username, password, setIsLoggedIn, callback) => {
  const params = {
    TableName : 'DecksterousUser',
    Key: {Username: username}
  };
  
  client.get(params, async function(err, data) {
  if (err) {
    return err
  }
  else {
    const myHash = myCipher(password)
    const serverHash = data?.Item?.Hash
    const signedIn = serverHash?((myHash===serverHash)?1:0):(-1)
    if (signedIn===1) {
      const jsonWT = myCipherJWT(JSON.stringify({username:username, password:myHash}))
      localStorage.setItem("JWT",jsonWT)
      addJWT(jsonWT,(data)=>{
        setIsLoggedIn(true);
        callback(signedIn)
      })
      return
    }
    callback(signedIn)
    return
  }
  });
};
