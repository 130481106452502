import { Droppable } from "@hello-pangea/dnd";
import { showCards } from "./PlayableArea";

export default function Hand ({hand, viewing, setCurrentCard}) {
    return (
        <div className="Hand" style={{opacity: viewing?0:1, transition: "opacity .5s", maxWidth:"40vw", bottom:hand.length>0?"3vh":"-5vh", height:hand.length>0?"":"40vh"}}>
            <Droppable style={{overflow: "hidden !important", cursor:"none !important", height:hand.length>0?"24vh":"40vh"}} droppableId="hand" direction="horizontal">
            {(provided, snapshot) => {return(
                <div
                ref={provided.innerRef}
                style={{...getListStyle(hand), display:hand.length>0?"flex":"inherit", height:hand.length>0?"":"40vh", overflow: "visible", cursor:"none !important"}}
                {...provided.droppableProps}
                >
                {showCards(hand, false, setCurrentCard)}
                {provided.placeholder}
                </div>
            )}}
            </Droppable>
        </div>
    )
}

export const getListStyle = hand => ({
    display: "flex",
    width:`${(hand.length*18)}vh`,
    zIndex:200,
    left:0,
    right:0,
    margin:"auto",
    maxWidth:"50vw"
});