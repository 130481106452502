import { useEffect, useState } from "react";
import Waves from "../Components/Main/Waves";
import { MATCHMAKING_TIMEOUT_SECONDS, findGame, setPaired, uninitMatchmaking } from "../TableFunctions/MatchmakingFunctions";
import { getUser } from "../TableFunctions/UserFunctions";
import { useSearchParams } from "react-router-dom";


export default function Challenge () {
    const [initTime, ] = useState(new Date().valueOf())
    const [status, setStatus] = useState("Finding A Match")
    const [searchParams] = useSearchParams();
    const friend = searchParams.get("user")
    
    useEffect(()=>{
        if (friend) {
            setTimeout(()=>{
                setStatus("Matchmaking Timeout")
                window.location.href = '/'
            },MATCHMAKING_TIMEOUT_SECONDS*1000)
            getUser(username=>{
                setPaired(username, friend, initTime, true, success=>{
                    findGame(setStatus,username,{Username:friend},0,{},0,initTime,'Challenge')
                })
            })
        }
    },[initTime,friend])
    return (
        <div className="App" style={{height:'80vh'}}>
            <Waves viewing={false}/>
            <div style={{position:"absolute", left:0, right:0, margin:"auto", fontSize:"5vh !important", top:'30vh'}} className='SignInHeader'>{status}</div>
            <div style={{position:"absolute", left:0, right:0, margin:"auto", fontSize:"5vh !important", top:'50vh'}} className='SignInHeader'>
                <div className="loadingSpin" style={{borderRadius:"50%",position:"absolute", left:0, right:0, margin:"auto",width:'8vh', height:'8vh', backgroundImage:`conic-gradient(#ffffff00 75%, #1f5171 25%)` }}></div>
                <div style={{borderRadius:"50%",position:"absolute", left:0, right:0, top:'1.5vh', margin:"auto",width:'5vh', height:'5vh', backgroundColor:"rgb(153, 170, 204)" }}></div>
                <button style={{position:"absolute", left:0, right:0, margin:"auto", top:'18vh'}} className="RedButtonSmall" onClick={()=>{uninitMatchmaking()}}>Cancel</button>
            </div>
        </div>
    )
}