import { generateRandomID,random } from '../../Components/Game/ArenaHelperFunctions';
export const allCards = () => {
  return {
    "Perfect Stick":()=>stick(),
    "Ladybug":()=>ladyBug(),
    "Pile of Floaties":()=>floatie(),
    "Worm":()=>worm(),
    "Fly":()=>fly(),
    "Big Fish":()=>bigFish(),
    "Fish":()=>fish(),
    "Frog":()=>frog(),
    "Crab":()=>crab(),
    "Fishing Hut":()=>fishingHut(),
    "Crab Trap":()=>crabTrap(),
    "Balloons":()=>balloons(),
    "Net":()=>net(),
    "Life Preserver":()=>lifePreserver(),
    "Bee":()=>bee(),
    "Slug":()=>slug(),
    "Snail":()=>snail(),
    "Beehive":()=>beehive(),
    "Beetle":()=>beetle(),
    "Repair Kit":()=>repairKit(),
    "Bandages":()=>bandages(),
    "Clam":()=>clam(),
    "Pearl":()=>pearl(),
  }

}
export const stick = () => {return {
    title:"Perfect Stick", 
    description:()=>"Skewer opponents card with the Perfect Stick.", 
    cardType:"spell", 
    position:generateRandomID(), 
    weight:1, attack:Number.MAX_SAFE_INTEGER, health:0,
    heavy:true,
    level:1,
    worth:2
  }}
  export const ladyBug = () => {return {
    title:"Ladybug", 
    description:(prod)=>`Ladybug increases adjacent building cards productivity by ${prod}.`, 
    production: 0, 
    productionLevels:1,
    cardType:"troop", 
    position:generateRandomID(), 
    weight:2, attack:0, health:Number.MAX_SAFE_INTEGER, 
    enhance:(arenaPositions, position,production,instanceid)=>enhance("production", arenaPositions, position,production,instanceid),
    whenPlaced:(arenaPositions, index, _,card)=>{
      const prod = getProduction([card])
      return {
        cardPlaced:!arenaPositions[index]?.[0]?.title,
        arenaPositions: enhance("production", 
                                arenaPositions.map((item,idx)=>idx===index?[card]:item), 
                                index, 
                                prod,
                                card.title+card.position),
        destroyedPositions:false
      }
    },
    whenRemoved:(arenaPositions, card)=>{
      return {arenaPositions:arenaPositions.map(item=>{
        if (item[0]?.ladybug?.[card.title+card.position]) {
          const newLadybug = item[0]?.ladybug
          delete newLadybug[card.title+card.position]
          return [{...item[0], ladybug:newLadybug}]
        }
        return item
      })}
    },
    updateEveryPlace:true,
    level:1,
    worth:1
  }}
  export const beetle = () => {return {
    title:"Beetle", 
    description:(prod)=>`Beetle increases adjacent troop cards attack by ${prod}.`, 
    production: 0,
    productionLevels:1, 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:2, attack:1, health:2, 
    enhance:(arenaPositions, position,production,instanceid)=>enhance("troopAttack", arenaPositions, position,production,instanceid),
    whenPlaced:(arenaPositions, index, _,card)=>{
      const prod = getProduction([card])
      return {
        cardPlaced:!arenaPositions[index]?.[0]?.title,
        arenaPositions: enhance("troopAttack", 
                                arenaPositions.map((item,idx)=>idx===index?[card]:item), 
                                index, 
                                prod,
                                card.title+card.position),
        destroyedPositions:false
      }
    },
    whenRemoved:(arenaPositions, card)=>{
      return {arenaPositions:arenaPositions.map(item=>{
        if (item[0]?.beetle?.[card.title+card.position]) {
          const newBeetle = item[0]?.beetle
          delete newBeetle[card.title+card.position]
          return [{...item[0], beetle:newBeetle}]
        }
        return item
      })}
    },
    heavy:true,
    updateEveryPlace:true,
    level:1,
    worth:1
  }}
  export const floatie = () => {return {
    title:"Pile of Floaties", 
    description:(prod)=>`The Pile of Floaties reduces all troop weight by ${prod} at the cost of 1 attack.`, 
    production: 0, 
    cardType:"building", 
    position:generateRandomID(), 
    weight:2, attack:0, health:4, 
    enhance:(arenaPositions, position,production,instanceid)=>enhance("troopWeight", arenaPositions, position, production,instanceid),
    whenPlaced:(arenaPositions, index, _,card)=>{
      const prod = getProduction([card])
      const cardPlaced = !arenaPositions[index]?.[0]?.title
      return {
        cardPlaced:cardPlaced,
        arenaPositions: enhance("troopWeight", 
                                arenaPositions.map((item,idx)=>idx===index?[card]:item), 
                                index, 
                                prod,
                                card.title+card.position),
        destroyedPositions:false
      }
    },
    whenRemoved:(arenaPositions, card)=>{
      return {arenaPositions:arenaPositions.map(item=>{
        if (item[0]?.floatie?.[card.title+card.position]) {
          const newFloatie = item[0]?.floatie
          delete newFloatie[card.title+card.position]
          return [{...item[0], floatie:newFloatie}]
        }
        return item
      })}
    },
    productionLevels:1,
    updateEveryPlace:true,
    level:1,
    worth:1
  }}
  export const net = () => {return {
    title:"Net", 
    description:(prod)=>`The Net produces ${prod} random item${prod>1?"s":""} or shell${prod>1?"s":""} per turn when on your plank.`, 
    production: 1, 
    cardType:"building", 
    position:generateRandomID(), 
    whenPlayed:(deck,level,arenaPositions) => {
      const randomNumber = Math.random()
      const possibleCatches=[clam,clam,clam,clam,fish,fish,fish,"shell","shell","shell","shell","shell","shell","shell","shell","shell","shell",slug,slug,slug,slug,slug,slug,snail,snail,snail,snail,snail,snail,fly,worm,beetle,beetle]
      const caughtItem = possibleCatches[Math.floor(randomNumber*possibleCatches.length)]
      return caughtItem ==="shell"?[[...deck], [...arenaPositions],level]:[[...deck,{...caughtItem(),level:level}], [...arenaPositions],false]
    },
    weight:3, attack:0, health:6,
    level:1,
    heavy:true,
    worth:5
  }}
  export const balloons = () => {return {
    title:"Balloons", 
    description:()=>"Balloons!", 
    production: 1, 
    cardType:"building", 
    position:generateRandomID(), 
    weight:0, attack:0, health:1,
    level:1,
    worth:1,
    weightLevels:-4
  }}
  export const beehive = () => {return {
    title:"Beehive", 
    description:(prod,turnsLeft)=>`Beehive Generates ${prod} linkTo(Bee)${prod!==1?"s":""} Per Turn For ${turnsLeft} Turn${turnsLeft!==1?"s":""}.`, 
    turnsLeft:12, 
    production: 2, 
    cardType:"building", 
    position:generateRandomID(), 
    weight:2, attack:0, health:4, 
    whenPlayed:(deck,level,arenaPositions, index, turnsLeft, lastTurn) => playBee(deck, arenaPositions, level, index, turnsLeft, lastTurn),
    level:1,
    heavy:true,
    worth:1
  }}
  export const crabTrap = () => {return {
    title:"Crab Trap", 
    description:(prod,turnsLeft)=>`Crab Trap Generates ${prod} linkTo(Crab)${prod!==1?"s":""} Per Turn For ${turnsLeft} Turn${turnsLeft!==1?"s":""}.`, 
    turnsLeft:12, 
    production: 1, 
    cardType:"building", 
    position:generateRandomID(), 
    weight:3, attack:0, health:12, 
    whenPlayed:(deck,level,arenaPositions, index, turnsLeft, lastTurn) => playCrab(deck, arenaPositions, level, index, turnsLeft, lastTurn),
    heavy:true,
    level:1,
    worth:5
  }}
  export const fishingHut = () => {return {
    title:"Fishing Hut", 
    description:(prod, turnsLeft)=>`The Fishing Hut Generates ${prod}${prod!==1?"s":""} linkTo(Fish) Per Turn For ${turnsLeft} Turn${turnsLeft!==1?"s":""}.`,
    turnsLeft:12, 
    production: 1, cardType:"building", 
    position:generateRandomID(), 
    weight:4, attack:0, health:18, 
    whenPlayed: (deck,level,arenaPositions, index, turnsLeft, lastTurn) => playFish(deck, arenaPositions, level, index, turnsLeft, lastTurn),
    heavy:true,
    level:1,
    worth:10
  }}
  export const bee = () => {return {
    title:"Bee", 
    description:()=>"A Bee.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:1, attack:0, health:1,
    heavy:true,
    level:1,
    worth:1
  }}
  export const slug = () => {return {
    title:"Slug", 
    description:()=>"A Slug.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:1, attack:2, health:2,
    heavy:true,
    level:1,
    worth:0
  }}
  export const snail = () => {return {
    title:"Snail", 
    description:()=>"The Snail has a protective shell.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:1, attack:2, health:4,
    heavy:true,
    replacement:()=>slug,
    level:1,
    worth:1
  }}
  export const pearl = () => {return {
    title:"Pearl", 
    description:()=>"A Pearl.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:1, attack:0, health:1,
    heavy:true,
    level:1,
    worth:16
  }}
  export const clam = () => {return {
    title:"Clam", 
    description:()=>"The Clam may contain a linkTo(Pearl)", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:1, attack:1, health:2,
    heavy:true,
    replacement:(seed)=>{
      const randomNumber = random(seed)
      const possibleCards=[pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,pearl,()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},()=>{},worm,fly]
      return possibleCards[Math.floor(randomNumber*possibleCards.length)]
    },
    level:1,
    worth:2
  }}
  export const crab = () => {return {
    title:"Crab", 
    description:()=>"A Crab.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:1, attack:2, health:4,
    heavy:true,
    level:1,
    worth:3
  }}
  export const fish = () => {return {
    title:"Fish", 
    description:()=>"A Fish.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:2, 
    attack:3, 
    health:8,
    heavy:true,
    level:1,
    worth:5
  }}
  export const frog = () => {return {
    title:"Frog", 
    description:()=>"A Frog.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:3, attack:5, health:12,
    heavy:true,
    level:1,
    worth:28
  }}
  export const bigFish = () => {return {
    title:"Big Fish", 
    description:()=>"A Big Fish.", 
    cardType:"troop", 
    position:generateRandomID(), 
    weight:6, attack:10, health:18,
    heavy:true,
    level:1,
    worth:32
  }}
  export const fly = () => {return {
    title:"Fly", 
    description:(_,turnsLeft)=>`A Fly. Catches a linkTo(Frog) in ${turnsLeft} turn${turnsLeft!==1?"s":""}.`, 
    turnsLeft:2, 
    production:1,
    cardType:"troop", 
    position:generateRandomID(), 
    weight:0, attack:0, health:Number.MAX_SAFE_INTEGER,  
    whenPlayed:(deck,level,arenaPositions, index, turnsLeft) => delay(()=>replaceFrog(arenaPositions, index, level), arenaPositions, turnsLeft, deck, level, true),
    level:1,
    worth:1
  }}
  export const worm = () => {return {
    title:"Worm", 
    description:(_,turnsLeft)=>`A Worm. Catches a linkTo(Big Fish) in ${turnsLeft} turn${turnsLeft!==1?"s":""}.`, 
    turnsLeft:2, 
    production:1,
    cardType:"troop", 
    position:generateRandomID(), 
    weight:0, attack:0, health:2, 
    whenPlayed:(deck,level,arenaPositions, index, turnsLeft) => delay(()=>replaceBigFish(arenaPositions, index, level), arenaPositions, turnsLeft, deck, level, true),
    level:1,
    worth:1
  }}
  export const lifePreserver = () => {return {
    title:"Life Preserver", 
    description:()=>'The Life Preserver moves all played cards To deck when purchased.',
    cardType:"spell", 
    position:generateRandomID(), 
    weight:0, attack:0, health:0, 
    level:1,
    worth:0,
    heavy:true
  }}
  export const repairKit = () => {return {
    title:"Repair Kit", 
    description:()=>'Repairs one card slot on your plank when placed on it.',
    cardType:"spell", 
    position:generateRandomID(), 
    weight:0, attack:0, health:0, 
    whenPlaced:(_, index, destroyedPositions)=>{
      return {cardPlaced:destroyedPositions.includes(index),destroyedPositions:destroyedPositions.includes(index)?destroyedPositions.filter(i=>i!==index):false, arenaPositions:false}
    },
    level:1,
    worth:0,
    heavy:true
  }}
  export const bandages = () => {return {
    title:"Bandages", 
    description:()=>'Heals any card when placed on it.',
    cardType:"spell", 
    position:generateRandomID(), 
    weight:0, attack:0, health:0, 
    whenPlaced:(arenaPositions, index)=>{return {cardPlaced:(arenaPositions[index]?.[0]?.title)&&(arenaPositions[index]?.[0]?.health<allCards()[arenaPositions[index]?.[0]?.title]().health),arenaPositions: arenaPositions[index]?.[0]?.title?arenaPositions.map((item,i)=>i===index?[{...item[0], health:allCards()[item[0].title]().health}]:item):false,destroyedPositions:false}},
    level:1,
    worth:0,
    heavy:true
  }}
  
  export const delay = (getCardFunct, arenaPositions, turnsLeft, deck, level, lastTurn) => {
    if (turnsLeft <= 1 && lastTurn) {
      const d = getCardFunct(level)
      return [[...deck], d,false]
    } else {
      return [[...deck], [...arenaPositions],false]
    }
  }
  export const playBee = (deck,arenaPositions, level, index, turnsLeft, lastTurn)=>{
    const newDeck = [...deck, {...bee(), level:level}]
    return delay(()=>{const newArenaPositions = [...arenaPositions];newArenaPositions[index] = []; return newArenaPositions},arenaPositions,turnsLeft, newDeck, level, lastTurn)
  }
  export const playCrab = (deck,arenaPositions, level, index, turnsLeft, lastTurn)=>{
    const newDeck = [...deck, {...crab(), level:level}]
    return delay(()=>{const newArenaPositions = [...arenaPositions];newArenaPositions[index] = []; return newArenaPositions},arenaPositions,turnsLeft, newDeck, level, lastTurn)
  }
  export const playFish = (deck,arenaPositions, level, index, turnsLeft, lastTurn)=>{
    const newDeck = [...deck, {...fish(), level:level}]
    return delay(()=>{const newArenaPositions = [...arenaPositions];newArenaPositions[index] = []; return newArenaPositions},arenaPositions,turnsLeft, newDeck, level, lastTurn)
  }
  export const getAttack = (item) => {
    return Math.max(0,(Object.keys(item[0].floatie??{}).length>0?-1:0)+((item[0].cardType==="building")?(item[0].attack):(item[0].attack+item[0].level-1))+Object.values(item[0].beetle??{}).reduce((partialSum, a) => partialSum + a, 0))
  }
  export const getWeight = (item) => {
    return item[0].weight-(Object.values(item[0].floatie??{}).reduce((partialSum, a) => partialSum + a, 0))+(item[0]?.weightLevels??0)*item[0].level
  }
  export const getProduction = (item) => {
    return Math.max(allCards()[item[0].title]().production,item[0].production+Object.values(item[0].ladybug??{}).reduce((partialSum, a) => partialSum + a, 0))+(item[0]?.productionLevels??0)*item[0].level
  }
  export const replaceFrog = (arenaPositions, index, level)=>{const placedFrog = {...frog(), ignoreFirstAttack:true, level:level};return arenaPositions.map((item,idx)=>{return idx===index?[{...placedFrog, ignoreFirstAttack:true, level:level}]:item})}
  export const replaceBigFish = (arenaPositions, index, level)=>{const placedFish = {...bigFish(), ignoreFirstAttack:true, level:level};const newArenaPositions = [...arenaPositions];newArenaPositions[index] = [{...placedFish}];return newArenaPositions}
  const enhance = (cardType, arenaPositions, position, production,instanceid) => {
    if (cardType==="production") {
      return arenaPositions.map((cardContainer, cardIndex)=>{
        let card = cardContainer[0]
        if (!card) return []
        if (cardIndex === position-1 || cardIndex === position+1) {
          if (card.cardType ==="building") {
            if ((card?.ladybug?.[instanceid]??0)<production) {
              let ladybug = card?.ladybug?card.ladybug:{}
              ladybug[instanceid] = production
              return [{...card, ladybug: ladybug}]
            }
          }
        }
        return [{...card}]
      })
    }
    else if (cardType==="troopWeight") {
      return arenaPositions.map((cardContainer, _)=>{
        let card = cardContainer[0]
        if (!card) return []
        if (card.cardType==="troop") {
          if ((card?.floatie?.[instanceid]??0)<production)  {
            let floatie = card?.floatie?card.floatie:{}
            floatie[instanceid] = production
            return [{...card, floatie: floatie}]
          }
        }
        return [{...card}]
      })
    }
    else if (cardType==="troopAttack") {
      return arenaPositions.map((cardContainer, cardIndex)=>{
        let card = cardContainer[0]
        if (!card) return []
        if (cardIndex === position-1 || cardIndex === position+1) {
          if (card.cardType ==="troop") {
            if ((card?.beetle?.[instanceid]??0)<production) {
              let beetle = card?.beetle?card.beetle:{}
              beetle[instanceid] = production
              return [{...card, beetle: beetle}]
            }
          }
        }
        return [{...card}]
      })
    }
  }