import Waves from '../Components/Main/Waves.js';
import {createAccount} from '../TableFunctions/UserFunctions.js'
import '../Styles/CreateAccount.css'
import { useState } from 'react';
//import { useSearchParams } from 'react-router-dom';
import PasswordStrength from '../Components/Main/PasswordStrength.js';
export default function CreateAccountPage ({shortScreen}) {
    //const [searchParams] = useSearchParams();
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [username, setUsername] = useState("")
    const [pView, setPView] = useState(false)
    const [p2View, setP2View] = useState(false)
    const [match, setMatch] = useState(false)
    //const error = searchParams.get("error")

    const strength = () => {
        if (password===password2) {
            setMatch(true)
        } else {
            setMatch(false)
            return 0
        }
        if (password) {
            const passwordCheck0 = /^(?=(.*[a-z]){1,}).*$/
            const test0 = password.match(passwordCheck0)
            const passwordCheck1 = /^(?=(.*[A-Z]){1,}).*$/
            const test1 = password.match(passwordCheck1)
            const passwordCheck2 = /^(?=(.*[0-9]){1,}).*$/
            const test2 = password.match(passwordCheck2)
            const passwordCheck3 = /^(?=(.*[!@#$%^&*()\-__+.?,~`{}|]){1,}).*$/
            const test3 = password.match(passwordCheck3)
            const passwordCheck4 = /^.{8,}$/
            const test4 = password.match(passwordCheck4)
            return (test0!==null?1:0)+(test1!==null?1:0)+(test2!==null?1:0)+(test3!==null?1:0)+(test4!==null?1:0)
        }
        return 0
    }
    return (
        <div className='App' style={{height:'100vh'}}>
            <Waves viewing={false}/>
            <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}}>
            <div style={{height:'25vh', fontSize: '5vh'}}>
                        <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
                    </div>
                    <div style={{position:"relative", width:"80vw", height:'60vh', top:'0vh',left:"10vw", borderRadius:'5vh', backgroundColor:"#ffffffaa"}}>
                <form autoComplete='on' onSubmit={e=>{
                    e.preventDefault();
                    const passwordCheck = /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.?,~`{}|]){1,}).{8,}$/
                    if ((password.match(passwordCheck) && username.length > 3)&&(password===password2)) {
                        createAccount(username.toLowerCase(),password, (e)=>{
                            if (e) {
                                window.location.href = '/signin?error=2'
                            } else {
                                window.location.href = '/createaccount?error=usernameTaken'//username used
                            }
                        })
                    } else {
                        window.location.href = '/createaccount?error=badPassword'//password not good enough
                    }
                }}>
                <div style={{position:'relative', top:'15vh', left:"-5vw", fontSize: '2vh', fontWeight:"bold"}}>Username:</div>
                            <div><input
                                className='Username'
                                type="username"
                                placeholder="Enter your username"
                                value={username}
                                onChange={e=>setUsername(e.target.value)}
                            /></div>
                            <div style={{position:'relative', top:'15vh', left:"-5vw", fontSize: '2vh', fontWeight:"bold"}}>Password:</div>
                            <div>
                                <input
                                    style={{paddingRight:'5vh'}}
                                    className='Password'
                                    type={pView?"text":"password"}
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={e=>setPassword(e.target.value)}
                                />
                                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                                <div className={pView?"fa fa-eye-slash":"fa fa-eye"} style={{position:"absolute", right:"30vw", top:'24.5vh', width:'1vh', height:'1vh', fontSize:'2.5vh'}} onClick={()=>setPView(!pView)}></div>
                            </div>
                            <div style={{position:'relative', top:'15vh', left:"-5vw", fontSize: '2vh', fontWeight:"bold"}}>Password:</div>
                            <div>
                                <input
                                    style={{paddingRight:'5vh'}}
                                    className='Password'
                                    type={p2View?"text":"password"}
                                    placeholder="Enter your password"
                                    value={password2}
                                    onChange={e=>setPassword2(e.target.value)}
                                />
                                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                                <div className={p2View?"fa fa-eye-slash":"fa fa-eye"} style={{position:"absolute", right:"30vw", top:'34.5vh', width:'1vh', height:'1vh', fontSize:'2.5vh'}} onClick={()=>setP2View(!p2View)}></div>
                            </div>
                            <button className='CreateAccountButtonSmall'>Create</button>
                            
                        <div style={{position:'relative',top:'21vh'}}>
                            <div>Username: Minimum four characters.</div>
                            {match?
                            <div>Password: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character.</div>
                            :
                            <div>Passwords Do Not Match.</div>    
                        }
                        </div>
                    <PasswordStrength strength={strength}/>
                </form>
                </div>
            </div>
            
        </div>
        
    )
}