import { getItemStyle } from "./ArenaHelperFunctions"
import BrokenPlank from "./BrokenPlank"
import Card from "./Card"

export default function Planks ({resetSelections, canRemove, highlightedCards, setHighlightedCards, selectedPlankCard, setSelectedPlankCard, sink, opponent, viewing, onRemoved, arenaPositions, setCurrentCard, destroyedPositions, destroyingPositions}) {
    const cardClick = (card, index) => {
        if (canRemove) setSelectedPlankCard({card:card, index:index})
    }
    const onHighlighted = (card, index) => {
        if (!Object.keys(card).length>0) return
        if (!card.heavy || !canRemove) return
        if (!highlightedCards.map(item=>item.index).includes(index)) setHighlightedCards([...highlightedCards,{card:card, index:index}])
        else setHighlightedCards(highlightedCards.filter(item=>item.index!==index))
    }
    return (
        <div>
            <div style={{transform: !viewing?"perspective(5000px) rotateX(55deg) translateY(-80vh) translateZ(10vh)":"perspective(5000px) translateY(-45vh) rotateX(-1deg) translateZ(-20vh)", transition: "transform 1s", position:"relative"}}>
                <div className={"ArenaPlankOpponent"+(opponent?.sink?"Sink":"")}>
                    {opponent?.arenaPositions?.map((cards, index)=>
                            {
                                if (opponent?.destroyedPositions?.map(num=>7-num)?.includes(index)) {
                                    return (
                                        <BrokenPlank key={index} destroying={opponent?.destroyingPositions.map(num=>7-num)?.includes(index)}/>
                                    )
                                }
                                const card = cards[0]
                                return cards.length>0?
                                    (<div 
                                        className={"skewCardPersp"+(opponent?.sink?"Sink":"")} 
                                        key={index} 
                                        style={{cursor: "none",height: '24vh', width:'100%'}}>
                                        <div style={{...getItemStyle(false, {}),cursor:"none"}}>
                                                <Card {...card} setCurrentCard={setCurrentCard} opp={true} placed={true}/>
                                        </div>
                                    </div>)
                                    :
                                    (<div 
                                        key={index} 
                                        style={{height: '24vh', width:'100%'}}>
                                    </div>)
                            }
                    )}
                </div>
                <div className={"ArenaPlank"+(sink?"Sink":"")}>
                    {arenaPositions?.map((cards, index)=>
                            {
                                if (destroyedPositions?.includes(index)) {
                                    return (
                                        <BrokenPlank key={index} destroying={destroyingPositions?.includes(index)}/>
                                    )
                                }
                                const card = cards[0]
                                return cards.length>0?(
                                <div className={"skewCardPersp"+(sink?"Sink":"")} key={index} style={{cursor: card.heavy?"url(/trash.png),no-drop":"url(/recycle.png),alias",height: '24vh', width:'100%'}}>
                                    <div style={getItemStyle(false, {})}>
                                            <Card {...card} highlighted={highlightedCards.map(item=>item.index).includes(index)} onHighlighted={(e)=>{e.preventDefault();if (!card.cardSinked&&!sink) {onHighlighted(card,index)}}} setCurrentCard={setCurrentCard} onRemoved={()=>{if (!card.cardSinked&&!sink) {cardClick(card,index)}}} placed={true} />
                                        </div></div>):(<div key={index} style={{height: '24vh', width:0}}></div>)
                            }
                    )}
                </div>
            </div>
            <div className="CardShopBackgroundOuter" style={{display: !(selectedPlankCard?.card?.title)?"none":"", position:"absolute",top:"-104vh", zIndex:525}}></div>
            <div className="CardShopBackgroundInner" style={{display: !(selectedPlankCard?.card?.title)?"none":"", borderRadius:"5vh",position:"absolute", zIndex:650, top:"-71vh", left:0, right:0, margin:"auto", width: "36vh", height:"24vh", backgroundColor:"white"}}></div>
            <div className="CardShopBackgroundCards" style={{display: !(selectedPlankCard?.card?.title)?"none":"", position:"absolute", zIndex:651, top:"-71vh", left:0, right:0, margin:"auto"}}>
                <div style={{display: !(selectedPlankCard?.card?.title)?"none":"", width:"28vh",position:"relative", top:"4vh", left:0, right:0, margin:"auto", fontWeight:"800", fontSize:'1.75vh'}}>{selectedPlankCard?.card?.heavy?`Trade ${highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?"highlighted cards":"this card"} for ${highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?highlightedCards.map(item=>item.card.worth*(item.card.level)).reduce((partialSum, a) => partialSum + a, 0):(selectedPlankCard?.card?.worth*(selectedPlankCard?.card?.level))} shell${(highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?highlightedCards.map(item=>item.card.worth*(item.card.level)).reduce((partialSum, a) => partialSum + a, 0):(selectedPlankCard?.card?.worth*(selectedPlankCard?.card?.level)))>1?"s":""}?`:"Move card to deck?"}</div>
                <div className="RedButtonSmall" style={{position:"absolute", right:0, left:"-18vh", margin:"auto", top:'12vh',width:"12vh", fontSize:'2vh', lineHeight:"4vh", fontWeight:"800"}} onClick={()=>{resetSelections()}}>No</div>
                <div className="SignInButtonSmall" style={{position:"absolute",right:"-18vh", left:0, margin:"auto", top:'12vh', fontSize:'2vh', lineHeight:"4vh",width:"12vh", fontWeight:"800"}} onClick={()=>{onRemoved(highlightedCards.map(item=>item.index).includes(selectedPlankCard.index)?highlightedCards:selectedPlankCard.card, selectedPlankCard.index);resetSelections()}}>Yes</div>
            </div>
        </div>
    )
}