export default function Scale ({weight, viewing, getPlankCapacity, state}) {
    return (
        <div style={{fontWeight:800, fontSize:'1.6vh', opacity:viewing?0:1, transition: "opacity .5s", animation: weight>(getPlankCapacity(state)-1)?"shake 1s linear infinite":"none"}}>
            <div style={{color:"#1f5171", width:'12vh'}}>
                <div style={{fontSize:'1.4vh', position:"relative",left:"-2vh",top:"11vh"}}>{isNaN(weight)?0:weight}/{getPlankCapacity(state)-1} Weight</div>
            </div>
            <div style={{width:'8vh', height:'8vh', borderRadius:"50%", backgroundColor:((weight<(getPlankCapacity(state)-1))||weight===undefined)?"#888888":"#de5454"}}>
                <div style={{position:"relative", left:'1vh', top:'1vh',width:'6vh', height:'6vh', borderRadius:"50%",transform:"rotate(-90deg)", backgroundImage:`conic-gradient(${((weight<(getPlankCapacity(state)-1))||weight===undefined)?"white":"#ffbbbb"} 50%, ${((weight<(getPlankCapacity(state)-1))||weight===undefined)?"#888888":"#de5454"} 50%)`}}>

                </div>
                <div style={{position:"relative", top:'-2vh', left:'.75vh'}}>
                    
                    <div style={{transformOrigin: "right",height:'.5vh', width:'3.5vh', backgroundColor:"black",transform:`rotate(${(weight===undefined?0:weight)*(180/(getPlankCapacity(state)-1))}deg)`, transition:"transform .5s"}}>

                    </div>
                    <div style={{backgroundColor:"black",width:'.75vh', height:'.75vh', borderRadius:"50%", position:"relative",left:'3.1vh',top:`${window.innerHeight * 0.01*-.55}px`}}></div>
                </div>
            </div>
            
            <div style={{color:"#1f5171", width:'12vh', position:"relative",left:"-2vh",top:"-13vh"}}>
                PLANK CAPACITY: 
            </div>
            
        </div>
    )
}