
export default function PasswordStrength({strength}) {
    return (
        <div>
            <div style={{position:"absolute", top:'42vh', left:"34vw", width:"12vw", backgroundColor:"#ffffffaa", height:'4vh', borderRadius:'5vh'}}></div>
            <div style={{position:"absolute", top:'43vh', left:"35vw", borderRadius:'1vh', width:"10vw", backgroundColor:"#888888", height:'1vh'}}></div>
            <div style={{position:"absolute", top:'43vh', left:"35vw", transition: "backgound-color 1s, width 1s", borderRadius:'1vh', width:(strength()===5)?("10vw"):((strength()===4)?("8vw"):((strength()===3)?("6vw"):((strength()===2)?("4vw"):((strength()===1)?("2vw"):(".4vw"))))), backgroundColor:(strength()===5)?("#58FF00"):((strength()===4)?("#B0FF00"):((strength()===3)?("#E5FF00"):((strength()===2)?("#FFC100"):((strength()===1)?("#FF7B00"):("#FF3400"))))), height:'1vh'}}></div>
            <div style={{position:"absolute", top:'44vh', left:"35vw", borderRadius:'1vh', width:"10vw", backgroundColor:"", height:'1vh', fontSize:'1.3vh'}}>Password Strength</div>
        </div>
    )
}