import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkJWT} from "../../TableFunctions/UserFunctions";
import { checkMatchmaking } from "../../TableFunctions/MatchmakingFunctions";

export default function UrlDetect({setLoaded, setIsLoggedIn}){
    const location = useLocation();
    const onChange = useCallback((location)=>{
        const jsonWT = localStorage.getItem("JWT")
        if (!jsonWT) { setLoaded(true); setIsLoggedIn(false); }
        if (jsonWT) setIsLoggedIn(true);
        checkJWT((e)=>{
          setIsLoggedIn(e===1?true:false);
          setLoaded(true)
          if (e===-1) {
            localStorage.removeItem("JWT")
          }
          if (!(location.pathname==="/findgame")) {
            localStorage.removeItem("findgame")
            if (!(location.pathname==="/game") && e===1) {
              checkMatchmaking()
              //TODO
              //send to game if it exists
            }
          }
        });
      },[setIsLoggedIn,setLoaded])
    useEffect(() => {
        onChange(location)
    }, [location,onChange]);
  return(<></>)
}