import { client, getUser, myCipherEventID, myCipherGameID } from "./UserFunctions";


const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

export function gameCoinsToday(callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const type = "GameCoins"
            const params = {
                TableName : 'DecksterousUserEvents',
                AttributesToGet: ['EventID', 'UserID','Type', 'TimeStamp'],
            };
            
            client.scan(params, async function(err, data) {
                if (err) {
                    callback(false)
                    return console.log(err)
                } else {
                    callback(data?.Items?.filter(item=>item.Type===type&&item.UserID===userID)?.filter(item=>item?.TimeStamp?datesAreOnSameDay(new Date(item.TimeStamp), new Date(Date.now())):false)?.length)
                }
            })
        }
        
    })
}

export function calculateCoins(callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const type = "Coins"
            const params = {
                TableName : 'DecksterousUserEvents',
                AttributesToGet: ['UserID','Type', 'Value'],
            };
            
            client.scan(params, async function(err, data) {
                if (err) {
                    callback(false)
                    return console.log(err)
                } else {
                    callback(data?.Items?.filter(item=>item.Type.includes(type)&&item.UserID===userID).reduce((partialSum, a) => partialSum + a?.Value??0, 0))
                }
            })
        }
        
    })
}

export function getEventsOfType(type, callback) {
    const params = {
        TableName : 'DecksterousUserEvents',
        AttributesToGet: ['EventID', 'UserID','Type', 'TimeStamp'],
    };
    
    client.scan(params, async function(err, data) {
        if (err) {
            callback(false)
            return console.log(err)
        } else {
            callback(data?.Items?.filter(item=>item.Type===type))
        }
    })
}

export function getEvent({type=null,id=null},callback) {
    if (!type || !id) {
        callback(false)
        return false
    }
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const eventID = myCipherEventID(JSON.stringify({user:username, id:id, type:type}))
            const params = {
                TableName : 'DecksterousUserEvents',
                Key: {
                    EventID: eventID,
                    UserID: userID
                }
            };

            client.get(params, async function(err, data) {
                if (err) {
                    callback(false)
                return console.log(err)
                }
                else {
                    callback(data?.Item)
                }
            });
        } else {
            callback(false)
        }
    })
}

export function putEvent ({type=null,id=null,value=null},callback) {
    if (!type || !id || value===null) {
        callback(false)
        return false
    }
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const eventID = myCipherEventID(JSON.stringify({user:username, id:id, type:type}))
            const params = {
                TableName : 'DecksterousUserEvents',
                Item: {
                    EventID: eventID,
                    UserID: userID,
                    Type:type,
                    Value:value,
                    TimeStamp:new Date().valueOf()
                }
            }
            client.put(params, function(err) {
                if (err) {
                    callback(false)
                    console.log(err)
                }
                else {
                    callback(eventID)
                }
            });
        } else {
            callback(false)
        }
    })
    
}