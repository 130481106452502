
export default function Bell ({turn, awaitingNextTurn, opponent, requestNextTurn, simulating}) {
    const color = simulating?"#54de54":((awaitingNextTurn && opponent?.awaitingNextTurn)?"#54de54":((awaitingNextTurn || opponent?.awaitingNextTurn)?(opponent?.awaitingNextTurn?(opponent?.turn!==turn?"#888888":"#de5454"):"#dccb60"):"#888888"))
    const message = simulating?"Simulating":((awaitingNextTurn && opponent?.awaitingNextTurn)?"Simulating":((awaitingNextTurn || opponent?.awaitingNextTurn)?(opponent?.awaitingNextTurn?(opponent?.turn!==turn?"Request Turn":"Opponent Requesting Turn"):"Requesting Turn"):"Request Turn"))
    return (
    <div style={{width:"100vw"}}>
        <div style={{position: "absolute", right:"16vh", top:'6vh', width:'20vw', height:'6vh'}}>
                <div style={{zIndex:600,position: "relative",right:"0", fontSize:'2vh',fontWeight:"800", width:"20vw",lineHeight: '6vh', height:'6vh', textAlign:"right"}}>{message}</div>
            </div>
        <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.1/css/all.min.css'></link>
        <div className="bell" 
            style={{
                animationName: ((awaitingNextTurn || opponent?.awaitingNextTurn) &&(turn===opponent?.turn))?"col":"", 
                background: color,
                zIndex: 521, 
                width:'6vh', 
                height:'6vh',
                top:'6vh',
                right:'6vh',

            }}
            onClick={()=>requestNextTurn()}>

        {((awaitingNextTurn || opponent?.awaitingNextTurn) &&(turn===opponent?.turn))? <div className="bell-border" style={{border: `1px solid ${color} !important`, boxShadow: `2px 2px 5px ${color}, -2px -2px 5px ${color}`, 
                width:'6vh', 
                height:'6vh'}}></div>:<></>}

        <i className="fas fa-bell btn-bell" style={{right:'2vh',top:'2vh',fontSize:'2vh',animationName: awaitingNextTurn?"bell-ring":""}}></i>
        </div>
    </div>)
}