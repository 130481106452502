export default function Result({won, lost, canLeave,handleHome}){
    return (
        <div>
            <div style={{position:"absolute", top:0}}>
                    <div className="CardShopBackgroundOuter" style={{animation: "shopPop .5s linear calc(1)"}}></div>
                    <div className="CardShopBackgroundInner" style={{animation: "shopPop .5s linear calc(1)"}}></div>
                    <div className="CardShopBackgroundCards" style={{animation: "shopPop .5s linear calc(1)"}}>
                    <div className="SignInHeader" style={{paddingTop:'25vh'}}>{won?"Game Won":(lost?"Game Lost":"Game Drawn")}</div>
                    <button
                            style={{position:"absolute", left:0, right:0, margin:"auto", top:'45vh', opacity:canLeave?1:0, transition: "opacity .5s", cursor:"none"}} 
                            className="CreateAccountButtonSmall" 
                            disabled={!canLeave}
                            onClick={()=>{
                                if (handleHome) {handleHome()}
                                else {window.location.href = '/'}
                            }}
                        >
                            Home
                        </button>
                    </div>
            </div>
        </div>
    )
}