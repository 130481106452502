import { getItemStyle, range } from "./ArenaHelperFunctions";
import BackOfCard from "./BackOfCard";
import { getListStyle } from "./Hand";

export default function OpponentHand ({hand, viewing}) {
    return (
        <div className="OpponentHand" style={{opacity: viewing?0:1, overflow: "hidden !important", transition: "opacity .5s",  display:"flex",justifyContent: "center"}}>
            {
                hand?
                <div style={getListStyle(hand)}>
                    {
                        range(hand.length).map(number=><div key={number} style={{...getItemStyle(), zIndex:300}}><BackOfCard/></div>)
                    }
                </div>
                :
                <></>
            }
        </div>
    )
}