import { Component } from "react";
import '../../Styles/Card.css'
import { DragDropContext} from "@hello-pangea/dnd";
import Waves from "../Main/Waves";
import PlayableArea from "./PlayableArea";
import Hand from "./Hand";
import { reorder, remove } from "./ArenaHelperFunctions";
import Shop from "./Shop";
import Deck from "./Deck";
import Bell from "./Bell";
import Planks from "./Planks";
import { cardMapToRep, repMapToCards } from "../../TableFunctions/GameTableFunctions";
import Exit from "./Exit";
import Result from "./Result";
import Scale from "./Scale";
import CardInfo from "./CardInfo";
import OpponentHand from "./OpponentHand";
import { getWeight } from "../../Data/Card/Cards";

export default class Arena extends Component {
    constructor(props) {
        super(props)
        this.gameFunctions = props.gameFunctions
        this.onDragEnd = this.onDragEnd.bind(this);
        this.requestNextTurn = this.requestNextTurn.bind(this);
        this.state = {
            selectedPlankCard:{},
            highlightedCards:[],
            draggingCard:false
        }
}
    
    requestNextTurn(newState) {
        if ((newState.awaitingNextTurn && this.props.opponentState.awaitingNextTurn)) { //both ready: simulate
            this.props.updatePlayer(newState)
            this.props.simulateCards(this.props.state, this.props.opponentState)
        }
    }
    onDragEnd(result) {
        this.setState({...this.state, draggingCard:false})
        // dropped outside the list
        if (!result.destination || this.props.simulating || this.state.simulating || this.state.viewing || this.props.state.sink) {
          return;
        }
        let sourceCards = []
        if (result.source.droppableId.includes("game")) {
            return
        } else if (result.destination.droppableId==="TopCard") {
            return
        } else {
            //from hand
            sourceCards = this.props.state.hand
            if (result.source.droppableId === result.destination.droppableId) {
                if (result.source.droppableId==="TopCard") return
                //to hand
                const hand = reorder(
                    sourceCards,
                    result.source.index,
                    result.destination.index
                    );
                this.props.setState({
                    ...this.props.state, hand
                    });
            } else {
                let hand= [...this.props.state.hand]
                let deck = [...this.props.state.deck]
                let removedCard = {}
                //from deck to hand
                if (result.source.droppableId==="TopCard") {
                    removedCard = deck[0]
                    deck.shift()
                    if (result.destination.droppableId==="hand") {
                        if (this.props.state.hand.length>=5) {
                            return
                        }
                        hand = reorder(
                            [...this.props.state.hand,removedCard],
                            this.props.state.hand.length,
                            result.destination.index
                            );
                        this.props.setState({
                            ...this.props.state, hand,deck, awaitingNextTurn:false
                            });
                            return
                    }
                }
                //from deck to areana setup
                else {
                    [hand, removedCard] = remove(sourceCards, result.source.index)
                }
                let cardLeveled = false
                let arenaPositions = [...this.props.state.arenaPositions].map(item=>item[0]?.cardSinked?[]:item)
                let newState = {...this.props.state}
                //to arena
                if (result.destination.droppableId.includes("game")) {
                    if (arenaPositions[result.destination.droppableId.charAt(4)].length === 1 && !(arenaPositions[result.destination.droppableId.charAt(4)][0].title===removedCard.title) && (!(typeof removedCard.whenPlaced === 'function'))) {
                        return
                    } else if ((arenaPositions[result.destination.droppableId.charAt(4)].length === 0) && (!this.props.state.destroyedPositions.includes(Number(result.destination.droppableId.charAt(4)))) && (!(typeof removedCard.whenPlaced === 'function'))) {
                        let newArenaPositions = arenaPositions
                        newArenaPositions[result.destination.droppableId.charAt(4)] = [removedCard]
                        newState = {
                            ...this.props.state, hand,deck, arenaPositions: newArenaPositions, sink:newArenaPositions.flat(1).filter(item=>!item.cardSinked).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getPlankCapacity(this.props.state)?true:false, awaitingNextTurn:false, opponentSink:this.props.opponentState?.arenaPositions?.flat(1).filter(item=>!item.cardSinked).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getOppPlankCapacity(this.props.opponentState)
                            }
                    } else if (arenaPositions[result.destination.droppableId.charAt(4)][0]?.cardSinked) {
                        return
                    } else if (removedCard.onlyLevel) {
                        return
                    } else if (arenaPositions[result.destination.droppableId.charAt(4)].length === 1 && (arenaPositions[result.destination.droppableId.charAt(4)][0].title===removedCard.title)) {
                        let leveledUpCard = {...arenaPositions[result.destination.droppableId.charAt(4)][0]}
                        if ((leveledUpCard.level + removedCard.level)<=5) {
                            leveledUpCard = {...leveledUpCard, level: leveledUpCard.level + removedCard.level}
                            removedCard = leveledUpCard
                            let newArenaPositions = arenaPositions
                            newArenaPositions[result.destination.droppableId.charAt(4)] = [leveledUpCard]
                            newState = {
                                ...this.props.state, hand,deck, arenaPositions: newArenaPositions, awaitingNextTurn:false
                                }
                            cardLeveled = true
                        } else {
                            return
                            //TODO Queen Bee, big Fish, etc
                        }
                    }
                    if (typeof removedCard.whenPlaced === 'function') {
                        const data = removedCard.whenPlaced(newState.arenaPositions, parseInt(result.destination.droppableId.charAt(4)), newState.destroyedPositions,removedCard)
                        if (data.cardPlaced || cardLeveled) {
                            newState = {
                                ...newState, deck:deck, hand:hand, arenaPositions: data.arenaPositions?data.arenaPositions:newState.arenaPositions, awaitingNextTurn:false, destroyedPositions: data.destroyedPositions?data.destroyedPositions:newState.destroyedPositions
                                }
                        } else {
                            return
                        }
                    }
                    let newArenaPositions = [...newState.arenaPositions]
                    newState.arenaPositions.forEach((cardContainer,idx)=>{
                        if ((typeof cardContainer[0]?.whenPlaced === 'function')&&(cardContainer[0]?.cardType === 'troop')) {
                            const newData = cardContainer[0].whenPlaced(newArenaPositions,idx,newState.destroyedPositions,newArenaPositions[idx][0])
                            newArenaPositions = newData.arenaPositions?newData.arenaPositions:newState.arenaPositions
                        }
                    })
                    newState.arenaPositions = [...newArenaPositions]
                    newArenaPositions = [...newState.arenaPositions]
                    newState.arenaPositions.forEach((cardContainer,idx)=>{
                        if ((typeof cardContainer[0]?.whenPlaced === 'function')&&(cardContainer[0]?.cardType === 'building')) {
                            const newData = cardContainer[0].whenPlaced(newArenaPositions,idx,newState.destroyedPositions,newArenaPositions[idx][0])
                            newArenaPositions = newData.arenaPositions?newData.arenaPositions:newState.arenaPositions
                        }
                    })
                    const sink = newArenaPositions.flat(1).filter(item=>!item.cardSinked).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getPlankCapacity(this.props.state)?true:false
                    if (sink) {
                            this.props.setState({...newState, arenaPositions:newArenaPositions,paused:true,sink:true})
                            setTimeout(() => {  
                                this.gameFunctions.handleSink(this.props.state, this.props.opponentState)
                            }, 6000);
                    } else {
                        this.props.setState({...newState, hand:hand, deck:deck, arenaPositions:newArenaPositions,sink:false})
                    }
                }
                

            }
        }
        
      }

    render() {
        return (
             <div className="ArenaCards" style={{height:"100vh"}}>
                <Waves 
                    viewing={this.props.state.viewing}
                    />
                {
                    !(this.props.state?.lost||this.props.state?.won||this.props.state?.draw)||(this.props.tutorial===true)?
                    <>
                        <Bell 
                        turn={this.props.state.turn}
                        requestNextTurn={()=>{
                            //sink or going to sink, paused/simulating, cardBeingRemoved, higher turn ; all do nothing
                            if (this.props.state.sink) return
                            if (this.props.state.arenaPositions.flat(1).filter(item=>!(item?.cardSinked??false)).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getPlankCapacity(this.props.state)?true:false) return
                            if (this.props.simulating || this.props.state.paused) return
                            if (this.props.state.turn > this.props.opponentState.turn) return
                            if (this.props.state.arenaPositions.flat(1).filter(item=>{if (item?.cardSinked) return true; return false }).length>0) return
                            if (!this.props.state.awaitingNextTurn) {
                                this.props.setState(prevState=>{
                                    const newState = {...prevState, awaitingNextTurn: true, paused: true};
                                    this.props.updatePlayer(newState)
                                    this.requestNextTurn(newState)
                                    return newState
                                })
                            }
                        }}
                        awaitingNextTurn={this.props.state.awaitingNextTurn}
                        simulating={this.props.simulating}
                        opponent={this.props.opponentState}
                        />
                        
                        <Shop 
                            turn={this.props.state.turn}
                            setCurrentCard={this.props.setCurrentCard}
                            shells={this.props.state.shells}
                            destroyedPositions={this.props.state.destroyedPositions}
                            saveCards={(newShells)=>{
                                if (!this.props.simulating) {
                                    this.props.setState({...this.props.state, shells: newShells, arenaPositions: [[],[],[],[],[],[],[],[]], deck:[...this.props.state.deck, ...this.props.state.arenaPositions.flat(1)]});
                                }
                            }}
                            addCard={(purchasedCard, newShells)=>{
                                if (!this.props.simulating) {
                                    this.props.setState({...this.props.state, shells: newShells, deck: [...this.props.state.deck, purchasedCard]});
                                }
                            }}
                            inShop={this.props.state.inShop && !(this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating)}
                            handleShop={()=>{this.props.setState({...this.props.state,inShop: !this.props.state.inShop, exiting:false})}}
                        />
                        <CardInfo onCloseCardInfo={this.props?.onCloseCardInfo} setCurrentCard={this.props.setCurrentCard} currentCard={this.props.currentCard}/>
                        { this.props.tutorial!==true?
                            <Exit
                                exiting={this.props.state.exiting && !(this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating)}
                                turn={this.props.state.turn}
                                opponentUsername={this.props.opponentUsername} 
                                handleExitPress={()=>{
                                    this.props.setState({...this.props.state,inShop: false, exiting:!this.props.state.exiting})
                                }}
                                handleExit={()=>{
                                this.props.handleExit()
                                }}
                            />
                            :
                            <></>
                        }
                    </>
                    :
                    <>
                    <Result handleHome={()=>{if (this.props.handleHome) this.props.handleHome(this.props.state)}} canLeave={this.props.canLeave} won={this.props.state?.won} lost={this.props.state?.lost} draw={this.props.state?.draw}/>
                    </>
                }
                <DragDropContext 
                    style={{ float: "left", width:"100vw" }} 
                    onDragEnd={this.onDragEnd}
                    onDragStart={(e)=>{
                        if (e.source.droppableId!=="TopCard") this.setState({...this.state, draggingCard:true})
                    }}
                    >
                        
                    <PlayableArea
                        setCurrentCard={this.props.setCurrentCard}
                        arenaPositions={this.props.state.arenaPositions}
                        viewing={this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating}
                        destroyedPositions={this.props.state.destroyedPositions}
                    />
                    <div style={{zIndex:100, overflow: "visible", height:"27vh", width:"100vw", position:"fixed", bottom:0}}>
                        <Hand 
                            setCurrentCard={this.props.setCurrentCard}
                            hand={this.props.state.hand}
                            viewing={this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating}
                        />
                    </div>
                    <div style={{zIndex:150, overflow: "visible", height:"27vh", width:"12vh", position:"fixed", bottom:0,right:"12vh"}}>
                    <Deck 
                        dragging={this.state.draggingCard}
                        setCurrentCard={this.props.setCurrentCard}
                        state={this.props.state} 
                        setState={this.props.setState}
                        viewing={this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating}
                    />
                </div>
                </DragDropContext>
                {
                    this.props.tutorial!==true?
                    <OpponentHand 
                        hand={this.props.opponentState.hand} 
                        viewing={this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating}
                    />
                    :
                    <></>
                }
                <div style={{height:"24vh", position: "absolute", bottom:"-28vh", left:0, right:0, margin: "auto"}}>
                    <Planks 
                        onRemoved={
                        (removedCards, index)=>{
                            if (this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating) {
                                return
                            }
                            let card = {}
                            let removingHighlighted = false
                            let newArenaPositions = [...this.props.state.arenaPositions];
                            if (removedCards instanceof Array) {
                                card = this.props.state.arenaPositions[index][0]
                                removingHighlighted = true
                                removedCards.forEach(removedCard=>{
                                    if (typeof removedCard.whenRemoved === "function") {
                                        let data = removedCard.whenRemoved(newArenaPositions, removedCard)
                                        newArenaPositions = data.arenaPositions?data.arenaPositions:newArenaPositions
                                    }
                                })
                            } else {
                                card = removedCards
                                if (typeof card.whenRemoved === "function") {
                                    let data = card.whenRemoved(newArenaPositions, card)
                                    newArenaPositions = data.arenaPositions?data.arenaPositions:newArenaPositions
                                }
                            }
                            if ((!this.props.state.sink) && (!this.props.state.paused) && (card.heavy)) {
                                let newShells = 0
                                const goingToSink = newArenaPositions.map((item,idx)=>((idx===index)||(removingHighlighted&&removedCards?.map(removItm=>removItm.index).includes(idx)))?0:(item[0]?.title?getWeight(item):0)).filter(item=>!(item?.[0]?.cardSinked??false)).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getPlankCapacity(this.props.state)
                                if (removingHighlighted) {
                                    newArenaPositions = newArenaPositions.map((item,idx)=>((idx===index)||(removingHighlighted&&removedCards?.map(item=>item.index).includes(idx)))?[{...item[0], cardSinked:true}]:item)
                                } else {
                                    newArenaPositions[index] = [{...card, cardSinked:true}]
                                }
                                newShells = removingHighlighted?removedCards.map(item=>item.card.worth*(item.card.level)).reduce((partialSum, a) => partialSum + a, 0):(card.worth*(card.level))
                                const newState = {...this.props.state, shells: this.props.state.shells+newShells,sunken: goingToSink?[...this.props.state.sunken]:[...this.props.state.sunken, card], arenaPositions: newArenaPositions,
                                    sink:goingToSink}
                                this.props.setState(newState);
                                if (goingToSink) {
                                    setTimeout(() => {  
                                        this.gameFunctions.handleSink(newState, this.props.opponentState)
                                    }, 6000);
                                } else {
                                    setTimeout(()=>{
                                        this.props.setState(prevState=>{return{...prevState, arenaPositions: prevState.arenaPositions.map(item=>item?.[0]?.cardSinked?[]:item)}})
                                    },4000)
                                }
                            } else if ((!this.props.state.sink) && (!this.props.state.paused) && (!card.heavy)) {
                                newArenaPositions[index] = [];
                                const goingToSink = newArenaPositions.flat(1).filter(item=>!(item?.cardSinked??false)).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getPlankCapacity(this.props.state)?true:false
                                this.props.setState({...this.props.state,deck:[...this.props.state.deck, card ].map(cardMapToRep).map(item=>{return {...repMapToCards(item,false), level: item.level}}), arenaPositions: newArenaPositions,
                                                sink:!this.props.state.sink?goingToSink:true});
                                if ((newArenaPositions.flat(1).filter(item=>!(item?.cardSinked??false)).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.gameFunctions.getPlankCapacity(this.props.state))&&!this.props.state.sink) {
                                    setTimeout(() => {  
                                        this.gameFunctions.handleSink(this.props.state, this.props.opponentState)
                                    }, 6000);
                                }
                            }
                        }}
                        canRemove={!(this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating)}
                        selectedPlankCard={this.state.selectedPlankCard}
                        setSelectedPlankCard={card=>this.setState({...this.state,selectedPlankCard:card})}
                        highlightedCards={this.state.highlightedCards}
                        setHighlightedCards={cards=>this.setState({...this.state,highlightedCards:cards})}
                        resetSelections={()=>this.setState({...this.state,highlightedCards:[],selectedPlankCard:{}})}
                        opponent={this.props.opponentState}
                        viewing={this.props.state.viewing}
                        sink={this.props.state.sink}
                        arenaPositions={this.props.state.arenaPositions}
                        setCurrentCard={this.props.setCurrentCard}
                        destroyedPositions={this.props.state.destroyedPositions}
                        destroyingPositions={this.props.state.destroyingPositions}
                    />
                </div>
                <div style={{zIndex:150, overflow: "visible", height:"20vh", width:"12vh", position:"fixed", bottom:0,left:"6vh"}}>
                <Scale state={this.props.state} getPlankCapacity={this.gameFunctions.getPlankCapacity} viewing={this.props.state.viewing || (this.props.state.awaitingNextTurn && this.props.opponentState.awaitingNextTurn) || this.props.simulating} weight={this.props.state.arenaPositions.filter(item=>item.length!==0).filter(item=>!item?.[0].cardSinked).map(item=>getWeight(item)).reduce((partialSum, a) => partialSum + a, 0)}/>
                </div>
                
             </div>
        );
    }
}