import { useSearchParams } from "react-router-dom";
import { starterDeck, starterPlank } from "../App.js";
import { drawCards, shuffleDeck } from "../Components/Game/ArenaHelperFunctions.js";
import { checkGameStillExists, getOpponentState, getPlayerState, processDownloadState, putPlayerState, removeGame } from "../TableFunctions/GameTableFunctions.js";
import { useEffect, useState } from "react";
import Arena from "../Components/Game/Arena.js";
import { myDecipherGameID, myDecipherJWT } from "../TableFunctions/UserFunctions.js";
import { GameFunctions } from "../Functions/GameFunctions/GameFunctions.js"

export default function Game() {
    const {hand, deck} = drawCards(shuffleDeck(starterDeck))
    const plank = starterPlank
    const [state, setState] = useState({})
    const [opponentState, setOpponentState] = useState({})
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id")
    const [updates, setUpdates] = useState(0)
    const [simulating, setSimulating] = useState(false)
    const [currentCard, setCurrentCard] = useState([])
    
    const [opponentUsername, setOpponentUsername] = useState("")
    const [canLeave, setCanLeave] = useState(false)
    const removeGameFunc = (removedState) => {
        setState(removedState)
            setTimeout(()=>{
                setState(removedState)
                removeGame(id, removedState,()=>{setCanLeave(true)})
            },1000)
    }
    const updateOpponent = (simming=false) => {
        getOpponentState(id, state.turn, (oppGame, oppTurn)=>{
            if (!simming&&!state.simulating && !simulating) {
                if (oppTurn==='error') {
                    return
                }
                if (oppTurn?.State) {
                    const oppState = oppTurn.State
                    let oppStateProcessed = processDownloadState(oppState)
                        oppStateProcessed.arenaPositions = oppStateProcessed.arenaPositions.reverse()
                        setOpponentState(oppStateProcessed)
                        if (oppStateProcessed.awaitingNextTurn && state.awaitingNextTurn) {
                            gameFunctions.simulateCards(state, oppStateProcessed)
                        }
                } else {
                    //SETTING OPPONENT NEW GAME START
                    setOpponentState({
                        inShop: false,
                        turn: 0,
                        lastShuffle:-1,
                        viewing: false,
                        awaitingNextTurn: false,
                        sink:false,
                        deck: deck,
                        topCard:deck[0],
                        hand: hand,
                        sunken: [],
                        arenaPositions:[...plank].reverse(),
                        destroyedPositions:[],
                        destroyingPositions:[],
                    })
                }
            }
            
        })
        
    }
    const updatePlayer = (state) => {
        putPlayerState(id, state)
    }
    const recoverDeck = (callback) => {
        getPlayerState(id, (game, turn)=>{
            if (turn==='error'||game==='error') {
                return callback('error')
            }
            if (!turn?.State) {
                callback(false, false)
                return
            }
            callback(processDownloadState(turn.State), game.Ended)
            
        })
    }
    const [gameFunctions,] = useState(new GameFunctions({setState, setOpponentState, setSimulating, updatePlayer, removeGame:removeGameFunc,}))
    useEffect(()=>{
        const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
        if (username) {
            const oppUsername = JSON?.parse(myDecipherGameID(id))?.users?.filter(name=>name!==username)?.[0]
            if (oppUsername) {
                setOpponentUsername(oppUsername)
            }
        }
    },[id])
    useEffect(()=>{
        const interval = setInterval(()=>{
            if (!simulating){
                updateOpponent(simulating)
            }
            setUpdates(updates+1)
        },4000)
        return () => clearInterval(interval);
        // eslint-disable-next-line
    },[updates, simulating])
    useEffect(()=>{
        recoverDeck((playerState, ended)=>{
            if (ended) {
                setCanLeave(true)
            }
            if (playerState==='error') {
                return
            }
            if (playerState) {
                const newState = playerState
                setState({...newState,
                    inShop: false,})
            } else {
                setState({
                    simulating: false,
                    inShop: false,
                    turn: 0,
                    shells:0,
                    lastShuffle:-1,
                    viewing: false,
                    awaitingNextTurn: false,
                    sink:false,
                    deck: deck,
                    topCard:deck[0],
                    hand: hand,
                    sunken: [],
                    arenaPositions:[...plank],
                    destroyedPositions:[],
                    destroyingPositions:[],
                })
            }
        })
        // eslint-disable-next-line
    },[setState])
    useEffect(()=>{
        checkGameStillExists(id,e=>{
            if (e) {
                //continute to game
                if (state?.hand) {
                    const myState = {
                        ...state
                    }
                    delete myState.inShop
                    if (!simulating && !myState.simulating) { //dont push state when simulating
                        updatePlayer(myState)
                    }
                }
            } else{
                //wait for other player then uninitialize findgame if they dont show
                //window.location.href = '/'
            }
        })
        // eslint-disable-next-line
    },[state, setOpponentState])

    useEffect(()=>{
        let removedState = {}
        if (opponentState.draw) {
            removedState = {
                ...state,
                paused:true,
                lost:false,
                won:false,
                draw:true,
            }
        } 
        else if (opponentState.lost) {
            removedState = {
                ...state,
                paused:true,
                lost:false,
                won:true,
            }
        } 
        else if (opponentState.won) {
            removedState = {
                ...state,
                paused:true,
                lost:true,
                won:false,
                draw:false,
            }
        } 
        if (removedState?.arenaPositions) {
            setState(removedState)
            removeGameFunc(removedState)
        }
        // eslint-disable-next-line
    },[opponentState, setState])
    
    return (
        <div className='App' style={{height:'100vh'}}>{state?.hand?<Arena handleExit={()=>{
            const removedState = {
                ...state,
                paused:true,
                lost:true,
                won:false,
                draw:false,
            }
            setState(removedState)
            setTimeout(()=>{
                removeGame(id, removedState,e=>{
                    if (e) {
                        setCanLeave(true)
                    }
                })
            },1000)
        }} handleHome={()=>{window.location.href = `/reward?id=${id}`}} gameFunctions={gameFunctions} simulateCards={(state, opponentState)=>gameFunctions.simulateCards(state, opponentState)} canLeave={canLeave} opponentUsername={opponentUsername} currentCard={currentCard} setCurrentCard={setCurrentCard} id={id} simulating={simulating} setSimulating={(s)=>{setSimulating(s)}} updatePlayer={updatePlayer} opponentState={opponentState} setOpponentState={setOpponentState} state={state} setState={setState}/>:<></>}</div>
    )
}