import Wave from "react-wavify";

export default function Waves ({viewing}) {
    return (
        <div style={{overflowY:"hidden"}}>
            <Wave fill='#99aacc'
                paused={false}
                style={{ display: 'flex', height: '120vh',position: "relative", top: `${viewing?100*-.2:15}vh`, transition: "top 1s", overflowY:"hidden", zIndex: 0 }}
                options={{
                    amplitude: 20,
                    speed: 0.15,
                    points: 5
                }}
            />
            <div className="BOTTOMWAVE" style={{position:"absolute", bottom:0,width: "100vw", height: '10vh', backgroundColor:"#99aacc"}}></div>
        </div>
    
)
}