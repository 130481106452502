export default function Exit({opponentUsername, handleExit, handleExitPress, turn, exiting}) {
    const color = exiting?"#de5454":"#888888"
    return (
        <div>
            <div style={{position: "absolute", right:0, left:0, margin:"auto", top:'0vh', width:"40vw", height:'6vh'}}>
                <div style={{zIndex:exiting?600:"",position: "absolute", fontSize:'2vh',fontWeight:"800", width:"40vw",lineHeight: '6vh', height:'6vh', textAlign:"center"}}>Resign</div>
            </div>
            <div style={{position: "absolute", right:0, left:"40vw", margin:"auto", top:'6vh', width:"40vw", height:'6vh'}}>
                <div style={{position: "absolute", left:'5.5vh', fontSize:'2vh',fontWeight:"800", width:"40vw",lineHeight: '6vh', height:'6vh', textAlign:"left"}}>{`Turn: ${turn}`}</div>
            </div>
            <div style={{position: "absolute", right:"40vw", left:0, margin:"auto", top:'6vh', width:"40vw", height:'6vh'}}>
                <div style={{position: "absolute", right:'5.5vh', fontSize:'2vh',fontWeight:"800", width:"40vw",lineHeight: '6vh', height:'6vh', textAlign:"right"}}>{opponentUsername}</div>
            </div>
            <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.1/css/all.min.css'></link>
            <div className="exit" 
                style={{
                    animationName: exiting?"col":"", 
                    background: color,
                    zIndex: 525, 
                    width:'6vh', 
                    height:'6vh',
                    position:"absolute",
                    top:'6vh',
                    left:0,
                    right:0,
                    margin:"auto"
                }}
                onClick={()=>{
                    handleExitPress(!exiting)
                }}>
            {
            exiting?
            <div className="bell-exit" style={{border: `1px solid ${color} !important`, boxShadow: `2px 2px 5px ${color}, -2px -2px 5px ${color}`, borderRadius:"50%",width:'6vh',  height:'6vh'}}>
                
            </div>
                :
            <></>
            }
            <div style={{position:"relative",top:'1.5vh',width:0,height:0,left:'1.5vh',transformOrigin:"0 0",transform:"scale(.5)"}}><svg xmlns="http://www.w3.org/2000/svg" width={'6vh'}height={'6vh'}viewBox="0 0 24 24"><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M13 12v.01M3 21h18M5 21V5a2 2 0 0 1 2-2h7.5M17 13.5V21M14 7h7m-3-3l3 3l-3 3"/></svg></div>
            <i className="fas fa-exit btn-exit" style={{animationName: exiting?"exit-ring":""}}></i>
            </div>
            <div>
            <div style={{position:"absolute", top:0}}>
                    <div className="CardShopBackgroundOuter" style={{animation: exiting?"shopPop .5s linear calc(1)":"", display: !exiting?"none":""}}></div>
                    <div className="CardShopBackgroundInner" style={{animation: exiting?"shopPop .5s linear calc(1)":"", display: !exiting?"none":""}}></div>
                    <div className="CardShopBackgroundCards" style={{animation: exiting?"shopPop .5s linear calc(1)":"", display: !exiting?"none":""}}><button style={{position:"absolute", left:0, right:0, margin:"auto", top:'25vh'}} className="RedButton" onClick={()=>{handleExit()}}>Resign Game</button></div>
            </div>
            </div>
        </div>
    )
}