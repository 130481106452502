import { checkGameNotOver, createGame, getCurrentGames, opponentGameEnded, endGame } from "./GameTableFunctions";
import { addUserData } from "./UserDataTableFunctions";
import { myDecipherJWT, client, myCipherGameID, myDecipherGameID, getUser} from "./UserFunctions";

export const MATCHMAKING_TIMEOUT_SECONDS = 80

function sortTime(a,b) {
    if (a.TimeStamp < b.TimeStamp)
       return -1;
    if (a.TimeStamp > b.TimeStamp)
      return 1;
    return 0;
  }

export const initMatchMaking = (username, timestamp, callback) => {
    const userRequest = {
        Username: username,
        Pair: "init",
        Paired: false,
        TimeStamp: timestamp
    }
    const params = {
      TableName : 'DecksterousMatchMaking',
      Item: userRequest
    };
    client.put(params, async function(err, data) {
      if (err) {
        return console.log(err)
      }
      else {
          callback()
      }
  })
}

export const setPaired = (username, pair, initTimeString, paired, callback) => {
    const params = {
      TableName : 'DecksterousMatchMaking',
      Item: {
        Username: username,
        Pair: pair,
        Paired: paired,
        TimeStamp: initTimeString
      }
    };
    client.put(params, function(err, _) {
    if (err) console.log(err);
    else callback(true);
    });
  };
  
const getCandidates = (username, callback) => {
    const params = {
      TableName : 'DecksterousMatchMaking',
      AttributesToGet: ['Username', 'Pair', 'Paired', 'TimeStamp'],
    };
    
    client.scan(params, async function(err, data) {
        if (err) {
          return console.log(err)
        }
        else {
          const items = data?.Items
          if (!items) {
            callback([])
            return
          }
          callback(items.filter(item=>(item.Username!==username&&item.Pair==="init"&&(item.TimeStamp>(new Date().valueOf()-(MATCHMAKING_TIMEOUT_SECONDS*1000))))).sort(sortTime),
                   items.filter(item=>(item.Username!==username&&item.Pair===username&&item.TimeStamp)).sort(sortTime))
        }
    });
}

const checkAlreadyPaired = (user, callback) => {
    const params = {
      TableName : 'DecksterousMatchMaking',
      AttributesToGet: ['Username', 'Pair', 'Paired', 'TimeStamp'],
    };
    
    client.scan(params, async function(err, data) {
        if (err) {
            callback('')
            return console.log(err)
        }
        else {
            const items = data?.Items
            if (!items) {
            callback('',{})
            return
            }
            const myStatus = items.filter(item=>item.Username===user)?.[0]
            const oppStatus = items.filter(item=>item.Username===myStatus.Pair)?.[0]
            if (!oppStatus?.Pair) callback('',{})
            if (myStatus) {
                if ((myStatus.Pair !== "init")&&(myStatus.Pair !== "uninit")) {
                    
                    if (oppStatus?.Pair===user) {
                        callback('paired',oppStatus?.Username)
                    } else {
                        callback('',oppStatus?.Username)
                    }
                } else if (myStatus.Pair === "uninit") {
                  callback(["init","uninit"].includes(oppStatus?.Pair)?'':'meNotPaired',oppStatus?.Username)
                }
            } else {
                callback(["init","uninit"].includes(oppStatus?.Pair)?'':'meNotPaired',oppStatus?.Username)
            }
        }
    });
}
  
  export const checkMatchmaking = (callback) => {
    const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
    const params = {
      TableName : 'DecksterousMatchMaking',
      Key: {Username: username}
    };
    
    client.get(params, async function(err, data) {
      if (err) {
        return console.log(err)
      }
      else {
        const pair = data?.Item?.Pair
        const initTime = data?.Item?.TimeStamp
        if  (pair!=="init" && pair!=="uninit") {
          //get opponent Pair and TimeStamp
          getCandidates(username, (candidates, candidatesRequestingMe)=>{
              if (candidatesRequestingMe.length>0) {
                const opponent = candidatesRequestingMe.filter(item=>item.Username===pair)[0]
                if (opponent) {
                  const opponentTime = opponent.TimeStamp
                  
                  const gameID = myCipherGameID(JSON.stringify((opponentTime<initTime)?({users:[pair, username],TimeStamp:opponentTime}):({users:[username,pair], TimeStamp: initTime})))
                  checkGameNotOver(gameID, (e)=>{
                    if (e) window.location.href = `/game?id=${gameID}`
                  })
                  
                }
              } else {
                const userRequest = {
                  Username: username,
                  Pair: "uninit",
                }
                const params = {
                  TableName : 'DecksterousMatchMaking',
                  Item: userRequest
                };
                client.put(params, async function(err) {
                  if (err) {
                    return console.log(err)
                  }
                  }
                )
              }
          })
        }
        else {
          const userRequest = {
            Username: username,
            Pair: "uninit",
          }
          const params = {
            TableName : 'DecksterousMatchMaking',
            Item: userRequest
          };
          client.put(params, async function(err) {
            if (err) {
              return console.log(err)
            }
            }
          )
              
        }
      }
    }
    );
  }
  export const uninitMatchmaking = () => {
    const {username} = JSON.parse(myDecipherJWT(localStorage.getItem("JWT")))
    const userRequest = {
      Username: username,
      Pair: "uninit",
    }
    const params = {
      TableName : 'DecksterousMatchMaking',
      Item: userRequest
    };
    client.put(params, async function(err) {
      if (err) {
        return console.log(err)
      }
      window.location.href='/'
      }
    )
  }

  const NUM_PAIR_ATTEMPTS = 5
  
  export const findGame = (setStatus=()=>{}, player='', candidate={}, timesPaired=0, match={}, id=0, timeStamp=0, type='Game') => {
    if (player) {
      if (id) {
        setStatus("Game Found")
        if(type==='Game') {
          addUserData({games:[id]},()=>{
            window.location.href = `/game?id=${id}`
          })
        } else {
          addUserData({challenges:[id]},()=>{
            window.location.href = `/game?id=${id}`
          })
        }
      }
      
      else if (match?.Username) {
        
        setStatus("Match Found")
        createGame(player, timeStamp, match.Username, match.TimeStamp, gameID=>{
          findGame(setStatus, player, candidate, timesPaired, match, gameID, timeStamp, type)
        })
        
      } else {
        getCandidates(player, (candidates, candidatesRequestingMe)=>{
          const pairedWithMe = candidatesRequestingMe.map(item=>item.Username)
          if (pairedWithMe.length>0) {
            setStatus("Looking For Users")
            const matchPair = candidatesRequestingMe.filter(item=>item.Username===pairedWithMe.includes(candidate.Username)?candidate.Username:pairedWithMe[0])[0]
            setPaired(player, matchPair.Username, timeStamp, true, ()=>{
              findGame(setStatus, player, candidate, timesPaired, matchPair, id, timeStamp, type)
            })
            
          } else if (candidate?.Username) {
            setStatus("Requesting Pair")
            if (timesPaired <= NUM_PAIR_ATTEMPTS) {
              setTimeout(()=>{
              
                findGame(setStatus, player, candidate, timesPaired + 1, match, id, timeStamp, type)
                
              },timesPaired===0?0:500)
            } else {
            
              findGame(setStatus, player, {}, 0, match, id, timeStamp, type)
            }
          } else  if (candidates.length>0) {
            setStatus("Requesting Pair")
            setPaired(player, candidates[0].Username, timeStamp, false,()=>{
              findGame(setStatus, player, candidates[0], 0, match, id, timeStamp, type)
            })
            
            
          } else {
            setStatus("No Users Found")
            setTimeout(()=>{
              findGame(setStatus, player, candidate, 0, match, id, timeStamp, type)
            },3000)
          }
        })
      }
    } else {
      
      getUser(gotPlayer=>{ //callback:: error: '', noPlayer:'', player:gotPlayer
      
        if (!gotPlayer) window.location.href='/'
        setStatus("User Found")
  
        getCurrentGames(gotPlayer, gameID=>{ //callback:: error: 0, noGames:0, game:gameID
          setStatus("Looking For Existing Games")
        
          checkAlreadyPaired(gotPlayer, status=>{ //callback:: error: '', justMeNotPaired:'meNotPaired', opponent/both NotPaired:'', paired:pair
            opponentGameEnded(gameID, gotPlayer, (opponentEnded,ended)=>{
              if (gameID && (status==='meNotPaired')&&(!opponentEnded)&&(!ended)) {
                const gameObj = JSON.parse(myDecipherGameID(gameID))
                const pair = gameObj.users.filter(item=>item!==gotPlayer)[0]
                setPaired(gotPlayer, pair, gameObj.users[0]===gotPlayer?gameObj.TimeStamp:gameObj.TimeStamp+200, true, success=>{
                  findGame(setStatus, '', candidate, timesPaired, match, id, timeStamp, type)
                })
                
              } else if ((gameID && !status) || (opponentEnded && !ended)) {
                endGame(gotPlayer, gameID,()=>{
                  findGame(setStatus, '', candidate, timesPaired, match, id, timeStamp, type)
                })
              } else {
                setStatus("Initializing Matchmaking")
                initMatchMaking(gotPlayer,timeStamp, ()=>{
                  findGame(setStatus, gotPlayer, candidate, timesPaired, match, id, timeStamp, type)
                })
              }
            })
          })
        })
      })
      
    }
  }
  