import inspect from './inspect.gif'
import place from './place.gif'
import sim from './sim.gif'
import sell from './sell.gif'
import shop from './shop.gif'
import level from './level.gif'
import attack from './attack.gif'
import sink from './sink.gif'
import capacity from './capacity.gif'
import { allCards, getWeight } from '../Card/Cards'
import Card from '../../Components/Game/Card'
import BigCard from '../../Components/Game/BigCard'

export const tutorialMessages = (state, setFrog,hasInspectedCard) => {
    return {
        0:["Welcome To The Sea of Decksterity Tutorial",
        <>
        <div>{"Sea of Decksterity is a card game played on two opposing planks. The goal: destroy your opponents plank!"}</div>
        <ul style={{textAlign:"left",color:hasInspectedCard?.length>=2?"rgb(32, 219, 155)":""}}><li>{"Inspect both cards in your hand."}</li></ul>
        </>],
        1:["Place Both Cards",
        <>
        <div>{"Place both of your cards on your plank such that the ladybug reaches 0 weight."}</div>
        <ul style={{textAlign:"left"}}>
            <li style={{color:(state?.arenaPositions?.flat(1)?.length>=2)?"rgb(32, 219, 155)":""}}>
                {`Placed cards: ${state?.arenaPositions?.flat(1).length}/2`}
            </li>
            <li style={{color:(getWeight([[...state?.arenaPositions?.flat(1)??[],...state?.hand??[],...state?.deck??[]]?.filter(item=>item.title==="Ladybug")[0]??allCards()["Ladybug"]()])??2)===0?"rgb(32, 219, 155)":""}}>
                {`Ladybug weight: ${getWeight([[...state?.arenaPositions?.flat(1)??[],...state?.hand??[],...state?.deck??[]]?.filter(item=>item.title==="Ladybug")[0]??allCards()["Ladybug"]()])??2}/0`}
            </li>
        </ul>
        </>],
        2:["Request Turn And Simulate",
        <>
        <div>{"Use the bell to request the next turn. Once both players have requested, the plank will simulate."}</div>
        <ul style={{textAlign:"left"}}>
            <li style={{color:(state?.turn??0)>0?"rgb(32, 219, 155)":""}}>
            {`Turn: ${state?.turn??0}/1`}
            </li>
        </ul>
        </>],
        3:["Selling And Buying Cards",<><div>{"Cards can be traded for shells by clicking when they are on your plank."}</div><ol style={{textAlign:"left"}}><li style={{color:(((state.tutorialPhase===6) || (state.tutorialPhase===7)) && ((state.shells>=1)||((state.hand?.filter(item=>item.title==="Beehive")?.length>0) || (state.deck?.filter(item=>item.title==="Beehive").length>0) || (state.arenaPositions?.flat(1)?.filter(item=>item.title==="Beehive")?.length>0))))?"rgb(32, 219, 155)":""}}>{"Trade the Bee for 1 shell by clicking on it."}</li><li style={{color:((state.hand?.filter(item=>item.title==="Beehive")?.length>0) || (state.deck?.filter(item=>item.title==="Beehive").length>0) || (state.arenaPositions?.flat(1)?.filter(item=>item.title==="Beehive")?.length>0))?"rgb(32, 219, 155)":""}}>{"Buy a Beehive from the shop in the top left corner."}</li><li style={{color:(((state.arenaPositions?.flat(1)?.filter(item=>item.title==="Beehive")?.length>0))&&([...state?.arenaPositions?.flat(1)??[],...state?.hand??[],...state?.deck??[]]?.filter(item=>item.title==="Bee")?.length>0))?"rgb(32, 219, 155)":""}}>{"Place the Beehive on your plank, then press the bell to simulate."}</li></ol></>],
        4:["Upgrading Cards","Now you have some Bee cards! You can upgrade most cards by stacking them on your plank. Place two Bees on the same spot to get a level II Bee. Or simulate a level II Beehive"],
        5:["Attacking","The attack value on a card shows the damage a card will deal. Simulate and attack Your opponents plank."],
        6:["Defending",<><div>{"Nice Job! Let's see if you can defend against this Frog. Remember, you can inspect cards."}</div><ul style={{textAlign:"left"}}><li style={{color:((state?.destroyedPositions?.length===0&&(state.tutorialPhase===13?state.turn:0)>=1))?"rgb(32, 219, 155)":""}}>{`Defend your plank for one simulation. ${state.tutorialPhase===13?state.turn:0}/1`}</li></ul><div style={{height:"15vh"}}></div><Card setCurrentCard={()=>{setFrog(true)}} {...allCards()["Frog"]()}/></>],
        7:["Plank Capacity",<><div>{"Now for a more difficult position. Each card has a weight property. If you have too much weight on your plank it will sink."}</div><ul style={{textAlign:"left"}}><li style={{color:((state?.destroyedPositions?.length===0&&(state.tutorialPhase===15?state.turn:0)>=2))?"rgb(32, 219, 155)":""}}>{`Defend your plank for two simulations. ${state.tutorialPhase===15?state.turn:0}/2`}</li></ul></>],
        8:["Tutoial Completed",<><div>{"Well done. You are ready for a game! Press next to return home."}</div><div style={{height:"2vh"}}></div><BigCard card={allCards()["Balloons"]()}/></>]
    }
}
const imageStyle = (width) => { return {width:"auto", height:"auto", maxWidth:`${width}vh`, borderRadius:"2vh", position:"relative", left:0, right:0, margin:"auto"}}
export const tutorialGifs = {
    0:()=><>
            <img style={imageStyle(20)} src={inspect} alt="inspect" />
        </>,
    1:()=><>
            <img style={imageStyle(20)} src={place} alt="place" />
        </>,
    2:()=><>
            <img style={imageStyle(12.5)} src={sim} alt="sim" />
        </>,
    3:()=><>
            <img style={imageStyle(20)} src={sell} alt="sell" />
            <img style={imageStyle(12.5)} src={shop} alt="shop" />
        </>,
    4:()=><>
            <img style={imageStyle(20)} src={level} alt="level" />
        </>,
    5:()=><>
            <img style={imageStyle(20)} src={attack} alt="attack" />
        </>,
    6:()=><>
    </>,
    7:()=><>
        <img style={imageStyle(65)} src={sink} alt="sink" />
        <img style={imageStyle(12.5)} src={capacity} alt="capacity" />
    </>,
}
