import { tutorialGifs, tutorialMessages } from "../../Data/Tutorial/TutorialData"

export default function TutorialCard({state, setFrog, open, nextPhase, phase, simulating, restartPhase, reset, lastPhase,hasInspectedCard}) {
    return (
        <div>
            {
                true?
                <div style={{position:"absolute", top:0, width:"100vw", height:0}}>
                <div className="CardShopBackgroundOuter" style={{zIndex:560,animation: open?"shopPop .5s linear calc(1)":"", display: (!open||simulating)?"none":""}}></div>
                <div className="CardShopBackgroundCards" style={{zIndex:560,transform:"translateY(-11.5vh)", left:0, right:0, margin:"auto", width:"70vh", backgroundColor:"#ffffff",borderRadius:"5vh", transition:"height .5s, transform .5s",height:(state.inShop||(simulating&& state.tutorialPhase >=5))?0:(simulating?"11.6vh":(open?"85vh":"37vh")),animation: !(!open||simulating)?"shopPop .5s linear calc(1)":""}}>
                    {
                        !simulating?
                        <>
                        
                        <>
                        <div style={{width:"60vh", fontSize:"2vh", fontWeight:800,paddingTop:"13vh", margin:"0 auto"}}>
                            {tutorialMessages(state,setFrog,hasInspectedCard)?.[Math.floor(phase/2)]?.[0]}
                        </div>
                        <div style={{width:"60vh", fontSize:"2vh",paddingTop:"2vh", margin:"0 auto"}}>
                            {tutorialMessages(state,setFrog,hasInspectedCard)?.[Math.floor(phase/2)]?.[1]}
                        </div>
                        {
                            open?
                            <div style={{paddingTop:"5vh", gap:"5vh", display:"grid", justifyContent: "center", alignItems:"center" }}>
                                {tutorialGifs?.[Math.floor(phase/2)]?tutorialGifs?.[Math.floor(phase/2)]():""}
                            </div>
                            :
                            <></>
                        }
                        </>
                    <div style={{position:"absolute",display:"flex",left:0, right:0, margin:"auto", top:'5vh', width:"45vh"}}>
                        {
                            (phase)&&(phase>0)?
                            <>
                            <button style={{position:"relative", width:"15vh", top:"0vh", display: !open||simulating?"none":""}} className="CreateAccountButtonSmall" onClick={lastPhase}>Back</button>
                            <div style={{width:"15vh"}}></div>
                            </>
                            :
                            <>
                            <div style={{width:"15vh"}}></div>
                            </>
                        }
                        <button style={{position:"relative", width:"15vh", top:"0vh", display: !open||simulating?"none":""}} className="SignInButtonSmall" onClick={()=>{
                            if (Math.floor(phase/2)===(Object.keys(tutorialMessages(state)).length-1)) {
                                reset()
                                window.location.href = '/'
                            } else {
                                nextPhase()
                            }
                        }}>Next</button>
                    </div>
                    <div style={{position:"absolute",display:"flex",left:0, right:0, margin:"auto", width:"45vh", top:'5vh'}}>
                        <button style={{position:"relative", width:"15vh", top:"0vh", display: !(!open||simulating)?"none":""}} className="CreateAccountButtonSmall" onClick={restartPhase}>Back</button>
                        <div style={{width:"15vh"}}>
                        </div>
                        <button style={{position:"relative", width:"15vh", top:"0vh", display: !(!open||simulating)?"none":""}} disabled={state.topPhase<=state.tutorialPhase} className="SignInButtonSmall" onClick={nextPhase}>Next</button>
                        
                    </div>
                        </>
                        :
                        <>
                        {
                            (!state.inShop && state.tutorialPhase < 5)?
                            <>
                                <button style={{position:"relative", width:"15vh", top:"3vh", display: !(!open||simulating)?"none":""}} className="CreateAccountButtonSmall" onClick={restartPhase}>Back</button>
                            </>
                            :
                            <></>
                        }
                        </>
                    }
                    
                </div>
                
        </div>
        :
        <></>
            }
        </div>
    )
}