import { useEffect, useState } from "react"
import '../../Styles/Tabs.css'
import { myCipherEventID, myDecipherEventID, myDecipherJWT } from "../../TableFunctions/UserFunctions"
import { calculateTrophies } from "../../TableFunctions/UserDataTableFunctions"
import { calculateCoins } from "../../TableFunctions/EventTableFunctions"

export default function Tabs ({tabs, userData}) {
    const [trophies,setTrophies] = useState(userData?.Trophies)
    const [coins, setCoins] = useState(JSON.parse(myDecipherEventID(localStorage.getItem("Coins")??"")??JSON.stringify({coins:userData?.coins??0}))?.coins)
    const [index, setIndex] = useState(1)
    const JWT = localStorage.getItem("JWT")
    const {username} = JWT?JSON.parse(myDecipherJWT(JWT)):""
    useEffect(()=>{
        if (userData) {
            calculateTrophies(userData,setTrophies)
            calculateCoins(coins=>{
                localStorage.setItem("Coins",myCipherEventID(JSON.stringify({coins:coins})))
                setCoins(coins)
            })
        }
    },[userData])
    return (
       <>{
        userData?
         <div className="Tabs">
            <div className="TabBar">
                <div className="TabButtons">
                    {
                        tabs?.map((tab,idx)=><div key={idx} className="Tab"><button className={`TabButton${tab.id===index? " TabSelected":""}`} onClick={()=>{setIndex(tab.id); if (typeof(tab.onTabSelected)==='function') {tab.onTabSelected()}}}>{tab.icon()}<div style={{fontSize:"1rem"}}>{tab.title}</div></button></div>)
                    }
                </div>
            </div>
            <div style={{position:"absolute",top:"-10vh",fontSize:"2rem", backgroundColor:"#ffffffaa", fontWeight:"bold", display:"flex", justifyContent:"space-around", left:0, right:0, margin:"auto",gap:"2vw",outline:".5vh solid #1f5171",borderRadius:"1vw",padding:".5vw",lineHeight:"2.5rem", height:"2.5rem", width:"70vw"}}>
                                <div><i className="fa fa-trophy"></i>:{trophies?trophies:0}</div>
                                <div>{(username?.length>10)?`${username?.slice(0,10)}...`:username}</div>
                                <div><i className="fa fa-diamond"></i>:{coins?coins:0}</div>
                            </div>
            <div className="TabContainer">
                {
                    tabs?.filter(tab=>tab.id===index)?.map((tab,idx)=>{
                        return <div key={idx}>{tab.content()}</div>
                    })
                }
            </div> 
            
        </div>
        :
        <></>
       }</>
    )
}