import { useEffect, useState } from "react";
import Waves from "../Components/Main/Waves";
import { MATCHMAKING_TIMEOUT_SECONDS, findGame, uninitMatchmaking } from "../TableFunctions/MatchmakingFunctions";


export default function FindGame () {
    const [initTime, ] = useState(new Date().valueOf())
    const [status, setStatus] = useState("Finding A Match")
    
    useEffect(()=>{
        setTimeout(()=>{
            setStatus("Matchmaking Timeout")
            localStorage.removeItem("findgame")
            window.location.href = '/'
        },MATCHMAKING_TIMEOUT_SECONDS*1000)
        findGame(setStatus,'',{},0,{},0,initTime)
    },[initTime])
    return (
        <div className="App" style={{height:'80vh'}}>
            <Waves viewing={false}/>
            <div style={{position:"absolute", left:0, right:0, margin:"auto", fontSize:"5vh !important", top:'30vh'}} className='SignInHeader'>{status}</div>
            <div style={{position:"absolute", left:0, right:0, margin:"auto", fontSize:"5vh !important", top:'50vh'}} className='SignInHeader'>
                <div className="loadingSpin" style={{borderRadius:"50%",position:"absolute", left:0, right:0, margin:"auto",width:'8vh', height:'8vh', backgroundImage:`conic-gradient(#ffffff00 75%, #1f5171 25%)` }}></div>
                <div style={{borderRadius:"50%",position:"absolute", left:0, right:0, top:'1.5vh', margin:"auto",width:'5vh', height:'5vh', backgroundColor:"rgb(153, 170, 204)" }}></div>
                <button style={{position:"absolute", left:0, right:0, margin:"auto", top:'18vh'}} className="RedButtonSmall" onClick={()=>{uninitMatchmaking()}}>Cancel</button>
            </div>
        </div>
    )
}