import Waves from '../Components/Main/Waves.js';
import '../Styles/SignIn.css'
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { signedIn } from '../TableFunctions/UserFunctions.js';
export default function SignInPage ({shortScreen, isLoggedIn, setIsLoggedIn}) {
    const [searchParams] = useSearchParams();
    const error = searchParams.get("error")

    const usernameRef = useRef();
    const passwordRef = useRef();
    return (
        <div className='App' style={{height:'100vh'}}>
            <Waves viewing={false}/>
            <div style={{position:"absolute", width:"100vw", height:'100vh', top:0}}>
            <div style={{height:'25vh', fontSize: '5vh'}}>
                        <div style={{position:"relative", top:'5vh'}} className='SignInHeader'>Sea of Decksterity</div>
                    </div>
                    {isLoggedIn?
                    <div style={{position:"relative", width:"80vw", height:'60vh', top:'0vh',left:"10vw", borderRadius:'5vh', backgroundColor:"#ffffffaa"}}>
                        <div style={{position:'absolute',left:0,right:0,margin:'auto', top:'5vh', fontSize: '4vh', fontWeight:"bold"}}>Already Signed In</div>
                        <br/>
                        <br/>
                        <br/>
                        <button className='SignInButtonSmall' onClick={()=>{
                                window.location.href = '/'
                            }}>Home</button>
                    </div>
                    :
                    <div style={{position:"relative", width:"80vw", height:'60vh', top:'0vh',left:"10vw", borderRadius:'5vh', backgroundColor:"#ffffffaa"}}>
                        <form autoComplete='on' onSubmit={e=>{
                            e.preventDefault();
                            const passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}.*$/
                            if (passwordRef.current.value.match(passwordCheck) && usernameRef.current.value.length > 3) {
                                signedIn(usernameRef.current.value.toLowerCase(),passwordRef.current.value,setIsLoggedIn, (e)=>{
                                    if (e===1) {
                                        window.location.href = '/'
                                    }
                                    if (e===0) {
                                        window.location.href = '/signin?error=0'
                                    }
                                    if (e===-1) {
                                        window.location.href = '/signin?error=1'
                                    }
                                })}
                            }}>
                            <div style={{position:'relative', top:'15vh', left:"-5vw", fontSize: '2vh', fontWeight:"bold"}}>Username:</div>
                            <div><input
                                className='Username'
                                type="username"
                                placeholder="Enter your username"
                                ref={usernameRef}
                            /></div>
                            <div style={{position:'relative', top:'15vh', left:"-5vw", fontSize: '2vh', fontWeight:"bold"}}>Password:</div>
                            <div><input
                                className='Password'
                                type="password"
                                placeholder="Enter your password"
                                ref={passwordRef}
                            /></div>
                            <button className='SignInButtonSmall'>Sign In</button>
                            
                            {
                                error?
                                <div style={{position:'relative',top:'25vh'}}>
                                    {
                                        error==='0'?
                                        <div>Sign in Failed.</div>
                                        :
                                        <>{error==='1'?<div>Password Failed</div>:<div>Creating Account, May Take a Moment...</div>}</>
                                    }
                                </div>
                                :
                                <></>
                            }
                        </form>
                </div>
                }
            </div>
            
        </div>
        
    )
}