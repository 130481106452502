import { getGameStatuses } from "./GameTableFunctions";
import { client, getUser, myCipherGameID, myDecipherGameID } from "./UserFunctions";

export const winTrophies = (trophies) =>{
    let base = 30
    if (trophies[0]<300) {
        base = 30+(10-Math.ceil(trophies[0]/30))
    }
    let difference = trophies[1] - trophies[0]
    return base+Math.ceil(difference/50)
}
export const lossTrophies = (trophies) => {
    let base = -30
    if (trophies[0]<300) {
        base =  -Math.floor(trophies[0]/12)
    }
    let difference = trophies[1] - trophies[0]
    return base+Math.ceil(difference/50)
}
export const drawTrophies = (trophies) => {
    let base = 0
    if (trophies[0]<300) {
        base = 10-Math.ceil(trophies[0]/30)
    }
    let difference = trophies[1] - trophies[0]
    return base + Math.ceil(difference/50)
}

export const getTrophies = (callback) => {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const params = {
                TableName : 'DecksterousUserData',
                Key: {
                    UserID: userID
                }
            };

            client.get(params, async function(err, data) {
                if (err) {
                    return console.log(err)
                }
                else {
                    const userData = data?.Item
                    if  (userData) {
                        callback(userData.Trophies??0)
                        return
                    }
                    else {
                        callback(0)
                        return
                    }
                }
            });
        }
    })
}
export const calculateTrophies = (userData,callback) => {
    if (userData?.Games?.length>0) {
        getGameStatuses(userData.Games,trophies=>{
            callback(trophies)
        })
    } else {
        callback(0)
    }
    
}

export function getUserData(callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const params = {
                TableName : 'DecksterousUserData',
                Key: {
                    UserID: userID
                }
            };

            client.get(params, async function(err, data) {
                if (err) {
                    callback("error")
                return console.log(err)
                }
                else {
                    const userData = data?.Item
                    if  (userData) {
                        calculateTrophies(userData,trophies=>{
                            callback({...userData, Trophies:trophies??0})
                        })
                        
                    }
                    else {
                        initUserData()
                    }
                }
            });
        }
    })
}
export function addUserData({coins=0,friends=[],hand=null,removingFriends=[],declinedFriends=[],games=[],challenges=[]},callback=null,callFriends=true) {
    getUser(username=>{
        if (username) {
            getUserData(userData=>{
                if (userData?.UserID) {
                    calculateTrophies(userData, trophies=>{
                        const newChallenges = Array.from(new Set([...userData?.Challenges??[],...challenges]))
                        const newGames = Array.from(new Set([...userData?.Games??[],...games]))
                        const newFriends = Array.from(new Set([...userData?.FriendsData?.Friends??[],...friends??[]])).filter(f=>!removingFriends.includes(f))
                        const newDeclinedFriends = Array.from(new Set([...userData?.FriendsData?.DeclinedFriends??[],...declinedFriends??[]])).filter(f=>!((newFriends??[]).includes(f)))
                        const userID = myCipherGameID(JSON.stringify({user:username}))
                        const userDataRequest = {
                        UserID: userID,
                        Coins: userData.Coins+coins,
                        Trophies: trophies??0,
                        FriendsData: {
                            Friends:newFriends??[],
                            DeclinedFriends:newDeclinedFriends??[]
                        },
                        Hand:hand?hand:userData.Hand,
                        BattleStatus:userData.BattleStatus??false,
                        Games:newGames??[],
                        Challenges:newChallenges??[]
                        }
                        const params = {
                        TableName : 'DecksterousUserData',
                        Item: userDataRequest
                        };
                        client.put(params, async function(err) {
                            if (err) {
                                return console.log(err)
                            } else {
                                if (typeof(callback) === 'function') {
                                    if (callFriends) {
                                        getFriends(friends=>{
                                            callback(friends)
                                        })
                                    } else {
                                        callback(true)
                                    }
                                }
                            }
                        })
                    })
                } else {
                    console.log("error")
                }
            })
        }
    })
}
export function initUserData() {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const userDataRequest = {
            UserID: userID,
            Coins: 0,
            Trophies: 0,
            hand:["ladybug","floatie"],
            FriendsData:{
                Friends:[],
                DeclinedFriends:[]
            },
            BattleStatus:false,
            Games:[],
            Challenges:[],
            Events:[]
            }
            const params = {
            TableName : 'DecksterousUserData',
            Item: userDataRequest
            };
            client.put(params, async function(err) {
                if (err) {
                    return console.log(err)
                }
            })
        } else {
            console.log("error")
        }
    })
}

export function getFriends(callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username}))
            const params = {
                TableName : 'DecksterousUserData',
                Key: {
                    UserID: userID
                }
            };

            client.get(params, async function(err, data) {
                if (err) {
                    callback("error")
                return console.log(err)
                }
                else {
                    const myPending = data?.Item?.FriendsData?.Friends??[]
                    const myDeclined = data?.Item?.FriendsData?.DeclinedFriends??[]
                    const params = {
                        TableName : 'DecksterousUserData',
                        AttributesToGet: ['UserID', 'FriendsData','Trophies'],
                      };
                      
                      client.scan(params, async function(err, data) {
                          if (err) {
                            return console.log(err)
                          }
                          else {
                            const nameToData = (name) => {
                                const user = data?.Items?.filter(item=>item.UserID===myCipherGameID(JSON.stringify({user:name})))?.[0]
                                return {name:name,trophies:user?.Trophies??0}
                            }
                            const dataToName = (data) => {
                                const name = JSON.parse(myDecipherGameID(data.UserID))?.user
                                return name
                            }
                            const requestingMeSet = new Set(data?.Items?.filter(userData=>userData?.FriendsData?.Friends?.includes(username)).map(dataToName))
                            const decliningMeSet = new Set(data?.Items?.filter(userData=>userData?.FriendsData?.DeclinedFriends?.includes(username)).map(dataToName))
                            const myDeclinedSet = new Set(myDeclined)
                            const myPendingSet = new Set(myPending).difference(myDeclinedSet)
                            const myFriendsSet = myPendingSet.intersection(requestingMeSet)
                            
                            const newFriendsList = Array.from(myFriendsSet).map(nameToData)
                            const newRequestList = Array.from(requestingMeSet.difference(myDeclinedSet).difference(myFriendsSet)).map(nameToData)
                            const newPendingList = Array.from(myPendingSet.difference(decliningMeSet).difference(myFriendsSet)).map(nameToData)
                            callback({friends:newFriendsList,requesting:newRequestList,pending:newPendingList})
                          }
                      });
                }
            });
        } else {
            callback({friends:[],requesting:[],pending:[]})
        }
    })
}