import { levelStates, opponentLevelStates } from "../Data/Play/LevelData";
import { processDownloadState, processUploadState } from "./GameTableFunctions";
import { client, getUser, myCipherGameID } from "./UserFunctions";

export function getCampaignLevel(level, callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username,level:level}))
            const params = {
                TableName : 'DecksterousCampaign',
                Key: {
                    UserID: userID,
                    Level:level
                }
            };

            client.get(params, async function(err, data) {
                if (err) {
                    callback("error")
                return console.log(err)
                }
                else {
                    const state = data?.Item?.State
                    const opponentState = data?.Item?.OpponentState
                    if  (state&&opponentState) {
                        callback(processDownloadState(state),processDownloadState(opponentState))
                    }
                    else {
                        callback(false)
                    }
                }
            });
        }
    })
}

export function removeLevel(level,callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username,level:level}))
            const campaignRequest = {
            UserID: userID,
            Level: level,
            State: processUploadState(levelStates[level]),
            OpponentState:processUploadState(opponentLevelStates[level])
            }
            const params = {
            TableName : 'DecksterousCampaign',
            Item: campaignRequest
            };
            client.put(params, async function(err) {
                if (err) {
                    return console.log(err)
                } else {
                    callback(true)
                }
            })
        }
    })
}

export function putCampaignLevel(level, state, opponentState) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username,level:level}))
            const campaignRequest = {
            UserID: userID,
            Level: level,
            State: processUploadState(state),
            OpponentState:processUploadState(opponentState)
            }
            const params = {
            TableName : 'DecksterousCampaign',
            Item: campaignRequest
            };
            client.put(params, async function(err) {
                if (err) {
                    return console.log(err)
                }
                if (state.won) {
                    getTopLevel(topLev=>{
                        if (topLev!=="error") {
                            putTopLevel(Math.max(parseInt(level)+1,topLev))
                        }
                    })
                }
                
            })
        }
    })
}

export function getTopLevel(callback) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username,level:0}))
            const params = {
                TableName : 'DecksterousCampaign',
                Key: {
                    UserID: userID,
                    Level:"0"
                }
            };

            client.get(params, async function(err, data) {
                if (err) {
                    callback("error")
                return console.log(err)
                }
                else {
                    const topLevel = data?.Item?.TopLevel
                    if  (topLevel) {
                        callback(topLevel)
                    }
                    else {
                        putTopLevel(1,()=>callback(1))
                        
                    }
                }
            });
        }
    })
}

export function putTopLevel(level,callback=()=>{}) {
    getUser(username=>{
        if (username) {
            const userID = myCipherGameID(JSON.stringify({user:username,level:0}))
            const campaignRequest = {
            UserID: userID,
            Level: "0",
            TopLevel: level
            }
            const params = {
            TableName : 'DecksterousCampaign',
            Item: campaignRequest
            };
            client.put(params, async function(err) {
                if (err) {
                    return console.log(err)
                } else {
                    callback(level)
                }
            })
        }
    })
}