import { range } from "../../Components/Game/ArenaHelperFunctions";
import { getAttack, getProduction, getWeight } from "../../Data/Card/Cards";
import { cardMapToRep,repMapToCards } from "../../TableFunctions/GameTableFunctions";


export class GameFunctions {
    constructor(props) {
        this.props = props
        this.getPlankCapacity = this.getPlankCapacity.bind(this);
        this.getOppPlankCapacity = this.getOppPlankCapacity.bind(this);
        this.calculateWin = this.calculateWin.bind(this);
        this.calculateLoss = this.calculateLoss.bind(this);
        this.calculateDraw = this.calculateDraw.bind(this);
        this.cardAttack = this.cardAttack.bind(this);
        this.enhance = this.enhance.bind(this);
        this.handleSink = this.handleSink.bind(this);
        this.whenPlayed = this.whenPlayed.bind(this);
        this.removeDeadCards = this.removeDeadCards.bind(this);
        this.removeDeadCardAttibutes = this.removeDeadCardAttibutes.bind(this);
        this.attack = this.attack.bind(this);
        this.simulateCards = this.simulateCards.bind(this);
    }
    
    getPlankCapacity = (state) => {
        return 8-(state.destroyedPositions?.length??0)
    }
    getOppPlankCapacity = (opponentState) => {
        return 8-(opponentState.destroyedPositions?.length??0)
    }
    calculateWin = (state, opponentState) => {
        const combinedDestroyed = Array.from(new Set([...state?.destroyedPositions, ...opponentState?.destroyedPositions.map(num=>7-num)]))
        const noOpposingPositions = combinedDestroyed.length===8
        const notEnoughSpace = (state?.destroyedPositions?.length >=7)
        const opponentNotEnoughSpace = (opponentState?.destroyedPositions?.length >=7)
        return (opponentNotEnoughSpace && !notEnoughSpace) || (noOpposingPositions&&(state?.destroyedPositions.length<opponentState?.destroyedPositions.length))
    }
    calculateLoss = (state, opponentState) => {
        const combinedDestroyed = Array.from(new Set([...state?.destroyedPositions, ...opponentState?.destroyedPositions.map(num=>7-num)]))
        const noOpposingPositions = combinedDestroyed.length===8
        const notEnoughSpace = (state?.destroyedPositions?.length >=7)
        const opponentNotEnoughSpace = (opponentState?.destroyedPositions?.length >=7)
        return (!opponentNotEnoughSpace && notEnoughSpace) || (noOpposingPositions&&(state?.destroyedPositions.length>opponentState?.destroyedPositions.length))
    }
    calculateDraw = (state, opponentState) => {
        const combinedDestroyed = Array.from(new Set([...state?.destroyedPositions, ...opponentState?.destroyedPositions.map(num=>7-num)]))
        const noOpposingPositions = combinedDestroyed.length===8
        const notEnoughSpace = (state?.destroyedPositions?.length >=7)
        const opponentNotEnoughSpace = (opponentState?.destroyedPositions?.length >=7)
        return (opponentNotEnoughSpace && notEnoughSpace) || (noOpposingPositions&&(state?.destroyedPositions.length===opponentState?.destroyedPositions.length))
    }
    cardAttack(damage,index,otherTeamCards, destroyedPositions) {
        let destroyed = [...destroyedPositions]
        const damagedCards = otherTeamCards.map((itemContainer, itemIndex)=>{
            let item = itemContainer[0]
            if (!item) {
                if ((itemIndex === index) && (damage>0)) {
                    destroyed.push(index)
                }
                return []
            }
            if (itemIndex === index) {
                return [{...item, health: damage===Number.MAX_SAFE_INTEGER?0:((item.health===Number.MAX_SAFE_INTEGER)?(Number.MAX_SAFE_INTEGER):(item.health-damage))}]
            }
            return [{...item}]
        })
        return [damagedCards, destroyed]
    }
    enhance(cards) {
        let newPlayerCards = [...cards]
        //enhance spells
        cards.forEach((cardContainer,index)=>{
            let card = cardContainer[0]
            if (!card) return
            if (card?.enhance) {
                const d = card?.enhance([...newPlayerCards], index, getProduction([card]), card.title+card.position)
                newPlayerCards = [...d]
            }
        })
        return newPlayerCards
    }
    handleSink(state, opponentState, ignore=false) {
        const newState = {
            arenaPositions:[[],[],[],[],[],[],[],[]], 
            opponentCards: [[],[],[],[],[],[],[],[]],
            deck: [...state.deck,...state.arenaPositions.map(item=>{
                const heavy = item?.[0]?.heavy??false
                return heavy?[]:item
            }).flat(1).map((item,idx)=>{return{...cardMapToRep(item,idx),level:item.level}}).map(item=>{return{...repMapToCards(item,false),level:item.level}})], 
            sink: false, 
            sunken: state.arenaPositions.filter(item=>item.length!==0).flat(1), 
            opponentSunken: opponentState.arenaPositions.filter(item=>item.length!==0).flat(1),
            shells:state.shells+state.arenaPositions.map(item=>{
                const heavy = item?.[0]?.heavy??false
                return heavy?item[0].worth:0
            }).reduce((partialSum, a) => partialSum + a, 0)
        }
            if (!ignore) {
                this.props.setState(s=>{
                    const newState = {
                        arenaPositions:[[],[],[],[],[],[],[],[]], 
                        opponentCards: [[],[],[],[],[],[],[],[]],
                        deck: [...s.deck,...s.arenaPositions.map(item=>{
                            const heavy = item?.[0]?.heavy??false
                            return heavy?[]:item
                        }).flat(1).map((item,idx)=>{return{...cardMapToRep(item,idx),level:item.level}}).map(item=>{return{...repMapToCards(item,false),level:item.level}})], 
                        sink: false, 
                        sunken: s.arenaPositions.filter(item=>item.length!==0).flat(1), 
                        opponentSunken: opponentState.arenaPositions.filter(item=>item.length!==0).flat(1),
                        shells:s.shells+s.arenaPositions.map(item=>{
                            const heavy = item?.[0]?.heavy??false
                            return heavy?item[0].worth:0
                        }).reduce((partialSum, a) => partialSum + a, 0)
                    }
                    return {...s, ...newState}
                })
            }
            return {...state, ...newState}
        }

    whenPlayed = (state,playerCards, nextShells) => {
        //whenPlayed
        let newPlayerCards = [...playerCards]
        let newDeck = [...state.deck]
        playerCards.forEach((cardContainer, index)=>{
            let card = cardContainer[0]
            if (!card) return
            if (card?.whenPlayed) {
                const prod = getProduction([card])
                range(prod).forEach(i=>
                    {
                        const data = card.whenPlayed(newDeck,card.level,newPlayerCards, index, card.turnsLeft)
                        if (data[2]) nextShells+=data[2]
                        newPlayerCards = [...data[1]]
                        newDeck = [...data[0]]
                    }
                )
            }
        })
        return {cards:newPlayerCards, deck:newDeck, shells:nextShells}
    }

    removeDeadCards = (playerCards, opponentCards,sunken,opponentSunken) => {
        return {playerCards:playerCards.map(item=>{
            if (item[0]?.turnsLeft >= 0) {
                if (item[0].turnsLeft !== Number.MAX_SAFE_INTEGER) {
                    item[0].turnsLeft -= 1
                }
            }
            if (item[0]) if (item[0].health<= 0) {
                //trigger death animation
                sunken = [...sunken, item[0]]
                return [{...item[0], cardSinked:true}]
            }
            return item
        }),
        opponentCards:opponentCards = opponentCards.map(item=>{
            if (item[0]?.turnsLeft >= 0) {
                if (item[0].turnsLeft !== Number.MAX_SAFE_INTEGER) {
                    item[0].turnsLeft -= 1
                }
            }
            if (item[0]) if (item[0].health <= 0) {
                //trigger death animation
                opponentSunken = [...opponentSunken, item[0]]
                return [{...item[0], cardSinked:true}]
            }
            return item
        })}
    }

    attack = (playerCards,opponentCards, destroyedPositions, opponentDestroyedPositions) => {
        let newPlayerCards = [...playerCards]
        let newOpponentCards = [...opponentCards]
        let newOpponentDestroyedPositions = []
        playerCards.forEach((item,index)=>{
            if (!item[0]?.level) return
            const damage = getAttack(item)
            if (damage) {
                if (newPlayerCards[index][0].ignoreFirstAttack!==true) {
                    [newOpponentCards, newOpponentDestroyedPositions] = this.cardAttack(damage,index,newOpponentCards, newOpponentDestroyedPositions)
                    if (newOpponentCards[index].length>0) {
                        newOpponentCards[index][0] = {...newOpponentCards[index][0], cardDamaged: true}
                    }
                }
                if (newPlayerCards[index].length>0) {
                    newPlayerCards[index][0] = {...newPlayerCards[index][0], cardAttacking: newPlayerCards[index][0].ignoreFirstAttack?undefined:1}
                }
            }
        })
        const opponentDestroyingPositions = newOpponentDestroyedPositions.map(number=>{return 7-number}).filter(index=>!opponentDestroyedPositions.includes(index))
        opponentDestroyedPositions = Array.from(new Set([...opponentDestroyedPositions, ...opponentDestroyingPositions]))
        let newDestroyedPositions = []
        playerCards = [...newPlayerCards]
        opponentCards = [...newOpponentCards]
        opponentCards.forEach((item,index)=>{
            if (!item[0]?.level) return
            const damage = getAttack(item)
            if (damage) {
                if (newOpponentCards[index][0].ignoreFirstAttack!==true) {
                    [playerCards, newDestroyedPositions] = this.cardAttack(damage,index,playerCards,newDestroyedPositions)
                    //only if card there
                    if (playerCards[index].length>0) {
                        playerCards[index][0] = {...playerCards[index][0], cardDamaged: true}
                    }
                }
                if (newOpponentCards[index].length>0) {
                    newOpponentCards[index][0] = {...newOpponentCards[index][0], cardAttacking: newOpponentCards[index][0].ignoreFirstAttack?undefined:1}
                }
                
            }
        })
        const destroyingPositions = newDestroyedPositions.filter(index=>!destroyedPositions.includes(index))
        destroyedPositions = Array.from(new Set([...destroyedPositions, ...destroyingPositions]))
        return {
            playerCards: playerCards,
            opponentCards: newOpponentCards,
            destroyingPositions: destroyingPositions,
            opponentDestroyingPositions:opponentDestroyingPositions,
            destroyedPositions: destroyedPositions,
            opponentDestroyedPositions: opponentDestroyedPositions,
        }
    }

    removeDeadCardAttibutes = (playerCards, opponentCards) => {
        let newPlayerCards = [...playerCards]
        let newOpponentCards = [...opponentCards]
        newPlayerCards.forEach((cardContainer,idx)=>{
            if ((typeof cardContainer[0]?.whenRemoved === 'function')&&cardContainer[0]?.cardSinked) {
                const newData = cardContainer[0].whenRemoved(newPlayerCards,cardContainer[0])
                newPlayerCards = newData.arenaPositions?newData.arenaPositions:newPlayerCards
            }
        })
        newOpponentCards.forEach((cardContainer,idx)=>{
            if ((typeof cardContainer[0]?.whenRemoved === 'function')&&cardContainer[0]?.cardSinked) {
                const newData = cardContainer[0].whenRemoved(newOpponentCards,cardContainer[0])
                newOpponentCards = newData.arenaPositions?newData.arenaPositions:newOpponentCards
            }
        })
        return {playerCards:newPlayerCards, opponentCards:newOpponentCards}
    }
    
    simulateCards(currentState, opponentState) {
        this.props.updatePlayer({...currentState, paused: true, viewing:true, awaitingNextTurn: true})
        this.props.setState(prevState=>{return{...prevState,viewing:true}})
        this.props.setSimulating(true)
        setTimeout(()=>{
            let state = {...currentState, paused: true, viewing:true, awaitingNextTurn: false, simulating:true}
            this.props.setState(state)
            this.props.setOpponentState({...opponentState,paused: true, viewing:true, awaitingNextTurn: false})
            let playerCards = [...state.arenaPositions]
            let opponentCards = [...opponentState.arenaPositions]
            let sunken = [...state.sunken]
            let opponentSunken = [...opponentState.sunken]
            let destroyedPositions = [...state.destroyedPositions]
            let opponentDestroyedPositions = [...opponentState.destroyedPositions]
            let newDeck = [...state.deck]
            let newOppDeck = [...opponentState.deck]
            const nextTurn = state.turn + 1
            let nextShells = state.shells + 1
            //enhance function of card
            playerCards = this.enhance(playerCards)
            opponentCards = this.enhance(opponentCards)
            //whenPlayed function of card
            let newPlayer = this.whenPlayed(state, playerCards, nextShells)
            playerCards = [...newPlayer.cards]
            nextShells = newPlayer.shells
            newDeck = newPlayer.deck
            let newOpponent= this.whenPlayed(opponentState, opponentCards)
            opponentCards = [...newOpponent.cards]
            newOppDeck = newOpponent.deck
            //attack
            let afterAttack = this.attack(playerCards,opponentCards, destroyedPositions, opponentDestroyedPositions)
            playerCards= afterAttack.playerCards
            opponentCards= afterAttack.opponentCards
            const destroyingPositions= afterAttack.destroyingPositions
            const opponentDestroyingPositions= afterAttack.opponentDestroyingPositions
            destroyedPositions= afterAttack.destroyedPositions
            opponentDestroyedPositions= afterAttack.opponentDestroyedPositions
            //remove dead cards
            let removedCards = this.removeDeadCards(playerCards,opponentCards,sunken,opponentSunken)
            playerCards=removedCards.playerCards
            opponentCards=removedCards.opponentCards
            //place cards on opponents side to start animations
            
            let newOpponentState = {
                ...opponentState, 
                destroyingPositions:opponentDestroyingPositions, 
                destroyedPositions:Array.from(new Set(opponentDestroyedPositions)), 
                arenaPositions: opponentCards, 
                awaitingNextTurn: false, 
                deck: newOppDeck, 
                turn: opponentState+1
            }
            this.props.setOpponentState(newOpponentState)
            //place cards on player side to start animations
            let newState = {
                ...state, 
                simulating:false, 
                destroyingPositions:destroyingPositions, 
                destroyedPositions:Array.from(new Set(destroyedPositions)), 
                arenaPositions: playerCards, 
                awaitingNextTurn: false, 
                deck: newDeck, 
                turn: nextTurn, 
                shells:nextShells
            }
            this.props.setState({...newState})
            //filter for removing card animation and removing sunken cards
            const cardMetaFilter = (item,idx) => {
                const replacement = item[0]?.replacement
                if (typeof replacement === 'function'&&(item?.[0]?.health<=0)) {
                    const seedLetters = item[0].position
                    let seed = 0
                    for (let i = 0; i < seedLetters.length; i++) {
                        seed+=seedLetters.charCodeAt(i)
                    }
                    seed+=(1200*nextTurn)+(idx*1500)
                    let replacementCard = replacement(seed)()
                    if (replacementCard?.title) return [{...replacementCard, level: item[0].level}]
                }
                let card = item[0]
                return !card ? item : (card?.cardSinked? []:[{...card, cardDamaged:undefined, cardAttacking:undefined, cardSinked:undefined, ignoreFirstAttack:undefined}])
            }
            setTimeout(() => {  
                //onNexTurnStart
                const data = this.removeDeadCardAttibutes(playerCards, opponentCards)
                playerCards = data.playerCards?data.playerCards:playerCards
                opponentCards = data.opponentCards?data.opponentCards:opponentCards
                const playerDeckKeepSunkenLightCards = [...newDeck, ...playerCards.filter(item=>item[0]?.cardSinked&&(item[0]?.heavy===undefined)).flat(1).map(cardMapToRep).map(item=>repMapToCards(item,false))]
                newOpponentState = {...newOpponentState, arenaPositions: [...opponentCards].reverse().map(cardMetaFilter).reverse(), awaitingNextTurn: false, destroyingPositions:[]}
                this.props.setOpponentState(newOpponentState)
                newState = {...newState,
                    paused: false, 
                    viewing: false, 
                    arenaPositions: playerCards.map(cardMetaFilter), 
                    awaitingNextTurn: false, 
                    deck: playerDeckKeepSunkenLightCards,
                    destroyingPositions:[], turn: nextTurn, shells:nextShells}
                
                //reapply stickers
                let newArenaPositions = [...newState.arenaPositions]
                newState.arenaPositions.forEach((cardContainer,idx)=>{
                    if ((typeof cardContainer[0]?.whenPlaced === 'function')&&(cardContainer[0]?.cardType === 'troop')) {
                        const newData = cardContainer[0].whenPlaced(newArenaPositions,idx,newState.destroyedPositions,newArenaPositions[idx][0])
                        newArenaPositions = newData.arenaPositions?newData.arenaPositions:newState.arenaPositions
                    }
                })
                newState.arenaPositions = [...newArenaPositions]
                newArenaPositions = [...newState.arenaPositions]
                newState.arenaPositions.forEach((cardContainer,idx)=>{
                    if ((typeof cardContainer[0]?.whenPlaced === 'function')&&(cardContainer[0]?.cardType === 'building')) {
                        const newData = cardContainer[0].whenPlaced(newArenaPositions,idx,newState.destroyedPositions,newArenaPositions[idx][0])
                        newArenaPositions = newData.arenaPositions?newData.arenaPositions:newState.arenaPositions
                    }
                })
                
                const goingToSink = newArenaPositions.map(cardMetaFilter).flat(1).filter(item=>!item.cardSinked).map(item=>getWeight([item])).reduce((partialSum, a) => partialSum + a, 0)>=this.getPlankCapacity(newState)
                newState = {...newState, arenaPositions:newArenaPositions, sink:goingToSink}
                this.props.setState({...newState});
                if (goingToSink) {
                    newState = this.handleSink({...newState, arenaPositions:newArenaPositions, sink:goingToSink}, {...newOpponentState}, true)
                }
                setTimeout(() => {  
                    
                    this.props.setState({...newState});
                    this.props.setSimulating(false)
                    this.props.updatePlayer({...newState})
                    const draw = this.calculateDraw({...newState}, newOpponentState)
                    const loss = this.calculateLoss({...newState}, newOpponentState)
                    const win = this.calculateWin({...newState}, newOpponentState)
                    if (win || loss || draw) {
                        const removedState = {
                            ...newState,
                            paused:true,
                            lost:loss,
                            won:win,
                            draw:draw
                        }
                        this.props.setState(removedState)
                        setTimeout(()=>{
                            this.props.removeGame(removedState)
                        },1000)
                    } else if (this.props.opponentSimulate) {
                        this.props.opponentSimulate(newOpponentState, newState)
                    }
                    
                }, 5200);
            }, 4000);
        },2000)
    }
}